import { ElementType, useMemo } from "react";

export const useGroupedCategories = (categories: ElementType[], itemsPerGroup = 8) => {
	return useMemo(() => {
		if (categories.length) {
			return  categories.reduce(
				(acc: ElementType[][], item: ElementType, index: number) => {
					const chunkIndex = Math.floor(index / itemsPerGroup);

					if (!acc[chunkIndex]) {
						acc[chunkIndex] = [];
					}

					acc[chunkIndex].push(item);

					return acc;
				},
				[]
			);
			//setGroupedElements(result);
		}
	}, [categories,itemsPerGroup]);
};