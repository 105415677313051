import React, { useEffect, useState } from 'react';
import s from "../CreateBoardAD.module.css";
import { useTranslation } from 'react-i18next';
import useFilterHandle from '../../../CustomHooks/useFilterHandle';
import { type NoticeFilterType, type NoticeProperyValueType } from '../../../types/noticeTypes';
import { useSelector } from 'react-redux';
import { StateType } from '../../../redux/redux-store';
import { capitalizeFirstLetter } from '../../../common/Logic/supporting';

type Props = {
	filter: NoticeFilterType;
	values?: any;
  setFieldValue: any;
};

const ChangeWorkType: React.FC<Props> = ({ filter, values, setFieldValue }: Props) => {
	const { t, i18n } = useTranslation()
  const { handleFilter } = useFilterHandle();
  
	const filtersOneIs: Array<{ filter: string; id: number | string }> = useSelector(
    (state: StateType) => state.noticeData.filtersOneIs
  );

  const [noticeFilter, setNoticeFilter] = useState<{ filter: string; id: number | string } | undefined>(
    filtersOneIs.find((f: { filter: string; id: number | string }) => f?.filter === filter.inputName)
  );
  
  const initialFilter =  filter?.propertyValues?.find((filter, index) => 
    noticeFilter ? filter.id === noticeFilter?.id : filter.position === 1 || index === 0
  );

  useEffect(() => {
    if (initialFilter) {
      const { id, name, name_ru } = initialFilter
      handleFilter({ element: { id: id.toString(), name, name_ru }, filter})
    }
  }, [filter]);
  
	useEffect(() => {
		if (values && setFieldValue) {
			setFieldValue("filters", [...filtersOneIs]);
			setFieldValue("notice_properties", [...filtersOneIs]);
		}
	}, [filtersOneIs]);

  const nameField = `name${i18n.language !== 'uk' ? `_${i18n.language}` : ''}`

  return (
    <div className={s.tabWrapper}>
      {filter?.propertyValues.map((property: NoticeProperyValueType) => {
        const { id, name, name_ru } = property
        const element = { id: id.toString(), name, name_ru };
        const buttonName = capitalizeFirstLetter(property[nameField as keyof NoticeProperyValueType])
        const isActive = initialFilter?.id === property.id || noticeFilter?.id === property.id

        return (
          <button
            key={property.id}
            className={`${s.tabItem} ${isActive ? s.active : ''}`}
            onClick={() => {
              if (!isActive) { // Проверяем, не активна ли кнопка
                handleFilter({ element, filter });
                setNoticeFilter({ filter: filter.inputName, id: property.id });
              }
            }}
            type="button"
          >
            {buttonName} {t('роботу')}
          </button>
        )
      })}
    </div>
  );
};

export default ChangeWorkType;