import React from "react";
import NavBarSvgSelector from "../NavBarSvgSelector";
import s from "./HeaderProfile.module.css";
import { Link } from "react-router-dom";
import { Desktop, Mobile } from "../../../responsive";
import { useSelector } from "react-redux";
import { stateUserType } from "../../../types/reduxStoreTypes";
import { useTranslation } from "react-i18next";
import { StateType } from "../../../redux/redux-store";

function HeaderProfile() {
	const auth = useSelector((state: stateUserType) => state?.userData?.isAuth);
	const userView = useSelector((state: StateType) => state.userData.user);
	const { t } = useTranslation();
	return (
		<>
			<div className={s.container}>
				{auth && userView?.photo?.photoUrl ? (
					<div className={s.imageContainer}>
						<span className={s.personalCabinet}>
							<img src={userView?.photo?.photoUrl} alt={"avatar"} />
						</span>
					</div>
				) : (
					<>
						<Mobile>
							<Link to={"registration_auth"}>
								<NavBarSvgSelector id="profile" />
							</Link>
						</Mobile>
						<Desktop>
							<span className={s.avatarIcon}>
								<NavBarSvgSelector id="profile" />
							</span>
						</Desktop>
					</>
				)}

				{!auth ? (
					<h5>
						<Mobile>
							<Link to={"/registration_auth"}>{t(`вхід`)}</Link>
						</Mobile>
						<Desktop>
							<span>{t(`вхід`)}</span>
						</Desktop>
					</h5>
				) : (
					<h5>
						<span className={s.personalCabinet}>{t(`кабінет`)}</span>
					</h5>
				)}
			</div>
		</>
	);
}

export default HeaderProfile;
