import { useEffect, useState } from "react";
import s from "../CreateBoardAD.module.css";
import { useTranslation } from "react-i18next";
import { FilterForCreateNotice, NoticeFilterType } from "../../../types/noticeTypes";

type Props = {
	noticeDescriptionRef: any;
	status: any;
	setStatus: any;
	values: any;
	setFieldValue: (a: string, b: string) => void;
	workTypeFilter?: NoticeFilterType;
};

const DescriptionField: React.FC<Props> = ({
	noticeDescriptionRef,
	status,
	setStatus,
	values,
	setFieldValue,
	workTypeFilter
}) => {
	const { t, i18n } = useTranslation();

	const getPlaceholder = (id?: number) => {
		const proposeId = workTypeFilter?.propertyValues.find((f) => f.name.toLowerCase() === "пропоную")?.id;
		const lookingId = workTypeFilter?.propertyValues.find((f) => f.name.toLowerCase() === "шукаю")?.id;

		if (id && id === proposeId) {
			return t("Спробуйте детально описати вакансію:\n  - необхідні навички;\n  - суть роботи;\n  - графік роботи;\n  - відпустка;\n  - умови працевлаштування.");
		} else if (id && id === lookingId) {
			return t("Опишіть яку роботу ви шукаєте:\n  - освіта;\n  - ваш стаж роботи;\n  - останні 3 місця роботи;\n  - наявність Автомобіля і категорій прав;\n  - сімейний стан;\n  - бажану Зарплату.");
		} else {
			return t("Опишіть ваш товар, як би ви хотіли його піднести покупцю. Розкажіть про найкращі його якості.");
		}
	}

	const [placeholder, setPlaceholder] = useState<string>(getPlaceholder());

	useEffect(() => {
		if (workTypeFilter) {
			const currentFilter = values.filters.find((f: FilterForCreateNotice) => f.filter === workTypeFilter.inputName);
			setPlaceholder(getPlaceholder(+currentFilter?.id));
		} else {
			setPlaceholder(getPlaceholder());
		}
	}, [values, workTypeFilter, i18n.language])

	return (
		<div className={`${s.formElement} ${s.descriptionField}`}>
			<div className={s.nameSide}>
				<span className={s.elementName}>
					{t(`Опис`)}
					<span className={s.required}> * </span>
				</span>
			</div>
			<div
				ref={noticeDescriptionRef}
				className={`${s.fieldSide} ${status?.description ? s.error : ""} ${s.textArea} `}
			>
				<textarea
					name={"description"}
					onFocus={() => setStatus({})}
					value={values.description}
					onChange={(e) => setFieldValue("description", e.currentTarget.value)}
					placeholder={placeholder}
				/>
				<div className={s.descriptionOptions}>
					{status && status.description ? (
						<div className={s.descriptionOptions_info}>
							{status.description}
						</div>
					) : (
						<div className={s.descriptionOptions_Size}>
							{values.description.length < 50 && t("Не менше 50 символів")}
						</div>
					)}
					<div className={s.descriptionOptions_Size}>
						{values.description.length}/8000
					</div>
				</div>
			</div>
		</div>
	);
};

export default DescriptionField;
