import React, { useState } from "react";
import s from "./SellerCard.module.css";
import SellerCardSvgSelector from "./SellerCardSvgSelector";
import { Desktop } from "../../responsive";
import { useDispatch, useSelector } from "react-redux";
import { stateUserType } from "../../types/reduxStoreTypes";
import { useTranslation } from "react-i18next";
import PublishedMonthYear from "../TimeAndDate/PublishedMonthYear";
import CompainModalWindow from "../CompainModalWindow/CompainModalWindow";
import { Link } from "react-router-dom";
import { setOpenRegAuthPopup } from "../../redux/user_Reducer";
import { formattingPhone } from "../../common/Logic/supporting";

type PropsType = {
	dialogOpen: boolean;
	dialogClose: () => void;
	sellerInfo?: {
		name: string;
		ownerPhone: string;
		time_registration: number;
		viber: number;
		whatsapp: number;
		telegram: number;
		time_last_visit: number;
		reputation: number | null;
		ownerImage: string | null;
		sellerId: string | null;
		ownerBPData?: {
			slug?: string;
			status?: number;
			statusName?: string;
		};
	};
};

const SellerCardPopup: React.FC<PropsType> = ({ dialogClose, sellerInfo }) => {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const [openCompainModal, setOpenCompainModal] = useState(false);
	const auth = useSelector((state: stateUserType) => state.userData.isAuth);

	const openEnterDialog = () => {
		dispatch(setOpenRegAuthPopup(true));
	};

	const handleOpenLogin = () => {
		openEnterDialog();
	};

	const handleOpenCompainModal = () => {
		setOpenCompainModal(true);
	};
	const handleCloseCompainModal = () => {
		setOpenCompainModal(false);
	};
	const languagePath = i18n.language === "ru" ? "" : "ua/";
	const socialLinks = [
		{
			id: "viber",
			href: `viber://chat?number=%2B${formattingPhone(sellerInfo?.ownerPhone || "")}`,
			status: sellerInfo?.viber === 1,
			activeColor: "#7D3DAF",
		},
		{
			id: "telegram",
			href: `https://t.me/%2B${formattingPhone(sellerInfo?.ownerPhone || "")}`,
			status: sellerInfo?.telegram === 1,
			activeColor: "#2da0d1",
		},
		{
			id: "whatsApp",
			href: `https://api.whatsapp.com/send?phone=%2B${formattingPhone(sellerInfo?.ownerPhone || "")}`,
			status: sellerInfo?.whatsapp === 1,
			activeColor: "#17970f",
		},
	];

	return (
		<div className={s.wrapper}>
			<div className={s.sellerCardPopupContainer}>
				<div className={s.sellerCardPopupTop}>
					<div className={s.titleTop}>
						<div>{t("Продавець")}</div>
						<span onClick={dialogClose}>
							<SellerCardSvgSelector id={"close"} />
						</span>
					</div>
					<h4>{sellerInfo?.name || ""}</h4>
				</div>
				<div className={s.sellerCardPopupContent}>
					<div className={s.aboutSellerContainer}>
						{/*<div className={s.verifiedSeller}>
                                <SellerCardSvgSelector id={"greenDone"}/>
                                <span>Перевірений продавець</span>
                            </div>*/}

						<div className={s.presentInService}>
							На
							<b> bago</b> {t("з")}{" "}
							<PublishedMonthYear
								seconds={sellerInfo?.time_registration || 0}
							/>
						</div>
						{/* <div className={s.deals}>Угоди <span>156</span></div>*/}
						{sellerInfo?.reputation !== null && (
							<div className={s.provenReputation}>
								{t(`Перевірена репутація з `)}
								{sellerInfo?.reputation}
								{/* <PublishedYear seconds={sellerInfo?.reputation || 0} />*/}
							</div>
						)}
						{/*<div className={s.useSafeDeal}>
                                <SellerCardSvgSelector id={"useSafeDeal"}/>
                                <span>Використовуйте <Link to={"/"}>Безпечну угоду</Link></span>
                            </div>*/}
						<div className={s.recommendYou}>{t("Радимо вам")}:</div>
						<div className={s.attention}>
							<SellerCardSvgSelector id={"attention"} />
							<span>{t("Не давайте нікому дані банківської картки")}</span>
						</div>
						<div className={s.contacts}>
							<ul className={s.phoneNumbers}>
								<li>
									<a  href={`tel:${sellerInfo?.ownerPhone}`}>{sellerInfo?.ownerPhone}</a>
								</li>
							</ul>
							<Desktop>
								<ul className={`${s.socialLinks} ${s.popup}`}>
									{socialLinks.map(({ id, href, status, activeColor }) => (
										<li key={id}>
											{status ? (
												<a href={href} target={"_blank"}>
													<SellerCardSvgSelector id={id} fill={activeColor} />
												</a>
											) : (
												<SellerCardSvgSelector id={id} fill="#BDBDBD" />
											)}
										</li>
									))}
								</ul>
							</Desktop>
						</div>
						<div className={s.buttons}>
							{sellerInfo?.ownerBPData?.slug !== "" &&
							sellerInfo?.ownerBPData?.statusName === "Активно" ? (
								<Link
									to={`/${languagePath}business-page/${sellerInfo.ownerBPData.slug}`}
									className={s.otherAdsSeller}
								>
									{t("Інші оголошення продавця")}
								</Link>
							) : null}
							{/*<div className={s.buttonAddVisit}>
                                    <button onClick={()=>setFavoriteSeller(!favoriteSeller)} className={s.addSellerToBusinessCards}>
                                        {favoriteSeller ?
                                            <SellerCardSvgSelector id={"yellowHeartSelected"}/> :
                                            <SellerCardSvgSelector id={"yellowHeart"}/>
                                        }
                                        <span>Додати продавця до візиток</span>
                                    </button>
                                </div>*/}
							{/*<div className={s.buttonAddTrack}>
                                    <button onClick={()=>setTrackSeller(!trackSeller)} className={s.trackNewItems}>
                                        {trackSeller ?
                                            <SellerCardSvgSelector id={"yellowHeartSelected"}/> :
                                            <SellerCardSvgSelector id={"yellowHeart"}/>
                                        }
                                        <SearchCardSvgSelector id={"heart"}/>
                                        <span>Відстежувати нові товари
                                               продавця </span>
                                    </button>
                                </div>*/}
						</div>
					</div>
				</div>
				<div className={s.sellerCardPopupBottom}>
					{auth ? (
						<div className={s.complain} onClick={handleOpenCompainModal}>
							<SellerCardSvgSelector id={"complain"} />
							<span>{t("Поскаржитись")}</span>
						</div>
					) : (
						<div className={s.complain} onClick={handleOpenLogin}>
							<SellerCardSvgSelector id={"complain"} />
							<span>{t("Поскаржитись")}</span>
						</div>
					)}
				</div>
			</div>
			{openCompainModal && (
				<CompainModalWindow
					dialogClose={handleCloseCompainModal}
					dialogOpen={openCompainModal}
					sellerName={sellerInfo?.name ?? ""}
					lastVisit={sellerInfo?.time_last_visit || 0}
					ownerImage={sellerInfo?.ownerImage ?? ""}
					userId={sellerInfo?.sellerId ?? ""}
					productImage={""}
					productName={""}
					productPrice={""}
					isShowProduct={false}
					idForCreate={0}
					isSellerCard={true}
				/>
			)}
		</div>
	);
};

export default SellerCardPopup;
