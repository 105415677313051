import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { buyService } from "../../../../redux/notice_Reducer";
import { OnePrice } from "../../../../redux/price_Reducer";
import { formattingOptionsForBuyService } from "../../../../common/Logic/supporting";
import { NoticeDataForModal } from "../../../../types/noticeTypes";
import BottomPayModalCardComponent from "../BottomPayModalCardComponent";
import TitleModalCardComponent from "../TitleModalCardComponent";
import TopModalCardComponent from "../TopModalCardComponent";
import s from "./ModalAutoRenewal.module.css";

type Props = {
	handleClose: (b: boolean) => void;
	data: OnePrice[] | null;
	noticeData: NoticeDataForModal;
};
const ModalAutoRenewal: React.FC<Props> = ({
	handleClose,
	data,
	noticeData,
}) => {
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const buy = useSelector(() => buyService);
	const formattingData = formattingOptionsForBuyService(data);
	const [value, setValue] = useState(
		formattingData[0]
			? formattingData[0]
			: {
					label: "",
					value: "",
					period: "",
					price: "",
					periodName: "",
				}
	);
	const handleChangePeriod = (newValue: any) => {
		setValue(newValue);
	};
	const handlePayService = () => {
		dispatch(
			buy(
				+noticeData.id,
				+value.value,
				handleClose,
				value.price,
				noticeData.name
			)
		);
	};
	return (
		<div className={s.modalContainer}>
			<TitleModalCardComponent
				title={t("Налаштувати автопідняття")}
				handleClose={handleClose}
			/>
			<TopModalCardComponent noticeData={noticeData} />
			<div className={s.topCardSelectLine}>
				<div className={s.topCardSelectLine_Info}>
					{t("Автопідняття оголошення 1 раз у")}
				</div>
				<div className={s.topCardSelectLine_Select}>
					<Select
						isClearable={false}
						options={formattingData}
						onChange={handleChangePeriod}
						value={value}
						styles={{
							control: (baseStyles) => ({
								...baseStyles,
								borderColor: "#E7E7E7",
								width: "198px",
								borderRadius: "4px",
								"@media (max-width: 1025px)": {
									width: "100%",
								},
							}),
							singleValue: (styles) => {
								return {
									...styles,
									fontFamily: "var(--myMainFontBold)",
									fontSize: "16px",
									lineHeight: "16px",
									color: "#939393",
								};
							},
						}}
					/>
				</div>
			</div>
			<div className={s.topCardSelectBuyInfo}>
				{t("Гроші будуть зніматися з вашого балансу в розмірі")}
				<span>
					{" "}
					{value.price} грн/{t("підняття")}{" "}
				</span>{" "}
				1 раз {t("на")} {value.periodName},{" "}
				{t("поки ви не скасуєте автопідняття або деактивуєте оголошення")}.
			</div>
			<div className={s.topCardSelectPayTitle}>
				{t("Замовити та оплатити 1-е підняття")}.
			</div>
			<BottomPayModalCardComponent
				totalBalance={noticeData.balance}
				handleClick={handlePayService}
				sum={value.price}
			/>
		</div>
	);
};

export default ModalAutoRenewal;
