import s from "./ProductCard.module.css";
import { publicOneNotice } from "../../../types/publicNoticeTypes";
import FavoriteHeartSelector from "../FavoriteHeartSelector/FavoriteHeartSelector";
import WorkSkeletonComponent from "../../WorkSkeletonComponent/WorkSkeletonComponent";
import { VacancyType, WorkInfoList } from "../../../pages/OutputBordAD/WorkNotice/WorkInfoList/WorkInfo";
import { testActualityService } from "../../../common/Logic/supporting";
import SearchCardSvgSelector from "../FavoriteSearchCard/SearchCardSvgSelector";

const WorkProductCard = ({
	notice,
	imageUrl
}: {
	notice: publicOneNotice;
	imageUrl: string;
}) => {
	const workType = notice.noticeType?.notice_properties?.find(prop => prop.specialViewOnFront === "work_search_propose")?.propertyValues;

	return (<>
		{imageUrl ? (
			<div className={s.workCardImage}>
				<img src={imageUrl} alt={notice.name} />
			</div>
		) : workType && (
			<WorkSkeletonComponent type={workType} category={notice.category_name} />
		)}
				
		{testActualityService(notice?.top_at ? notice.top_at : null) && (
			<span className={s.topFlag}>
				<SearchCardSvgSelector id={"greenTop"} />
			</span>
		)}

		<div className={`${s.cardTitle} ${s.work}`}>
			<p>{notice?.name || ""}</p>
			<FavoriteHeartSelector
				id={notice?.id || 0}
				isFavorite={notice?.isFavorite || 0}
				userId={notice?.users_id || 0}
			/>
		</div>

		<div className={s.cardWorkContent}>
			<WorkInfoList notice={notice} card isPopular />
			{notice.noticeType?.vacancyType &&
				<VacancyType type={notice.noticeType?.vacancyType} card />
			}
		</div>

	</>);
};

export default WorkProductCard;
