import { useState } from "react";
import {
	DeliveryService,
	SetManageActivityBlock,
} from "../../../../../api/business-page/types";
import { useSetDeliveryService } from "../../../../../CustomHooks/api/business-page/useBusinessPage";
import s from "./DeliverySeller.module.css";

type Props = {
	isEditDeliverySeller: boolean;
	setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
	block: string;
	onChangeVisible: (
		block: keyof SetManageActivityBlock,
		visible: 0 | 1
	) => void;
	deliveryServices: Record<string, DeliveryService | null>;
	isUserPage?: boolean;
};


const DeliverySeller = (props: Props) => {
	const { isEditDeliverySeller, deliveryServices = {}, isUserPage } = props;

	const { mutate: setDeliveryService } = useSetDeliveryService("");

	const [selectedOptions, setSelectedOptions] = useState<
		Record<string, boolean>
	>(
		Object.entries(deliveryServices).reduce(
			(acc, [key, service]) => ({
				...acc,
				[key]: service?.active === "1",
			}),
			{}
		)
	);

	const toggleOption = (deliveryId: string): void => {
		if (!isEditDeliverySeller) return;

		const newStatus = !selectedOptions[deliveryId];
		setSelectedOptions((prev) => ({
			...prev,
			[deliveryId]: newStatus,
		}));

		// Call the mutation to update the delivery service status
		setDeliveryService({
			deliveryId,
			active: newStatus ? 1 : 0,
		});
	};


	return (
		<div className={s.container}>
			<ul className={s.deliveryTable}>
				{!isUserPage
					? Object.values(deliveryServices)
							.filter((v) => v?.active === "1")
							.map((option) => {
								if (!option) return null;

								const isActive = selectedOptions[option.deliveryId];

								return (
									<li
										key={option.deliveryId}
										className={s.deliveryTableElement}
									>
										<button
											className={`${s.deliveryButton} ${isActive && isEditDeliverySeller ? s.selected : ""}`}
											onClick={() => toggleOption(String(option.deliveryId))}
											disabled={!isEditDeliverySeller}
										>
											<div className={s.deliveryLogo}>
												<img src={option.logoUrl} alt="Logo delivery service" />
											</div>
											<div className={s.deliveryInfo}>
												<span className={s.deliveryName}>{option.title}</span>
											</div>
										</button>
									</li>
								);
							})
					: Object.values(deliveryServices).map((option) => {
							if (!option) return null;

							const isActive = selectedOptions[option.deliveryId];

							return (
								<li key={option.deliveryId} className={s.deliveryTableElement}>
									<button
										className={`${s.deliveryButton} ${isActive && isEditDeliverySeller ? s.selected : ""}`}
										onClick={() => toggleOption(String(option.deliveryId))}
										disabled={!isEditDeliverySeller}
									>
										<div className={s.deliveryLogo}>
											<img src={option.logoUrl} alt="Logo delivery service" />
										</div>
										<div className={s.deliveryInfo}>
											<span className={s.deliveryName}>{option.title}</span>
											{isUserPage ? (
												<span className={s.deliveryTime}>
													{isActive ? "Включено" : "Виключено"}
												</span>
											) : null}
										</div>
									</button>
								</li>
							);
						})}
			</ul>
		</div>
	);
};

export default DeliverySeller;
