import { useMediaQuery } from "react-responsive";

export const responsiveForLayotZone = (action:"width" | "cols") => {
	const isFirstSmallMobile = useMediaQuery({ maxWidth: 400 });
	const isSmallMobile = useMediaQuery({ maxWidth: 500 });
	const isMediumMobile = useMediaQuery({ maxWidth: 700 });
	const isHighMobile = useMediaQuery({ maxWidth: 900 });
	const isMobile = useMediaQuery({ maxWidth: 1024 });
	const isStartDesktop = useMediaQuery({ maxWidth: 1250 });
	const isDesktop = useMediaQuery({ maxWidth: 1280 });

	if(action === "width") {
		return isFirstSmallMobile
			? 340
			: isSmallMobile
				? 340
				: isMediumMobile
					? 500
					: isHighMobile
						? 700
						: isMobile
							? 700
							: isStartDesktop
								? 1000
								: isDesktop
									? 1100
									: 1200;

	}else if(action === "cols"){
		return isFirstSmallMobile
			? 4
			: isSmallMobile
				? 4
				: isMediumMobile
					? 5
					: isHighMobile
						? 8
						: isMobile
							? 8
							: isStartDesktop
								? 7
								: isDesktop
									? 8
									: 8;
	} else {
		return 8
	}
}

