import React from "react"
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import mockListing from "../../../images/mock-listing-row.svg";
import { setClearFiltersList } from "../../../redux/category_Reducer";
import { setImagesOriginal } from "../../../redux/notice_Reducer";
import { StateType } from "../../../redux/redux-store";
import { setOpenRegAuthPopup, setPhoneEnterPopup } from "../../../redux/user_Reducer";
import s from "../FavoriteSearchCard/FavoriteSearchCard.module.css";

export const AddListingCardRow = ({
	isShowBorderTop,
}: {
	isShowBorderTop: boolean;
}) => {
	const navigate = useNavigate();
	const { i18n, t } = useTranslation();
	const dispatch = useDispatch();
	const location = useLocation();
	const auth = useSelector((state: StateType) => state.userData.isAuth);
	const userView = useSelector((state: StateType) => state.userData.user);
	const clearFilters = useSelector(() => setClearFiltersList);
	const segmentsArray = location.pathname?.split("/").filter(Boolean);
	const handleEnterPopup = useSelector(() => setPhoneEnterPopup);

	const languagePath = i18n.language === "ru" ? "" : "ua/"

	const openEnterDialog = () => {
		dispatch(setOpenRegAuthPopup(true));
	};
	const clearInputs = () => {
		dispatch(setImagesOriginal([]));
		dispatch(clearFilters());
	};

	const onClickOnCard = () => {
		clearInputs();
		if (auth && userView.is_approve_phone !== 1) {
			dispatch(handleEnterPopup(true));
		} else if (auth && userView.is_approve_phone === 1) {
			if (
				segmentsArray?.length > 0 &&
				(segmentsArray[0] === "create_ad" || segmentsArray[1] === "create_ad")
			) {
				alert(
					t(
						"Оголошення, яке ви розпочали подавати," +
							" ще не було опубліковано. Бажаєте повернутися до цього оголошення?"
					)
				);
			} else {
				navigate(`/${languagePath}create_ad`);
			}
		}
	};

	return (
		<>
			<div
				onClick={() => {
					!auth ? openEnterDialog() : onClickOnCard();
				}}
				style={{
					borderTop: isShowBorderTop ? "1px solid #dcdcdc" : "",
					borderBottom: "1px solid #dcdcdc",
					caretColor: "transparent",
				}}
				className={s.cardRowContainer}
			>
				<div className={s.image}>
					<img
						className={`${s.image}`}
						loading={"lazy"}
						alt="Add Post"
						src={mockListing}
					/>
				</div>
				<div className={s.info}>
					<div className={s.infoTitle}>
						<h4>
						{segmentsArray.includes("search_result") ||
						segmentsArray.includes("search")
							? t("Додати оголошення")
							: t("Додати в цю рубрику оголошення")}
						</h4>
					</div>
				</div>
				<div className={s.panel}>
					<div className={s.panelBottom}>
						<div className={s.addAD}>
							<button onClick={onClickOnCard}>
								<h3>+ {t(`оголошення`)}</h3>
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
