import { Formik, Form, Field } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import s from '../CommentSection.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { createComment } from "../../../../redux/notice_Reducer"
import { StateType } from '../../../../redux/redux-store';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { getRecaptchaCode } from '../../../../common/Logic/recaptchaService';
import { setNotification } from '../../../../redux/notificationReducer';
import PersonalCabinetSvgSelector from '../../../../Components/PersonalCabinet/PersonalCabinetSvgSelector';

const CreateComment: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const noticeView = useSelector((state: StateType) => state.publicNotice.noticeView);
  const userView = useSelector((state: StateType) => state.userData.user);
  const minValue = 50;
  const maxValue = 8000;

  type Values = {
    comment: string,
    author_name: string
  };

  const validate = (values: Values) => {
    const errors: Record<string, string> = {};
    if (!values.comment) {
      errors.comment = t("Необхідно заповнити");
    } else if (values.comment.length < minValue) {
      errors.comment = `${t("Не менше ніж")} ${minValue} ${t("символів")}`;
    } else if (values.comment.length > maxValue) {
      errors.comment = `${t("Не більше ніж")} ${maxValue} ${t("символів")}`;
    }
    if (!values.author_name) {
      errors.author_name = t("Необхідно заповнити");
    }
    return errors;
  };

  const handleSubmit = async (values: Values, { setSubmitting, setErrors, resetForm }: any) => {
    if (!noticeView?.id) return;

    try {
      const newCaptcha = await getRecaptchaCode(executeRecaptcha);
      const commentData = { 
        ...values,
        notice_id: noticeView.id.toString(),
        captcha: newCaptcha ?? "",
      };
      const response = await createComment(commentData);
      if (response.data?.errors) {
        setErrors(response.data.errors);
      } else {
        dispatch(setNotification(t("Ваш коментар відправлено на перевірку. Він буде опублікований після модерації."), "success"));
        resetForm({ values: { ...values, comment: "" } });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        comment: '',
        author_name: userView?.name ?? ''
      }}
      validate={validate}
      onSubmit={handleSubmit}
      validateOnChange={false}
     >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        isSubmitting,
        setFieldError,
      }) => {
        return <Form className={s.form} id="comments" onSubmit={handleSubmit}>
          <div className={`${s.fieldWrap} ${errors.comment && touched.comment ? s.error : ''}`}>
            <Field
              as="textarea"
              placeholder={t("Напишіть свій коментар")}
              name="comment"
              value={values.comment}
              className={s.comment}
              onFocus={() => setFieldError('comment', '')}
            />
            <div className={s.commentHelpWrapper}>
              <span className={s.help}>
                {errors.comment && touched.comment ? errors.comment : `${t("Не менше ніж")} ${minValue} ${t("символів")}`}
              </span>
              <div className={s.sizeCounter}>
                {values.comment.length}/8000
              </div>
            </div>
          </div>

          <div className={`${s.fieldWrap} ${errors.author_name && touched.author_name ? s.error : ''}`}>
            {!values.author_name &&
              <span className={s.authorIcon}>
                <PersonalCabinetSvgSelector id={"author"} />
              </span>
            }
            <Field
              type="text"
              name="author_name"
              placeholder={t("Автор")}
              value={values.author_name}
              className={`${s.field} ${!values.author_name ? s.withIcon : ''}`}
              onFocus={() => setFieldError('author_name', '')}
            />
            {errors.author_name && touched.author_name &&
              <span className={s.help}>
                {errors.author_name && touched.author_name && errors.author_name}
              </span>
            }
          </div>

          <button
            type="submit"
            disabled={isSubmitting || !(values.comment && values.author_name)}
            className={s.submitButton}
          >
            {t("Опублікувати")}
          </button>
        </Form>
      }}
    </Formik>
  );
};

export default CreateComment;