import React, { useEffect } from "react";
import s from "./PersonalCabinetSettings.module.css";
import PersonalCabinetAccordion from "../PersonalCabinetAccordion/PersonalCabinetAccordion";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../LanguageSelector/LanguageSelector";
const PersonalCabinetSettingsMobile = () => {
	const { t } = useTranslation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className={s.personalCabSettingsMobile}>
			<LanguageSelector />
			{/* <SettingsTopBlock />
            <SettingsPremiumSettings /> */}
			<h5>{t("Управління особистими даними")}</h5>
			<div className={s.settingsMobileAccordionContainer}>
				{/* <PersonalCabinetSettingBusinessPage /> */}
				<PersonalCabinetAccordion />
			</div>
		</div>
	);
};

export default PersonalCabinetSettingsMobile;
