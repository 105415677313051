import React, { ReactNode } from 'react';
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useTranslation } from 'react-i18next';

interface RecapchaProps {
  children: ReactNode;
  recaptchaKey?: string;
}

const Recaptcha: React.FC<RecapchaProps> = ({ children, recaptchaKey }) => {
  const { i18n } = useTranslation();
  const reCaptchaKey = recaptchaKey ?? "6LfTBfAUAAAAAPqIrFmQ24dE4oKhU7F8efdxSpeZ";

  return (
    <GoogleReCaptchaProvider
			reCaptchaKey={reCaptchaKey}
			language={i18n.language}
		>
      {children}
    </GoogleReCaptchaProvider>
  );
};

export default Recaptcha;