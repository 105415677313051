import React, { useState } from "react";
import styleFile from "../../Components/CommonPopups/AttentionInfoPopup/AttentionInfoPopup.module.css";
import classes from "./ForgotPassPopup.module.css";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getSetPassword, setForgotPass } from "../../redux/user_Reducer";
import style from "../../GlobalStyles.module.css";
import { StateType } from "../../redux/redux-store";
import { useNavigate } from "react-router";
import { stateUserType } from "../../types/reduxStoreTypes";
import ArrowsAndCross from "../../common/ArrowsAndCross/ArrowsAndCross";
import PasswordFormInput from "../../Components/Registration&Auth/PasswordFormInput";
import BedResultInfoPopup from "../BedResultInfoPopup";
import SmsLimitErrorPopup from "../SmsLimitErrorPopup";
import Timer from "../../Components/CommonPopups/AttentionInfoPopup/Timer";
import i18n from "i18next";

type Props = {
	phone: string;
	handleToReg: (b: boolean) => void;
};
const PopupForApprovePhoneAndSetNewPass = ({
	phone,
	handleToReg,
}: Props) => {
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const navigate = useNavigate();
	const setNewPass = useSelector(() => getSetPassword);
	const auth = useSelector((state: stateUserType) => state.userData.isAuth);
	const sendMessage = useSelector((state: StateType) => state.userData.result);
	const sendType = useSelector((state: StateType) => state.userData.sendType);
	const sendRecoveryCode = useSelector(
		(state: StateType) => state.userData.recovery_code
	);
	const phoneNotExits = useSelector(
		(state: StateType) => state.userData.phoneNotExits
	);
	const smsLimitError = useSelector(
		(state: StateType) => state.userData.smsLimitError
	);
	const [time, setTime] = useState(240); // 4 минуты в секундах (4 минуты * 60 секунд)
  const setCloseForgotPass = ()=>{
		dispatch(setForgotPass(false))
	}
	if (phoneNotExits) {
		return (
			<BedResultInfoPopup setClose={setCloseForgotPass} handleToReg={handleToReg} />
		);
	} else if (smsLimitError) {
		return <SmsLimitErrorPopup setClose={setCloseForgotPass} />;
	} else {
		return (
			<div className={styleFile.wrapper}>
				<div className={styleFile.container}>
					<div className={styleFile.topNavigate}>
						<span
							onClick={setCloseForgotPass}
						>
							<ArrowsAndCross id={"closeCross"} color={"#727272"} />
						</span>
					</div>
					<div className={`${styleFile.title} ${classes.title}`}>
						{t("Відновлення паролю")}
					</div>
					<div className={styleFile.info}>{sendMessage}</div>
					{sendType === "email" && (
						<>
							<div className={styleFile.button}>
								<button
									onClick={(e) => {
										e.preventDefault();
										dispatch( setForgotPass(false));
									}}
								>
									{t("OK")}
								</button>
							</div>
						</>
					)}
					{sendType === "sms" && (
						<>
							<Formik
								initialValues={{
									code: "",
									password: "",
									sms_code: "",
								}}
								onSubmit={(values, submitProps) => {
									dispatch(
										setNewPass(
											sendRecoveryCode,
											values.password,
											()=>{
												navigate("/");
											},
											values.sms_code,
											submitProps.setStatus
										)
									);
								}}
							>
								{({ values, handleSubmit, status, setFieldValue }) => {
									return (
										<Form onSubmit={handleSubmit}>
											<div
												className={`${classes.timerContainer} ${classes.timerInApprove}`}
											>
												<Timer
													time={time}
													setTime={setTime}
													phone={phone}
													approve={false}
												/>
											</div>
											<div
												className={`${classes.enterEmailInput} ${status && status.error ? classes.error : ""} `}
											>
												<Field
													name={"sms_code"}
													value={values.sms_code}
													placeholder={t("Введіть код")}
												/>
												{status && status.sms_code && (
													<span className={style.error}>{status.sms_code}</span>
												)}
												{status && status.error && (
													<span className={style.error}>{status.error}</span>
												)}
											</div>
											<div className={`${styleFile.title} ${classes.title}`}>
												{t("Придумайте пароль")}
											</div>
											<div
												className={`${classes.enterEmailInput} ${status && status.error ? classes.error : ""} `}
											>
												<PasswordFormInput
													values={{ password: values.password }}
													setFieldValue={setFieldValue}
													status={
														status && status.password ? status.password : ""
													}
												/>
											</div>
											{status && status.captcha && (
												<span className={styleFile.error}>
													{status.captcha}
												</span>
											)}
											<div className={styleFile.button}>
												<button
													onClick={(e) => {
														e.preventDefault();
														if (auth) {
															navigate(`/${i18n.language === "ru" ? "" : "ua/"}personal_cabinet/settings`);
														} else {
															handleSubmit();
														}
													}}
												>
													{t("Вхід")}
												</button>
											</div>
										</Form>
									);
								}}
							</Formik>
						</>
					)}
				</div>
			</div>
		);
	}
};

export default PopupForApprovePhoneAndSetNewPass;
