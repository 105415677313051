import React, { useState, useEffect } from "react";
import s from "./AdCardMobile.module.css";
import NoRegisterSvgSelector from "./NoRegisterSvgSelector";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import handleSortPhrases from "./sortPhrases";
import {
	colorArray,
	noRegisterPhrasesArray,
} from "../../data_arrays/BannersData";
import { useLocation } from "react-router-dom";
import { setOpenRegAuthPopup } from "../../redux/user_Reducer";
interface InterfacePhrasesArray {
	text: string;
	author: string;
}
const NoRegisterAdCardAdaptive = () => {
	const { t, i18n } = useTranslation();
	const [randomColor, setRandomColor] = useState("");
	const [randomPhrases, setRandomPhrases] =
		useState<InterfacePhrasesArray | null>(null);
	const dispatch: any = useDispatch();
	const location = useLocation();
	const pathAfterDomain = location.pathname;
	const currentLanguage = i18n.language === "ru" ? "" : "/ua";

	useEffect(() => {
		const sortResoult = handleSortPhrases(
			noRegisterPhrasesArray,
			"noAuthBagoPhrasesArray"
		);
		setRandomPhrases(sortResoult);

		const randomColorIndex = Math.floor(Math.random() * colorArray.length);
		setRandomColor(colorArray[randomColorIndex]);
	}, []);

	return (
		<>
			{randomColor && (
				<div
					className={s.cardWrap}
					style={{ padding: pathAfterDomain == "/" ? "0 12px" : "0 0px" }}
				>
					<div
						className={s.cardContainer}
						style={{ background: `#${randomColor}` }}
					>
						<div className={s.content}>
							<div className={s.contentDescriptionWrap_noRegister}>
								<p className={s.contentDescription}>
									{randomPhrases && t(`${randomPhrases?.text}`)}
								</p>
							</div>
							<div className={s.contentAuthorWrap}>
								<p className={s.contentDescriptionAuthor}>
									{randomPhrases && t(`${randomPhrases?.author}`)}
								</p>
							</div>
						</div>
						<div className={s.logo}>
							<NoRegisterSvgSelector id={"logoBago"} />
						</div>
						<div className={s.buttons}>
							<Link to={`${currentLanguage}/registration_auth`}>
								<button
									onClick={() => {
										dispatch(setOpenRegAuthPopup(true,"auth"));
									}}
									className={s.buttonEnter}
								>
									{t("вхід")}
								</button>
							</Link>
							<Link to={`${currentLanguage}/registration_auth`}>
								<button
									onClick={() => dispatch(setOpenRegAuthPopup(true,"reg"))}
									className={s.registration}
								>
									{t("Реєстрація")}
								</button>
							</Link>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default NoRegisterAdCardAdaptive;
