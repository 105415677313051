import React, { forwardRef, ReactNode, useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import ModalAutoRenewal from "../ModalWindows/ModalAutoRenewal/ModalAutoRenewal";
import { Backdrop } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ModalPitchToTop from "../ModalWindows/ModalPitchToTop/ModalPitchToTop";
import MyAdsCard from "./MyAdsCard/MyAdsCard";
import { NoticeDataForModal, NoticeType } from "../../../types/noticeTypes";
import { useDispatch, useSelector } from "react-redux";
import { setCreatedSuccessFalse } from "../../../redux/notice_Reducer";
import { StateType } from "../../../redux/redux-store";

export const useStyles = makeStyles(() =>
	createStyles({
		modalRenewal: {
			display: "flex",
			marginTop: "278px",
			justifyContent: "center",
			height: "404px",
		},
		modalPitchToTop: {
			display: "flex",
			marginTop: "278px",
			justifyContent: "center",
			height: "374px",
		},
	})
);

interface MyModalProps {
	children: ReactNode; // Ожидается, что MyModal будет получать дочерние элементы
}
export const MyModal = forwardRef<HTMLDivElement, MyModalProps>(
	({ children }, ref) => {
		return React.cloneElement(children as React.ReactElement, { ref });
	}
);
const MyAds = ({ type, notice }: { type: number; notice: NoticeType }) => {
	const [autoRenewalSettings, setOpenAutoRenewalSettings] = useState(false);
	const [pitchToTopSettings, setPitchToTopSettings] = useState(false);
	const dispatch: any = useDispatch();

	const successFalse = useSelector(() => setCreatedSuccessFalse);
	const classes = useStyles();
	const autoRenewalData = useSelector(
		(state: StateType) => state.priceData.autoRenewal
	);
	const topData = useSelector((state: StateType) => state.priceData.top);
	const balanceTotal = useSelector(
		(state: StateType) => state.userData.userStatic.totalBalance
	);

	const noticeDataModal: NoticeDataForModal = {
		name: notice.name,
		price: notice.price,
		img: notice?.noticeImages[0]?.imageUrl || "",
		settlement: notice.short_settlement_name,
		balance: balanceTotal,
		id: notice.id ? notice.id.toString() : "",
	};
	const handleCloseRenewal = () => {
		setOpenAutoRenewalSettings(false);
	};
	const handleClosePitchToTop = () => {
		setPitchToTopSettings(false);
	};
	const handleOpenAutoRenewal = () => {
		setOpenAutoRenewalSettings(true);
	};
	useEffect(() => {
		dispatch(successFalse());
	}, []);
	if (notice) {
		return (
			<>
				{autoRenewalData && (
					<div>
						<Modal
							aria-labelledby="transition-modal-title"
							aria-describedby="transition-modal-description"
							className={classes.modalRenewal}
							open={autoRenewalSettings}
							onClose={handleCloseRenewal}
							closeAfterTransition
							BackdropComponent={Backdrop}
							BackdropProps={{
								timeout: 500,
							}}
						>
							<MyModal>
								<ModalAutoRenewal
									noticeData={noticeDataModal}
									data={autoRenewalData}
									handleClose={handleCloseRenewal}
								/>
							</MyModal>
						</Modal>
					</div>
				)}

				<div>
					<Modal
						className={classes.modalPitchToTop}
						open={pitchToTopSettings}
						onClose={handleClosePitchToTop}
						closeAfterTransition
						BackdropComponent={Backdrop}
						BackdropProps={{
							timeout: 500,
						}}
					>
						<MyModal>
							<ModalPitchToTop
								noticeData={noticeDataModal}
								data={topData}
								handleClose={handleClosePitchToTop}
							/>
						</MyModal>
					</Modal>
				</div>

				<MyAdsCard
					type={type}
					notice={notice}
					setOpenAutoRenewalSettings={handleOpenAutoRenewal}
					setPitchToTopSettings={setPitchToTopSettings}
				/>
			</>
		);
	}
	return <></>;
};

export default MyAds;
