// noinspection JSNonASCIINames

export const ru = {
	translation: {
		/* Header*/
		Вихід: "Выход",
		"Вихід з акаунту": "Выход из аккаунта",
		"Я шукаю...": "Я ищу...",
		обране: "избранное",
		замовлення: "заказы",
		повiдомлення: "сообщения",
		безплатно: "бесплатно",
		кошик: "корзина",
		оголошення: "объявление",
		"додати оголошення": "подать объявление",
		вхід: "вход",
		кабінет: "кабинет",
		"Підтвердіть номер телефону": "Подтвердите номер телефона",
		"Оголошення, яке ви розпочали подавати, ще не було опубліковано. Бажаєте повернутися до цього оголошення?":
			"Объявление, которое вы начали подавать, еще не было опубликовано. Желаете вернуться к этому объявлению?",
		"Шукати на BAGO": "Искать на BAGO",
		/*   Main Search */
		"Історія пошуку": "История поиска",
		"Очистити історію": "Очистить историю",
		Очистити: "Очистить",
		Фільтри: "Фильтры",
		"Додати в цю рубрику оголошення": "Добавить в эту рубрику объявление",
		/*"фільтри":"фильтры",*/
		"Останні перегляди": "Недавние просмотры",
		"Введіть назву міста": "Введите название города",
		/*  Page 404 + Error Pages*/
		Перезавантажити: "Перезагрузить",
		"Перейти на головну": "Перейти на главную",
		"Перевірте з'єднання та перезавантажте сторінку":
			"Проверьте соединение и перезагрузите страницу",
		"Це оголошення більше не доступне!": "Это объявление больше не доступно!",
		"Ой щось пішло не так!": "Ой, что-то пошло не так!",
		"- Я все знайшла,": "- Я всё нашла,",
		"як ти і хотів,": "как ты и хотел,",
		"але хтось нас випередив": "но кто-то нас опередил",
		/*Window Registration & Auth*/
		Вхід: "Вход",
		Реєстрація: "Регистрация",
		"Реєструючись, Ви погоджуєтесь з ": "Регистрируясь, Вы соглашаетесь с ",
		"Під час авторизації, Ви погоджуєтесь з ":
			"Во время авторизации, Вы соглашаетесь с ",
		"Політикою Конфіденційності": "Политикой Конфиденциальности",
		"Угодою користувача": "Пользовательским соглашением",
		"та ": "и ",
		або: "или",
		"Швидкий вхід": "Быстрый вход",
		"Ім'я": "Имя",
		Зареєструватися: "Зарегистрироваться",
		"Відновлення паролю": "Восстановление пароля",
		"Придумайте пароль": "Придумайте пароль",
		"Введіть пароль": "Введите пароль",
		Сховати: "Спрятать",
		Показати: "Показать",
		всі: "все",
		"Запам'ятати мене": "Запомнить меня",
		"Забули пароль": "Забыли пароль",
		Відновити: "Восстановить",
		Далі: "Далее",
		"Введіть код підтвердження": "Введите код подтверждения",
		"Введіть код": "Введите код",
		"Код не може бути пустий": "Код не может быть пустой",
		"Код не має містити пробіли": "Код не должен содержать пробелы",
		"Код має бути не менше 6 символів": "Код должен быть не менее 6 символов",
		"Телефон успішно підтверджено!": " Телефон успешно подтверждён!",
		"Код підтвердження надіслано на номер":
			"Код подтверждения отправлен на номер",
		"Пароль не може містити кирилицю": "Пароль не может содержать кириллицу",
		"На вказаний телефон": "На указанный телефон",
		" було надіслано повідомлення. Введіть код та придумайте пароль":
			"было выслано сообщение.  Введите код и придумайте пароль",
		"Ви погоджуєтесь та приймаєте": "Вы соглашаетесь и принимаете",
		"Угода користувача": "Пользовательское соглашение",
		/*Personal cabinet popup*/
		Мова: "Язык",
		Валюта: "Валюта",
		Звук: "Звук",
		Знайти: "Найти",
		"Вся Україна": "Вся Украина",
		"Будь різним": "Будь разным",
		"Використовується для відновлення пароля":
			"Используется для восстановления пароля",
		"Шукай свою нішу": "Ищи свою нишу",
		"пробуй різні групи товарів": "пробуй разные группы товаров",
		"Страхова сума": "Страховая сумма",
		бонусів: "бонусов",
		"Поповнити Особистий рахунок": "Пополнить Личный счет",
		"Всі категорії": "Все категории",
		Транзакція: "Транзакция",
		Призначення: "Назначение",
		Сума: "Сумма",
		Промокод: "Промокод",
		"Підтвердіть пошту":"Подтвердите почту",
		"Промокод не може перевищувати 255 символів.":
			"Промокод не может превышать 255 символов.",
		"Введіть Ваш промокод": "Введите Ваш промокод",
		"Промокод не може бути порожнім.": "Промокод не может быть пустым.",
		"Успішно активовано промокод.": "Промокод успешно активирован.",
		"Помилка при активації промокоду.": "Ошибка при активации промокода.",
		/*Personal cabinet */
		"Управління особистими даними": "Управление личными данными",
		"Редагувати резюме": "Редактировать резюме",
		Резюме: "Резюме",
		"Додати фото": "Добавить фото",
		"Помилка при видаленні":"Ошибка при удалении",
		"Виявлено дублікат":"Обнаружен дубликат",
		"Перевищено ліміт зображень":"Превышен лимит изображений",
		"Невірний тип файлу":"Неправильный тип файла",
		"Невідома помилка":"Неизвестная ошибка",
		"Підняти оголошення за":"Поднять объявление за",
		"Додати логотип": "Добавить логотип",
		Портфоліо: "Портфолио",
		"Редагувати портфоліо": "Редактировать портфолио",
		З: "С",
		"Ім'я не може бути пусте": "Имя не может быть пустым",
		Всі: "Все",
		"Читати більше": "Читать больше",
		"Читати далі": "Читать далее",
		"Читати меньше": "Читать меньше",
		"Видалити акаунт": "Удалить аккаунт",
		"Видалити профіль користувача": "Удалить профиль пользователя",
		"До нових зустрічей": "До новых встреч",
		"Всі Ваші оголошення та": "Все Ваши объявления и",
		"Нам доведеться безповоротно обнулити ваш Баланс":
			"Нам придется безвозвратно обнулить ваш Баланс",
		"Вашу особисту інформацію буде видалено безповоротно":
			"Ваша личная информация будет удалена безвозвратно",
		"Всі Ваші оголошення та Ваша особиста інформація будуть видалені безповоротно":
			"Все Ваши объявления и Ваша личная информация  будут удалены безвозвратно",
		"Дорогий Користувач! Дякуємо за спільну мандрівку":
			"Дорогой Пользователь! Спасибо за совместное путешествие",
		"Але спогади про Вас назавжди залишаться у нашому серці.":
			"Но воспоминания о Вас навсегда останутся в нашем сердце.",
		"Переглянуті товари та оголошення": "Просмотренные товары и объявления",
		"Переглянутий товар": "Просмотренный товар",
		"Умови використання сайту": "Условия использования сайта",
		"Інформація для Продавців": "Информация для продавцов",
		"Переглянути звіти": "Посмотреть отчеты",
		Поповнити: "Пополнить",
		"Приватний рахунок": "Личный счёт",
		Бонуси: "Бонусы",
		/*Personal cabinet accordion*/
		"Контактні дані": "Контактные данные",
		"На вказану пошту було відправлено лист для підтвердження. Для підтвердження перейдіть по силці в листі":
			"На указанную почту было отправлено письмо с подтверждением. Для подтверждения перейдите по ссылке в письме",
		"Якщо на вказаному телефоні є месенджер і Ви бажаєте відобразити його у своїх контактах – виберіть месенджер.":
			"Если на указанном телефоне есть мессенджер и Вы желаете отобразить его в своих контактах - выберите мессенджер",
		Зберегти: "Сохранить",
		"Ваше ім'я": "Ваше имя",
		"Ваше прізвище": "Ваша фамилия",
		"Чекайте, з Вами зв'яжеться технічна підтримка Bago":
			"Ожидайте, с Вами свяжется техническая поддержка Bago",
		"Ви вичерпали ліміт СМС повідомлень для підтвердження номеру телефону":
			"Вы исчерпали лимит СМС сообщений для подтверждения номера телефона",
		"Змінити телефон для входу": "Изменить телефон для входа",
		"Зв'язати акаунти": "Связать аккаунты",
		"Налаштувати єдиний вхід через акаунти соціальних мереж":
			"Настроить единый вход через аккаунты социальных сетей",
		"Ваш телефон не підтверджений": "Ваш телефон не подтвержден",
		Відправити: "Отправить",
		"Введіть посилання на Вашу сторінку на іншому маркетплейсі, де буде видно ваш номер телефону та ваша репутація (кількість угод, дата реєстрації, позитивні відгуки)":
			"Введите ссылку на Вашу страницу на другом маркетплейсе, где будет видно Ваш номер телефона и Ваша репутация (колличество сделок, дата регистрации, положительные отзывы)",
		"Перевірити репутацію": "Проверить репутацию",
		"Даний запит модерується позачергово":
			"Данный запрос модерируется вне очереди",
		Cамовивіз: "Самовывоз",
		Увімкнено: "Включено",
		"Пароль не має містити пробіли": "Пароль не может содержать пробелы",
		"Пароль не може бути пустий": "Пароль не может быть пустым",
		"Не обрано": "Не выбрано",
		Підтвердити: "Подтвердить",
		"Виберіть зручні для вас способи доставки. Ці налаштування будуть застосовані до всіх ваших об'яв.":
			"Выберите удобные для Вас способы доставки. Эти настройки будут применены ко всем Вашим объявлениям",
		"Способи доставки": "Способы доставки",
		"Банківська карта": "Банковская карта",
		"Введіть номер картки, на яку відбуватиметься оплата за куплений у вас товар іншими користувачами":
			"Введите номер карточки, на которую будет проводится оплата за купленый у Вас товар другими пользователями",
		"Дані картки Не передаються Покупцю та Не відображаються в оголошеннях, а використовуються сервісом Privat24 або Monobank або LiqPay для проведення онлайн платежів у Безпечній угоді":
			"Данные карты Не передаются покупателю и Не отображаются в объявлениях, а используются сервисами Privat24 или Monobank или LiqPay для проведения онлайн платежей в Безопасной сделке",
		"Отримувати повідомлення, якщо у мене замовили товар":
			"Получать уведомления, если у меня заказали товар",
		Змінити: "Изменить",
		"Змінити пароль": "Изменить пароль",
		"Введіть новий email": "Введите новый email",
		"Додайте та підтвердіть номер телефону":
			"Добавте и подтвердите номер телефона",
		"Обмежені можливості": "Ограниченные возможности",
		"Показувати повідомлення": "Показывать уведомления",
		"У контактах про Продавця буде виводитись повідомлення:":
			"В контактах о Продавце будет выводиться сообщение",
		"“Вітаю, Я Людина з обмеженими можливостями - ":
			"“Приветствую, Я Человек с ограниченными возможностями - ",
		"зв'язатися зі мною ви можете тільки через чат або смс”":
			"связаться со мной Вы можете только через чат или смс”",
		"Пройти верифікацію": "Пройти верификацию",
		Станьте: "Станьте",
		"Перевірений продавець": "Проверенный продавец",
		"Підніміть свій рейтинг довіри для своїх Покупців":
			"Поднимите свой рейтинг доверия для своих покупателей",
		Прізвище: "Фамилия",
		Побатькові: "Отчество",
		"Додати аватар": "Фото профиля",
		"Відправити новий код": "Отправить новый код",
		/*"Підтвердіть номер телефону":"Подтвердите номер телефона",*/
		"Додати документ на перевірку": "Добавить документ на проверку",
		"Ми не зберігаємо та не публікуємо фото документа. Надіслане фото після модерації видаляється автоматично.":
			"Данные верификации не опубликовываются и не передаются другим пользователям.",
		"Ваше фото з документом в руці, де чітко видно вашу фотографію Прізвище Ім'я Побатькові (Можливо закрити номер документа, роспись і всі інші дані)":
			"Ваше фото с документом в руке, где чётко видна ваша фотография и Фамилия Имя Отчество (Можно закрыть Номер документа, роспись и все другие данные)",
		/*Create AD*/
		Публікувати: "Опубликовать",
		Опубліковано: "Опубликовано",
		Поскаржитись: "Пожаловаться",
		"Заголовок оголошення": "Заголовок объявления",
		"Введіть назву оголошення": "Введите название объявления",
		Переглянути: "Предпросмотр",
		"Оберіть категорію": "Выберите категорию",
		"Оберіть рубрику": "Выберите рубрику",
		Опис: "Описание",
		Фото: "Фото",
		"Максимальна кількість фото в цій рубриці": "Максимальное количество фотографий в этой рубрике",
		"Доступна кількість зображень для завантаження": "Доступное количество изображений для загрузки",
		"Опишіть ваш товар, як би ви хотіли його піднести покупцю. Розкажіть про найкращі його якості.":
			"Опишите ваш товар, как вы бы хотели его преподнести покупателю. Расскажите о лучших его качествах.",
		"Перетягніть фотографії сюди.": "Перетащите сюда фотографии.",
		"Перетягніть логотип сюди.": "Перетащите сюда логотип.",
		"Перша фотографія буде головною, щоб змінити порядок - пересуньте фото.":
			"Первая фотография будет главной, чтобы поменять порядок  - перетащите фото.",
		"Не менше 50 символів": "Не менее  50 символов",
		Стан: "Состояние",
		Нове: "Новое",
		Вживане: "Б/У",
		з: "с",
		"Радимо вам": "Советуем Вам",
		"Не давайте нікому дані банківської картки":
			"Не передавайте никому данные банковской карточки",
		Ціна: "Цена",
		"Введіть назву не менш ніж 16 символів":
			"Введите название не менее 16 символов",
		Договірна: "Договорная",
		Безкоштовно: "Бесплатно",
		Місцезнаходження: "Местоположение",
		"Контактна особа": "Контактное лицо",
		Автоподовження: "Автопродление",
		Автопідняття: "Автоподнятие",
		Підняття: "Поднятие",
		"Автопідняття 1 раз на": "Автоподнятие 1 раз в",
		"Кількість днів": "Кол-во дней",
		Налаштувати: "Настроить",
		Оголошення: "Объявление",
		"зі значком": "со значком",
		"Ваше оголошення відображатиметься": "Ваше объявление будет отображаться",
		" частіше та з періодичним повтором у":
			" чаще и с периодическим повтором в",
		"результатах пошуку разом з іншими оголошеннями":
			"результатах поиска вместе с другими объявлениями",
		"привертає увагу та збільшує кількість реакцій":
			"привлекает внимание и увеличивает количество реакций",
		"Гроші знімуться з вашого балансу в розмірі":
			"Деньги снимутся с вашего баланса в размере",
		Вимкнено: "Выключено",
		"1 раз": "1 раз",
		"Додати до ТОП": "Добавить в ТОП",
		" днів": " дней",
		вим: "выкл",
		"10 грн/підняття": "10 грн/поднятие",
		"10 грн/день": "10 грн/день",
		"До оплати": "К оплате",
		"Автопідняття оголошення 1 раз у": "Автоподнятие объявления 1 раз в",
		"Гроші будуть зніматися з вашого балансу в розмірі":
			"Деньги будут сниматься с вашего баланса в размере",
		підняття: "поднятие",
		на: "в",
		днів: "дней",
		Залишилось: "Осталось",
		Оплатити: "Оплатить",
		"Налаштувати автопідняття": "Настроить автоподнятие",
		"Замовити та оплатити 1-е підняття": "Заказать и оплатить 1-е поднятие",
		"Замовити та оплатити підняття": "Заказать и оплатить поднятие",
		"поки ви не скасуєте автопідняття або деактивуєте оголошення":
			"пока вы не отмените автоподнятие или деактивируете объявление",
		"Кількість на складі": "Колличество на складе",
		"Додаткові характеристики": "Дополнительные характеристики ",
		"Виберіть кількість днів": "Выбирите количество дней",
		"Не показувати з цим оголошенням ": "Не показывать с этим объявлением ",
		"Блок “Інші оголошення автора”": "Блок “Другие объявления автора”",
		"поля обов'язкові для заповненя": "поля обязательны для заполнения",
		"Введіть назву міста українською": "Введите название города на русском",
		"Додати своє": "Добавить своё",
		"Шукати, або залишити власне значення": "Искать или оставить своё значение",
		"Створити оголошення": "Создать объявление",
		"Редагувати оголошення": "Редактировать объявление",
		"Цікаво, а": "Интересно, а",
		будуть: "будут",
		"Вибір за категорією": "Выбор по категории",
		"Зробити фото": "Сделать фото",
		"Вибрати фото з галереї": "Выбрать фото из галереи",
		"Помилка під час завантаження файлу": "Ошибка при загрузке файла",
		"Автопідбір рубрики": "Автоподбор рубрики",
		"Виберіть категорії": "Выберите категории",
		"Пошук рубрики по назві": "Поиск рубрики по названию",
		"Переключити камеру": "Переключить камеру",
		/*   "":"",*/
		/*================Output Board AD ======================*/
		"Повернутися до створення": "Вернуться к созданию",
		Написати: "Написать",
		Подзвонити: "Позвонить",
		Замовити: "Заказать",
		Переглядів: "Просмотров",
		Продавець: "Продавец",
		опис: "описание",
		"б/в": "б/у",
		"Останній візит": "Последний визит",
		"З цим товаром переглядають": "С этим товаром просматривают",
		"Щоб використовувати весь функціонал сайту вам необхідно":
			"Чтобы использовать весь функционал сайта вам необходимо",
		"ввійти або зареєструватись": "войти или зарегистрироваться",
		"Схожі оголошення": "Похожие объявления",
		"Топ товари в цій рубриці": "Топ товары в этой рубрике",
		/*=======Image Input ==============*/
		"Перетягніть фотографію сюди": "Перетащите фотографию сюда",
		"Для створення оголошення потрібно підтвердити свій номер телефону":
			"Для создания объявления необходимо подтвердить свой номер телефона",
		"Зараз дивляться": "Сейчас смотрят",
		Розвернути: "Развернуть",
		/*============Time ==============*/
		хв: "мин",
		р: "г",
		/*============Approve popup ===============*/
		/*============ Filter By Price start ===============*/
		від: "от",
		до: "до",
		Скинути: "Сбросить",
		/*============ Filter By Price end ===============*/
		/*=====New Adverts ===========*/
		"Дивитися ще": "Смотреть еще",

		/*=================  Cabinet Nav Bar ===========*/
		"Переглянуті товари": "Просмотренные товары",
		"Обрані оголошення": "Избранные объявления",
		Обрані: "Избранные",
		Баланс: "Баланс",
		"Мої оголошення": "Мои обьявления",
		Замовлення: "Заказы",
		"Мої повідомлення": "Мои сообщения",
		"Мої покупки": "Мои покупки",
		Партнерка: "Партнёрка",
		"Мої відгуки": "Мои отзывы",
		"Рекламний кабінет": "Рекламный кабинет",
		"Управління бізнес-профілем": "Управление бизнес-профилем",
		/*====================Home Page =================*/
		"Нові оголошення": "Новые объявления",
		"Популярний продукт": "Популярный продукт",
		"Безкоштовна дошка оголошень": "Бесплатная доска объявлений",
		/*"Популярнi рубрики": "Популярные рубрики",*/
		"Пошук по всьому сайту": "Поиск по всему сайту",
		"Шукати ": "Искать ",
		історію: "историю",
		"як ти і хотів": "как ты и хотел",
		" у всіх рубриках": " во всех рубриках",
		Знайдено: "Найдено",
		"в рубриках": "в рубриках",
		/*'Шукати на bago': 'Искать на bago',*/
		"Бізнес та": "Бизнес и",
		Послуги: "Услуги",
		Бестселери: "Бестселлеры",
		"ТОП товари": "ТОП товары",
		Акції: "Акции",
		"Раніше переглянуті": "Раннее просмотренные",
		Згорнути: "Свернуть",
		Розгорнути: "Развернуть",
		Категорії: "Категории",
		Вибрати: "Выбрать",
		/*======== Search result Select===============*/
		Нові: "Новые",
		"За популярністю": "По популярности",
		"Спочатку дешеві": "Сначала недорогие",
		"Спочатку дорогі": "Сначала дорогие",
		Вживані: "Б/У",
		/*=========My ADS=================*/
		"мої оголошення": "мои объявления",
		Неактивні: "Неактивные",
		Відхилені: "Отклоненные",
		Активні: "Активные",
		/*========Product Card =============*/
		Сьогодні: "Сегодня",
		Вчора: "Вчера",
		/*=====Validate Form ============*/
		"Не більше 150 символів": "Не больше 150 символов",
		"Не менше 10 символів": "Не меньше 10 символов",
		"в категорії": "в категории",
		/*========My Ads Card============*/
		Подивитися: "Посмотреть",
		Видалити: "Удалить",
		"Завантажую...": "Загружаю...",
		"Розмір файлу перевищує 5мб": "Размер файла превышает 5мб",
		Активувати: "Активировать",
		Редагувати: "Редактировать",
		Рекламувати: "Рекламировать",
		"Причина відхилення": "Причина отклонения",
		"На перевірці": "На проверке",
		Деактивувати: "Деактивировать",
		Відмінити: "Отменить",
		автопідняття: "автоподнятие",
		"Підняти 1 раз": "Поднять 1 раз",
		"Підняти за": "Поднять за",
		наст: "cлед",
		"Ця дія видалить оголошення назавжди":
			"Это действие удалит объявление окончательно",
		Продовжити: "Продолжить",
		Так: "Да",
		Ні: "Нет",
		/*========== My Favorite ======================*/
		"Обрані товари та оголошення": "Избранные товары и объявления",
		"Видалити все": "Удалить всё",
		"Обраний товар": "Избранный товар",
		"Обрані пошуки": "Избранные поиски",
		Візитки: "Визитки",
		Пошук: "Поиск",
		"Не знайдено": "Не найдено",
		"Оберіть месенджер, по якому з Вами можуть зв'язатись інші користувачі":
			"Выберите мессенджер, по которому с Вами могут связаться другие пользователи",
		"Пароль має бути не менше 8 символів":
			"Пароль должен быть не меньше 8 символов",
		/*========== Banner for authorized users ======================*/
		"Зараз – ти працюєш на час. Завтра – час працює на тебе. Дій!":
			"Сейчас – ты работаешь на время. Завтра – время работает на тебя. Действуй!",
		"Мета – це мрія з кінцевим терміном виконання.":
			"Цель – это мечта с конечним сроком исполнения.",
		"Мета без плану – лише бажання.": "Цель без плана – всего лишь желание.",
		"Мета – це якір, який допомагає зосередитися на майбутньому.":
			"Цель – это якорь, который помогает сосредоточиться на будущем.",
		"Якщо у вас немає мети, ви ніколи не потрапите в ціль.":
			"Если у вас нет цели, вы никогда не попадете в цель.",
		"Люди з метою живуть осмисленим життям.":
			"Люди с целью живут осмысленной жизнью.",
		"Мета – це світло, яке показує шлях, навіть у найтемнішій ночі.":
			"Цель - это свет, который показывает путь, даже в самую тёмную ночь",
		"Мета – це мрія з планом дій.":
			"Цель – это секретное оружие успешных людей.",
		"Мета – це секретна зброя успішних людей.":
			"Цель - это секретное оружие успешных людей.",
		"Мета – це фокус, який дозволяє нам побачити те, що інші не помічають.":
			"Цель – это фокус, который позволяет нам увидеть то, что другие не замечают.",
		"Мета – це мрія, зроблена конкретною та вимірюваною.":
			"Цель - это мечта, сделанная конкретной и измеримой.",
		"Мета – це план, що призводить до успіху.":
			"Цель - это план, который приводит к успеху.",
		"Без мети ми пливемо за течією, а з метою - ми керуємо напрямом.":
			"Без цели мы плывем по течению, а с целью - мы управляем направлением.",
		"Щастя – це бути в сьогоденні, і воно доступне саме зараз.":
			"Счастье – это быть в настоящем, и оно доступно именно сейчас.",
		"Саме зараз ви можете змінити свою долю.":
			"Прямо сейчас вы можете изменить свою судьбу.",
		"Саме зараз – це Ваш час діяти, не чекайте кращого моменту.":
			"Именно сейчас – это Ваше время действовать, не ждите лучшего момента.",
		"Щастя – це внутрішній спокій та гармонія із собою.":
			"Счастье – это внутреннее спокойствие и гармония с собой. ",
		"Щастя це коли ви задоволені тим, що у вас є.":
			"Счастье – это когда вы довольны тем, что у вас есть.",
		"Щастя – це віддати бабусі онуків та зайнятися покупками.":
			"Счастье – это отдать бабушке внуков и заняться покупками.",
		"Колись ти згадуватимеш цей час, як найщасливіший у своєму житті! Насолоджуйся тут і зараз!":
			"Когда-то ты будешь вспоминать этот период, как самый счастливый в своей жизни! Наслаждайся здесь и сейчас!",
		"Якщо ти вмієш читати та аналізувати – це неймовірна перевага.":
			"Если ты умеешь читать и анализировать, это невероятное преимущество.",
		"не давайте нікому дані своєї картки.":
			"не давайте никому данные своей карты.",
		"користуйтесь післяплатою.": "пользуйтесь наложеным платежом.",
		"використовуйте функцію Безпечне замовлення.":
			"используйте функцию Безопасный заказ.",
		"ніколи не перераховуйте передоплат або авансів.":
			"никогда не перечисляйте предоплат или авансов.",
		"перевіряйте доступними способами репутацію продавця.":
			"проверяте доступными способами репутацию продавца.",
		"не натискайте невідомі посилання.": "не нажимайте неизвесные ссылки.",
		"не натискайте посилання від невідомих контактів.":
			"не нажимайте ссылки от неизвесных контактов.",
		"перевіряйте комплектацію та цілісність товару на пошті.":
			"проверяйте комплектацию и целосность товара на почте.",
		"Ціна бездіяльності набагато вища за ціну кожної помилки.":
			"Цена бездействия намного выше цены каждой ошибки.",
		"Бізнес будується на тих людях, яким не байдуже.":
			"Бизнес строится на тех людях, которым не всё равно.",
		"Дбаючи про щастя інших, ми знаходимо своє власне.":
			"Заботясь о счастье других, мы находим своё собcтвенное.",
		"Рівність створює дружбу.": "Равенство создаёт дружбу.",
		"Ми щасливі лише тоді, коли перебуваємо у сьогоденні.":
			"Мы счастливые только тогда, когда находимся в настоящем.",
		"У момент проблеми ви не підніметеся до рівня своїх очікувань, а впадете до рівня своїх можливостей.":
			"В момент проблемы вы не подниметесь до уровня своих ожиданий, а свалитесь до уровня своих возможностей.",
		"Одержимість – це той мотор, який призводить до перемоги, коли всі інші вже здалися.":
			"Одержимость – это тот мотор, который приводит к победе, когда все другие уже сдались.",
		"Одержимість – це сила, яка змушує тебе долати всі перешкоди, навіть коли весь світ проти тебе.":
			"Одержимость – это та сила, которая заставляет тебя преодолевать все преграды, даже если весь мир против тебя.",
		"Підприємець це той, хто бачить можливості там, де інші бачать труднощі.":
			"Предприниматель — это тот, кто видит возможности там, где другие видят трудности.",
		"Справжній підприємець не боїться провалів, він боїться згаяти шанс на успіх.":
			"Истинный предприниматель не боится провалов, он боится упустить шанс на успех.",
		"Підприємець – це той, хто бачить у кожній невдачі можливість для нового початку.":
			"Предприниматель – это тот, кто видит в каждой неудаче возможность для нового начала.",
		"Бізнес – це мистецтво бачити не лише те, що є, а й те, що можливо.":
			"Бизнес – это искусство видеть не только то, что есть, но и то, что возможно.",
		"У світі бізнесу немає сталості, лише постійні зміни та можливості.":
			"В мире бизнеса нет постоянства, только постоянные изменения и возможности.",
		"Усі успішні маркетплейсери колись починали з простого акаунту продавця.":
			"Все успешные маркетплейсеры когда-то начинали с простого аккаунта продавца.",
		"Щастя швидкоплинне і непостійне, але його цінність полягає саме в цих швидкоплинних миттєвостях, які змушують нас по-справжньому відчувати повноту життя.":
			"Счастье скоротечно и непостоянно, но его ценность заключается именно в этих мимолетных мгновениях, которые заставляют нас по-настоящему ощущать полноту жизни.",
		"Яка головна мета Вашого Життя? Що Ви зробили сьогодні, щоб наблизити її?":
			"Какая главная цель Вашей Жизни? Что Вы сделали сегодня, чтобы её приблизить?",
		"це місце, де народжуються нові можливості":
			"это место, где рождаются новые возможности.",
		"це місце для стрибка в майбутнє.": "это место для прыжка в будущее.",
		"Мистецтво підприємництва полягає в тому, щоб з нічого зробити щось.":
			"Искусство предпринимательства состоит в том, чтобы из ничего сделать что—то.",
		"Краще, коли дружба виросла з бізнесу, ніж коли бізнес виріс із дружби.":
			"Лучше, когда дружба выросла из бизнеса, чем когда бизнес вырос из дружбы.",
		"Наш бізнес – це люди, яким ми продаємо каву, а не кава, яку ми продаємо людям.":
			"Наш бизнес – это люди, которым мы продаём кофе, а не кофе, который мы продаём людям.",
		"Визначний бізнес повинен мати совість.":
			"Выдающийся бизнес должен обладать совестью.",
		"Якщо ви чекаєте на ідеальні умови, ви ніколи нічого не зробите.":
			"Если вы ждете идеальных условий, вы никогда ничего не сделаете.",
		"Будьте Зміною, яку ви хочете бачити у світі.":
			"Будьте изменением, которое вы хотите видеть в мире.",
		"Негативний результат – це також результат!":
			"Отрицательный результат - это тоже результат!",
		"Я не зазнав поразки. Я щойно знайшов 10,000 способів, які не працюють.":
			"Я не потерпел поражения. Я только что нашел 10,000 способов, которые не работают.",
		"Єдиний спосіб зробити чудову роботу – любити те, що ви робите.":
			"Единственный способ сделать великолепную работу — любить то, что вы делаете.",
		"Ваш час обмежений, не витрачайте його, живучи чужим життям.":
			"Ваше время ограничено, не тратьте его, живя чужой жизнью.",
		"Вам не потрібні 100 книг про саморозвиток, Вам потрібно тільки самодисципліна та дії.":
			"Вам не нужны 100 книг о саморазвитии, Вам нужны только самодисциплина и действия.",
		"Момент – це точка найбільшої повноти Вашого життя.":
			"Момент - это точка наибольшей полноты Вашей жизни.",
		"Життя – це самий дорогоцінний подарунок твоїх батьків.":
			"Жизнь - это самый драгоценный подарок твоих родителей.",
		"У бізнесі кожна хвилина має власну ціну.":
			"В бизнесе каждая минута имеет свою цену.",
		"Час – найважливіший інвестиційний актив у бізнесі.":
			"Время — самый важный инвестиционный актив в бизнесе.",
		"Твій вільний Час – це міра успіху у світі підприємництва.":
			"Твоё свободное Время — это мера успеха в мире предпринимательства.",
		"Істина полягає в тому, що Щастя завжди швидкоплинне. Та коли приходить Щастя – радійте! Радійте, як дитина, і ніколи не відмовляйте собі в цьому!":
			"Истина состоит в том, что Счатье всегда скоротечно. И когда приходит Счастье - радуйтесь! Радуйтесь, как ребёнок, и никогда не отказывайте себе в этом!",
		"Думка - це енергія.": "Мысль - это энергия.",
		"Якщо чітко записати проблему, то справа наполовину вирішена.":
			"Если четко записать проблему, то дело наполовину решено.",
		"Думка - це початок всього. Думка створює світ.":
			"Мысль — это начало всего. Мысль создаёт мир.",
		"Природа не поспішає, але всього досягає.":
			"Природа не торопится, но всё достигает.",
		"Приймайте важке так само легко, як і просте; велике - так само легко, як і маленьке.":
			"Принимайте трудное так же легко, как и простое; большое — так же легко, как и маленькое.",
		"Сама глибока річка робиться з маленьких струмочків.":
			"Самая глубокая река делается из маленьких ручейков.",
		"Щастя — це шлях, а не місце, куди ми приходимо.":
			"Счастье — это путь, а не место, куда мы приходим.",
		"Той, хто надто багато каже, часто нічого не робить.":
			"Тот, кто слишком много говорит, часто ничего не делает.",
		"Найкращий спосіб побачити світло – це бути в темряві.":
			"Лучший способ увидеть свет — это быть в темноте.",
		"Той, хто надто зайнятий, щоб дбати про своє здоров'я, рано чи пізно буде змушений дбати про свою хворобу.":
			"Тот, кто слишком занят, чтобы заботиться о своём здоровье, рано или поздно будет вынужден заботиться о своей болезни.",
		"Два найважливіші дні у вашому житті - це день, коли ви народилися, і день, коли ви зрозуміли, навіщо.":
			"Два наиболее важных дня в вашей жизни - это день, когда вы родились, и день, когда вы поняли, зачем.",
		"Ви просто пливете за течією, а я бачу гребінь хвилі і щосили гребу до нього!":
			"Вы просто плывёте по течению, а я вижу гребень волны  и изо всех сил гребу к на него!",
		"Бізнес – це втілення мрії. Ненавиджу тих, для кого це просто гроші.":
			"Бизнес  - это воплощение мечты. Ненавижу тех, для кого это просто деньги.",
		"95% людей мають мрію, яку можна здійснити до кінця тижня, а вони її роблять мрією всього життя.":
			"У 95% людей есть мечта, которую можно исполнить до конца недели, а они её делают мечтою всей жизни.",
		"Коли ви чогось дуже сильно бажаєте, в гру вступає Закон притягнення.":
			"Когда вы чего-то очень сильно хотите, в игру вступает Закон приятжения.",
		"Ніхто мені не казав, що це неможливо, тож я зробив роботу.":
			"Никто мне не говорил, что это невозможно, поэтому я  сделал работу.",
		"Коли мені кажуть, що це неможливо – я працюю з подвійною старанністю.":
			"Когда мне говорят, что это невозможно - я работаю с двойным усердием.",
		"Щастя - це знайти того, хто шукав тебе.":
			"Счастье - это найти того кто искал тебя.",
		"Проблема цього світу в тому, що виховані люди сповнені сумнівів, а ідіоти сповнені впевненості.":
			"Проблема этого мира в том, что воспитанные люди полны сомнений, а идиоты полны уверенности.",
		"Бережіть в собі людину.": "Берегите в себе человека.",
		"Хто не жив посеред бурі, той ціни не знає силі.":
			"Кто не жил посреди бури, тот цены не знает силы. ",
		"Хто не журиться, не плаче, то той ніколи й не радіє.Хто не журиться, не плаче, то той ніколи й не радіє.":
			"Кто не печалится и не плачет, тот никогда и не радуется.",
		"Вогонь запеклих не пече.": "Огонь закалённых не печёт.",
		"Поразка - це наука, ніяка перемога так не вчить.":
			"Поражение – это наука, никакая победа так не учит.",
		"Активність – це ключ до успіху. Життя дає шанс лише тим, хто не просто чекає, а діє.":
			"Активность — это ключ к успеху. Жизнь дает шанс только тем, кто не просто ждет, а действует.",
		"Життя подібне до велосипеда: щоб зберегти рівновагу, потрібно рухатися.":
			"Жизнь подобна велосипеду: чтобы сохранить равновесие, нужно двигаться.",
		"Щоразу, коли ти дієш, ти створюєш енергію, яка повертається до тебе у вигляді нових можливостей.":
			"Всякий раз, когда ты действуешь, ты создаешь энергию, которая возвращается к тебе в виде новых возможностей.",
		"Козак є людина вільна, а свобода - над усіма законами.":
			"Казак есть человек свободный, а свобода - над всеми законами.",
		"Підприємництво — це сміливість приймати рішення, незважаючи на невизначеність та ризики.":
			"Предпринимательство — это смелость принимать решения, несмотря на неопределенность и риски.",
		"Одержимість - це іскра, яка запалює шлях до успіху, надихаючи нас долати всі труднощі на шляху.":
			"Одержимость — это искра, которая зажигает путь к успеху, вдохновляя нас преодолевать все трудности на пути.",
		"Коли пристрасть стає вашим способом життя, ви стаєте непереможними.":
			"Когда страсть становится вашим образом жизни, вы становитесь непобедимыми.",
		'Бізнесмена часто запитують: „Чому?“. Це хороше питання, але не менш важливе: "Чому б і ні?"':
			"Бизнесмена часто спрашивают: „Почему?“. Это хороший вопрос, но не менее важный: „Почему бы и нет?“",
		"Життя - це те, що відбувається з тобою, поки ти жваво будуєш інші плани.":
			"Жизнь — это то, что происходит с тобой, пока ты оживлённо строишь другие планы.",
		"Ваше життя на 10% залежить від того, що з вами відбувається, і на 90% від того, як ви реагуєте на ці події.":
			"Ваша жизнь на 10% зависит от того, что с вами происходит, и на 90% от того, как вы реагируете на эти события.",
		"Наше сознание – это все. Вы становитесь тем, о чем думаете.":
			"Наше сознание – это все. Вы становитесь тем, о чем думаете.",
		"Найкращий час, щоб посадити дерево, було 20 років тому. Наступний найкращий час – сьогодні.":
			"Лучшее время, чтобы посадить яблоню, было 20 лет назад. Следующее лучшее время – сегодня.",
		"Ти ніколи не перетнеш океан, якщо не наберешся сміливості втратити з уваги берег.":
			"Ты никогда не пересечёшь океан, если не наберёшься смелости потерять из виду берег.",
		"Найкращою помстою є величезний успіх.":
			"Лучшей местью является огромный успех.",
		"Перешкоди - це ті страшні речі, які ви бачите, коли відводите очі від мети.":
			"Препятствия – это те страшные вещи, которые вы видите, когда отводите глаза от цели.",
		"Сім разів упади – вісім разів устань.":
			"Семь раз упади – восемь раз встань.",
		"Коли зачиняються одні двері до щастя – відчиняються інші, але ми так довго дивимося на зачинені двері, що не помічаємо ті, які вже відчинилися для нас.":
			"Когда закрывается одна дверь к счастью – открывается другая, но мы так долго смотрим на закрытую дверь, что не замечаем ту, которая уже распахнулась для нас.",
		"Щастя не буває у готовому вигляді. Воно походить від ваших власних дій.":
			"Счастья не бывает в готовом виде. Оно происходит от ваших собственных действий.",
		"Якщо вітер стих, берись за весла.": "Если ветер утих, берись за вёсла.",
		"Людина, яка ніколи не помилялася, ніколи не намагалася зробити щось нове.":
			"Человек, который никогда не ошибался, никогда не пробовал сделать что-нибудь новое.",
		"Труднощі роблять наше життя цікавішим, а їхнє подолання наповнює наше життя змістом.":
			"Трудности делают нашу жизнь интереснее, а их преодоление наполняет нашу жизнь смыслом.",
		"Без відхилення від норми прогрес неможливий.":
			"Без отклонения от нормы прогресс невозможен.",
		"Той, хто не хоче працювати на себе - працюватиме на інших.":
			"Тот кто не хочет работать на себя -  будет работать на других.",
		"Той, хто не хоче платити за освіту - платитиме за невігластво.":
			"Тот кто не хочет платить за образование - будет платить за невежество.",
		"Пам'ятай, що неотримання того, що ти хочеш, часом є справжньою усмішкою долі.":
			"Помни, что неполучение того, что ты хочешь, порой является настоящей улыбкой судьбы.",
		"Коли здається, що весь світ налаштований проти тебе, пам'ятай, що літак злітає проти вітру.":
			"Когда кажется, что весь мир настроен против тебя, помни, что самолёт взлетает против ветра.",
		"Ви повинні горіти ідеєю, або проблемою, або помилкою, яку ви хочете виправити. Якщо у вас недостатньо пристрасті з самого початку, ви ніколи не досягнете успіху.":
			"Вы должны гореть идеей, или проблемой, или ошибкой, которую вы жаждите исправить. Если в вас недостаточно страсти с самого начала, вы никогда не преуспеете.",
		"Якість означає виконувати роботу правильно, коли ніхто не дивиться.":
			"Качество означает делать работу правильно, когда никто не смотрит. ",
		"Ваші найбільш незадоволені клієнти – ваше найбільше джерело навчання.":
			"Ваши самые недовольные клиенты – ваш самый большой источник обучения.",
		"Коли ви недооцінюєте те, що робите, світ недооцінюватиме те, ким ви є.":
			"Когда вы недооцениваете то, что делаете, мир будет недооценивать то, кем вы есть.",
		"Якщо ти не керуєш своїми цілями, то твоїми цілями керує хтось інший.":
			"Если ты не управляешь своими целями, то твоими целями управяет кто-то другой.",
		"Проактивність – це коли ти сам вигадуєш, що робити.":
			"Проактивность - это когда ты сам придумываешь, что делать.",
		"Тільки встановленна ціль допомагає правильно розставити пріоритети.":
			"Только поставленная цель помогает правильно расставить приоритеты.",
		"Гроші найчастіше платять за відмову від власних цілей.":
			"Чаще всего деньгами платят за отказ от собственных целей.",
		"Перед злетом тебе доля обов'язково проведе через дно.":
			"Перед взлётом тебя судьба обязательно проведёт через дно.",
		"Несвідоме завжди вибирає найкращий варіант із наявних.":
			"Безсознательное всегда выбирает лучший вариант из имеющихся.",
		"Не можна від цього світу взяти більше, ніж ти віддав.":
			"Нельзя от этого мира взять больше, чем ты в него отдал.",
		"Енергія йде туди, куди ми спрямовуємо своє мислення. Особливо не щастить людям, які бажають іншим зла. Думайте позитивно, випромінюйте добро.":
			"Энергия идёт туда, куда мы направляем своё мышление. Особенно не везёт людям, которые желают другим зла. Мыслите позитивно, излучайте добро.",
		"Всі геніальні люди дуже незручні, тому що вони нарциси, вони ніколи не йдуть у ногу та не підлаштовуються ні під кого.":
			"Все гениальные люди очень неудобны, потому что они нарцысы, они никогда не ходят строем и не подстраиваются ни под кого.",
		"Дорогою до мети - аналізуйте шлях, а досягнувши вершини, ставте нову.":
			"По дороге к цели - анализируйте путь, а достигнув вершины, ставьте новую.",
		"Завдання, які Ви в минулому не вирішили, Ви не збираєтеся їх вирішувати. Навчіться відпускати минуле і йдіть далі.":
			"Задачи, которые Вы в прошлом не решили, Вы и не собираетесь их решать. Научитесь отпускать прошлое и идите дальше.",
		"Думка – це енергія.": "Мысль – это энергия.",
		"Думка – це початок всього. Думка створює світ.":
			"Мысль – это начало всего. Мысль создаёт мир.",
		"Приймайте важке так само легко, як і просте; велике – так само легко, як і маленьке.":
			"Принимайте трудное так же легко, как и простое; большое – так же легко, как и маленькое.",
		"Два найважливіші дні у вашому житті – це день, коли ви народилися, і день, коли ви зрозуміли, навіщо.":
			"Два наиболее важных дня в вашей жизни – это день, когда вы родились, и день, когда вы поняли, зачем.",
		"Щастя – це знайти того, хто шукав тебе.":
			"Счастье – это найти того кто искал тебя.",
		"Поразка – це наука, ніяка перемога так не вчить.":
			"Поражение – это наука, никакая победа так не учит.",
		"Козак є людина вільна, а свобода – над усіма законами.":
			"Казак есть человек свободный, а свобода – над всеми законами.",
		"Підприємництво – це сміливість приймати рішення, незважаючи на невизначеність та ризики.":
			"Предпринимательство – это смелость принимать решения, несмотря на неопределенность и риски.",
		"Одержимість – це іскра, яка запалює шлях до успіху, надихаючи нас долати всі труднощі на шляху.":
			"Одержимость – это искра, которая зажигает путь к успеху, вдохновляя нас преодолевать все трудности на пути.",
		"Бізнесмена часто запитують: „Чому?“. Це хороше питання, але не менш важливе: „Чому б і ні?“":
			"Бизнесмена часто спрашивают: „Почему?“. Это хороший вопрос, но не менее важный: „Почему бы и нет?“",
		"Життя – це те, що відбувається з тобою, поки ти жваво будуєш інші плани.":
			"Жизнь – это то, что происходит с тобой, пока ты оживлённо строишь другие планы.",
		"Наша свідомість – це все. Ви стаєте тим, що думаєте.":
			"Наше сознание – это все. Вы становитесь тем, о чем думаете.",
		"Перешкоди – це ті страшні речі, які ви бачите, коли відводите очі від мети.":
			"Препятствия – это те страшные вещи, которые вы видите, когда отводите глаза от цели.",
		"Той, хто не хоче працювати на себе – працюватиме на інших.":
			"Тот кто не хочет работать на себя – будет работать на других.",
		"Той, хто не хоче платити за освіту – платитиме за невігластво.":
			"Тот кто не хочет платить за образование – будет платить за невежество.",
		"Дорогою до мети – аналізуйте шлях, а досягнувши вершини, ставте нову.":
			"По дороге к цели – анализируйте путь, а достигнув вершины, ставьте новую.",
		"Не шукайте одну продаж, намагайтеся знайти потік.":
			"Не ищите одну продажу, старайтесь найти поток.",
		/*========== Banner for not authorized users ======================*/
		"Будь-який шлях починається з першого кроку.":
			"Любой путь начинается с первого шага.",
		"За будь-якими дверима може бути початок нового шляху.":
			"За любой дверью может быть начало нового пути.",
		"Вихід із ситуації, що склалася, найчастіше, знаходиться там же, де і ВХІД.":
			"Выход из сложившейся ситуации чаще всего находится там же, где и ВХОД.",
		"Початок – це половина всього.": "Начало – это половина всего.",
		"Початок – це момент, коли мрії перетворюються на реальність.":
			"Начало – это момент, когда мечты превращаются в реальность.",
		"Почни, зробивши те, що необхідно, потім зроби те, що можливо, і раптом ти побачиш, що зробив неможливе.":
			"Начни, сделав то, что необходимо, затем сделай то, что возможно, и вдруг ты увидишь, что сделал невозможное.",
		"Важливо не лише розпочати, а розпочати правильно.":
			"Важно не только начать, но и начать правильно.",
		"Усі великі справи починаються з того моменту, коли ви вирішуєте щось зробити.":
			"Все великие дела начинаются с того момента, когда вы решаете что-то сделать.",
		"Початок – це момент у житті, коли ви вирішуєте, що чогось хочете найбільше.":
			"Начало – это момент в жизни, когда вы решаете, что чего-то хотите больше всего.",
		"Кожен початок – це можливість.": "Каждое начало – это возможность.",
		"Кожен Вхід – це можливість.": "Каждый Вход – это возможность.",
		"Початок – це найважливіша частина твоєї роботи.":
			"Начало – это самая важная часть твоей работы.",
		"Подивися на ці дві кнопки! Ти думав Невідомість виглядає якось інакше?":
			"Посмотри на эти 2 кнопки! Ты думал Неизвестность выглядит как-то иначе?",
		"bago – це місце, де народжуються нові можливості.":
			"bago – это место, где рождаются новые возможности.",
		"А ти проти маркетплейсів, що зажерлися? У тебе є Вибір!":
			"А ты против зажравшихся маркетплейсов? У тебя есть Выбор.",
		"Ми намагаємося зробити цей bago найкращим!":
			"Мы стараемся сделать этот bago лучшим!",
		"Bago – це наш дім. Щоб увійти – треба зареєструватися.":
			"Bago – это наш дом. Чтобы войти – нужно зарегистрироваться.",
		"bago – це місце для стрибка в майбутнє.":
			"bago - это место для прыжка в будущее.",
		"Хочеш стосунків? Лише після реєстрації!":
			"Хочешь отношений? Только после регистрации!",
		"Б'ємося об заклад, ти не зможеш подати оголошення без реєстрації?":
			"Спорим, ты не сможешь подать объявление без регистрации?",
		"Будь сміливим, зроби вибір)": "Будь смелым, сделай выбор)",
		"Пообіцяй, що не зраджуватимеш з іншими маркетплейсами...":
			"Пообещай, что не будешь изменять с другими маркетплейсами...",
		"Саме зараз – це момент, коли ви залишаєте минуле і вступаєте в майбутнє.":
			"Именно сейчас - это момент в жизни, который является ключом к новым возможностям и перспективам.",
		"Саме зараз - це момент, коли ви залишаєте минуле і вступаєте в майбутнє.":
			"Сейчас – это момент, когда вы оставляете прошлое  и вступаете в будущее.",
		"Саме зараз – той момент, коли треба зробити вибір, щоби почати щось нове.":
			"Именно сейчас – тот момент, когда надо сделать выбор, чтобы начать что-то новое.",
		"Зараз настав саме той момент, коли треба ухвалити непросте рішення)) >>>":
			"Сейчас настал именно тот момент, когда нужно принять непростое решение)) >>>",
		"Вхід – це той момент, коли світ стає доступним.":
			"Вход – это тот момент, когда мир становится доступным",
		"Щоб щось змінити, іноді потрібно спочатку Увійти.":
			"Чтобы что-то изменить, иногда нужно сначала Войти.",
		"Вхід у незвідане – це перший крок до пригод.":
			"Вход в неизведанное – это первый шаг к приключениям.",
		"Вхід у новий світ вимагає сміливості та рішучості.":
			"Вход в новый мир требует смелости и решительности.",
		"Вхід – це той момент, коли ми перетинаємо поріг можливостей.":
			"Вход – это тот момент, когда мы пересекаем порог возможностей.",
		"Будь-який Вхід – це новий досвід, а будь-який досвід – це майбутня можливість.":
			"Любой Вход – это новый опыт, а любой опыт – это будущая возможность.",
		"У житті бувають різні Входи, і кожен із них призводить до нового шляху.":
			"В жизни бывают разные Входы, и каждый из них ведет к новому пути.",
		"Саме зараз – це можливість зробити перший крок до своєї мети.":
			"Сейчас – это возможность сделать первый шаг к своей цели.",
		"Важливо пам'ятати, що кожен Вхід може призвести до нових виходів.":
			"Важно помнить, что каждый Вход может привести к новым выходам.",
		"Щоб використовувати весь функціонал сайту, вам необхідно увійти або зареєструватися.":
			"Чтобы использовать весь функционал сайта, вам необходимо войти или зарегистрироваться.",
		"У житті бувають різні Реєстрації, і кожна з них призводить до нового шляху)":
			"В жизни бывают разные Регистрации, и каждая из них ведет к новому пути)",
		"Реєстрація – це той момент, коли ми перетинаємо поріг можливостей.":
			"Регистрация – это тот момент, когда мы пересекаем порог возможностей.",
		"Bago – постійне місце Реєстрації вашого бізнесу.":
			"Bago – постоянное место Регистрации вашего бизнеса.",
		"Справжнє кохання не боїться нічого, навіть Реєстрації.":
			"Настоящая любовь не боится ничего, даже Регистрации.",
		"Зареєструємо наші відносини?": "Зарегистрируем наши отношения?",
		"У Реєстрації наших відносин не буде розлучення)":
			"В Регистрации наших отношений не будет развода)",
		"Бажаєш бачити тут свої Оголошення? Реєструйся та публікуй їх зовсім безкоштовно!":
			"Хочешь видеть здесь свои Объявления? Регистрируйся и публикуй их совершенно бесплатно!",
		"Нам по дорозі, якщо ти готовий до постійного розвитку та навчання.":
			"Нам по пути, если ты готов к постоянному развитию и обучению.",
		"Нам по дорозі, якщо ти готовий будувати надійні стосунки з клієнтами та партнерами.":
			"Нам по пути, если ты готов строить надежные отношения с клиентами  и  партнерами.",
		"Ціна бездіяльності, набагато вища за ціну кожної помилки.":
			"Цена бездействия, намного выше цены каждой ошибки.",
		"Якщо ти не одержимий – ти вже програв!":
			"Если ты не одержим – ты уже проиграл!",
		"Зробіть перший крок у вірі. Вам не потрібно бачити всі сходи, просто зробіть перший крок.":
			"Сделайте первый шаг в вере. Вам не нужно видеть всю лестницу, просто сделайте первый шаг.",
		"Подорож у тисячу миль починається з одного кроку.":
			"Путешествие в тысячу миль начинается с одного шага.",
		"Складні справи починаються з найпростіших. Великі справи починаються з малих.":
			"Сложные дела начинаются с простых. Большие дела начинаются с малых.",
		"Не бійтеся бути повільними, бійтеся залишатись на місці.":
			"Не бойтесь быть медленными, бойтесь оставаться на месте.",
		"Молодість завжди шукає щось нове. Якщо Ви боїтеся змін – Ви на порозі старості.":
			"Молодость всегда ищет что-то новое. Если Вы боитесь изменений  - Вы на пороге старости.",
		"Щастя – це шлях, а не місце, куди ми приходимо. ":
			"Счастье – это путь, а не место, куда мы приходим.",
		"Bago – це наш дім. Заходьте у гості!":
			"Bago – это наш дом. Заходите в гости!",
		"Мрія дана з силами здійснити її.": "Мечта дана с силами её осуществить.",
		"Все найцінніше у цьому житті ми отримуємо безкоштовно.":
			"Всё самое ценное в этой жизни мы получаем бесплатно.",
		"Найкращий спосіб пізнати людину – це побувати у нього в гостях. О-ось там - кнопочка ВХІД;)":
			"Лучший способ узнать человека – это побывать у него в гостях. Во-он там - кнопочка ВХОД;)",
		"Гостей у будинку багато не буває. О-ось там – кнопочка ВХІД;)":
			"Гостей в доме много не бывает. Во-он там – кнопочка ВХОД;)",
		"Вітаємо тебе! О, Таємничий Незнайомцю! Бо інші вже зареєструвалися)":
			"Приветствуем тебя! О, Таинственный Незнакомец! Остальные уже зарегистрировались)",
		"Не чекайте. Час ніколи не буде доречним.":
			"Не ждите. Время никогда не будет подходящим.",
		"Впевнено йди за своїми мріями. Живи життям, яким тобі хочеться жити.":
			"Уверенно следуй за своими мечтами. Живи жизнью, которой тебе хочется жить",
		"Саме зараз – це момент у житті, який є ключем до нових можливостей та перспектив.":
			"Сейчас – это момент в жизни, который является ключом к новым возможностям и перспективам.",
		"Пообіцяй, що не змінюватимеш з іншими маркетплейсами...":
			"Пообещай, что не будешь изменять с другими маркетплейсами...",
		//"":"",
		/*========== Author ======================*/
		"Діана Сайрес": "Диана Сайрес",
		"Антуан де Сент-Екзюпері": "Антуан де Сент-Экзюпери",
		"Зіг Зіглар": "Зиг Зиглар",
		"Тоні Роббінс": "Тони Роббинс",
		"Маркус Туллій Цицерон": "Маркус Туллий Цицерон",
		"Лао Цзи": "Лао Цзы",
		"Наполеон Хілл": "Наполеон Хилл",
		"Гарі Райнер": "Гари Райнер",
		"Джером Фішер": "Джером Фишер",
		"Пітер Друкер": "Питер Друкер",
		Конфуцій: "Конфуций",
		"Стівен Кови": "Стивен Кови",
		Піфагор: "Пифагор",
		Арістотель: "Аристотель",
		"Жорж Бізе": "Жорж Бизе",
		Цицерон: "Цицерон",
		Платон: "Платон",
		"О. Хартман": "О. Хартман",
		"Є. Черняк": "Е. Черняк",
		"Г. Бехар": "Г. Бехар",
		"Мартін Лютер Кінг мол.": "Мартин Лютер Кинг мл.",
		Еклезіаст: "Экклезиаст",
		"Махатма Ганді": "Махатма Ганди",
		"Томас Едісон": "Томас Эдисон",
		"Стів Джобс": "Стив Джобс",
		"Лао-Цзи": "Лао-Цзы",
		"Е. Хемінгуей": "Э. Хемингуэй",
		"Генрі Девід Торо": "Генри Девид Торо",
		"Японське прислів'я": "Японская пословица",
		"Джозеф Мерфи": "Джозеф Мерфи",
		"Закон Кідліна": "Закон Кидлина",
		"Марк Твен": "Марк Твен",
		"Рік Рубін": "Рик Рубин",
		"Е. Карнегі": "Э. Карнеги",
		"Омар Хаям": "Омар Хаям",
		"Ч. Буковський": "Ч. Буковски",
		"А.П. Чехов": "А.П. Чехов",
		"Леся Українка": "Леся Украинка",
		"Тарас Шевченко": "Тарас Шевченко",
		"Ліна Костенко": "Лина Костенко",
		"А.Ейнштейн": "А.Эйнштейн",
		"Тарас Бульба": "Тарас Бульба",
		"Дж. Безос": "Дж. Безос",
		"Джон Леннон": "Джон Леннон",
		"Джон Максвелл": "Джон Максвел",
		Будда: "Будда",
		"Введіть суму": "Введите сумму",
		"Китайське прислів'я": "Китайская пословица",
		"Христофор Колумб": "Христофор Колумб",
		"Френк Сінатра": "Френк Синатра",
		"Генрі Форд": "Генри Форд",
		"Хелен Келлер": "Хелен Келлер",
		"Далай Лама": "Далай Лама",
		"Латинське прислів'я": "Латинская пословица",
		"Альберт Ейнштейн": "Альберт Эйнштейн",
		"Джошуа Марін": "Джошуа Марин",
		"Френк Заппа": "Фрэнк Заппа",
		"Лао–Цзи": "Лао–Цзы",
		/*========== Complaint list ======================*/
		"Оголошення, що вводить в оману": "Объявление вводящее в заблуждение",
		"Невірно вказано категорію": "Неверно указана категория",
		"Неправильні контактні дані": "Неверные контактные данные",
		"Фотографія та опис не збігаються": "Фотография и описание не совпадают",
		"Невірні характеристики оголошення": "Неверные характеристики объявления",
		"Неправильно вказано розташування": "Неверно указано местоположение",
		"Товар уже продано": "Товар уже продан",
		Інше: "Другое",
		"Порушення правил моралі та моральності":
			"Нарушение правил морали и нравственности",
		Дискримінація: "Дискриминация",
		"Жорстокість та насильство": "Жестокость и насилие",
		"Зміст сексуального характеру": "Содержание сексуального характера",
		"Образи та погрози": "Оскорбления и угрозы",
		Шахрайство: "Мошенничество",
		"Мене обдурили або намагалися обдурити":
			"Меня обманули или пытались обмануть",
		"Фішинг - мені надіслали підозріле заслання":
			"Фишинг - мне прислали подозрительную ссылку",
		"Я не отримав товар що замовив": "Я не получил товар который заказывал",
		"Продавець запросив мої особисті дані":
			"Продавец запросил мои личные данные",
		"Товар викликає підозру": "Товар вызывает подозрение",
		"Заборонений товар чи послуга": "Запрещенный товар или услуга",
		"Контрафактний товар": "Контрафактный товар",
		"Нелегальний продаж тварин": "Нелегальная продажа животных",
		"Занадто низька ціна": "Слишком низкая цена",
		"Поскаржитись на продавця": "Пожаловаться на продавца",
		"Спам ": "Спам ",
		"Образи чи погрози": "Оскорбления или угрозы",
		"Фішинг - мені надіслали підозріле посилання":
			"Фишинг - мне выслали подозрительную ссылку",
		"Інше (Хочу детально пояснити проблему)":
			"Другое (Хочу детально объяснить проблему)",
		"Bago поради": "Bago советы",
		/*========== Complaint ======================*/
		"Не турбуйтеся, цей користувач не дізнається, що Ви поскаржилися на нього.":
			"Не беспокойтесь, этот пользователь не узнает, что Вы пожаловались на него.",
		"Ми розглянемо Ваше звернення позачергово!":
			"Мы рассмотрим Ваше обращение внеочереди!",
		"Безпека та актуальність даних дуже важлива для нас. Ми перевіримо інформацію на відповідність до Угоди користувача та вживемо необхідних заходів.":
			"Безопасность и актуальность данных  очень важна для нас.  Мы проверим информацию на соответствие с Пользовательским соглашением  и примем необходимые меры.",
		Закрити: "Закрыть",
		"Опишіть проблему детальніше...": "Опишите проблему более детально ...",
		/*Footer*/
		"Правила безпеки": "Правила безопасности",
		"Політика конфіденційності": "Политика конфиденциальности",
		"Про нас": "О нас",
		Головна: "Главная",
		Обране: "Избранное",
		Створити: "Создать",
		Повідомлення: "Сообщения",
		Профіль: "Профиль",
		/*===========Balance Tint =======================*/
		"Ваш Баланс ": "Ваш Баланс",
		"Особистий рахунок": "Личный счёт",
		"Бонусний рахунок": "Бонусный счёт",
		"для оплати сторонніх сервісів або оплати за товари або послуги, представлені в оголошеннях на сайті Продавцями або Магазинами":
			"для оплаты сторонних сервисов или оплаты за товары или услуги  представленные в объявлениях на сайте Продавцами или Магазинами",
		"Кошти на Балансі не можна використовувати":
			"Средства на Балансе нельзя использовать",
		"тільки для оплати Платних послуг із просування оголошення":
			"только для оплаты Платных услуг по продвижению объявления",
		"Кошти на Балансі можна використовувати":
			"Средства на Балансе можно использовать",
		"це рахунок, що користувач може поповнити через платіжну систему":
			"это счёт который пользователь может пополнить через платежную систему",
		"є єдиним платіжним рішенням від ": "является единым платёжным решением от",
		Приватбанку: "ПриватБанка",
		"Бонуси (скорочене позначення - бнс) нараховуються за деякі дії користувачів на сайті Bago.ua та є платіжним еквівалентом в інформаційно-розрахунковій системі в рамках Сайту Bago.ua":
			"Бонусы (сокращённое обозначение - бнс) начисляются за некоторые действия пользователей  на  сайте Bago.ua и являются платёжным эквивалентом в информационно-расчетной системе в рамках Сайта Bago.ua",
		"це сукупна сума коштів, що складається із коштів, що знаходяться на Особовому рахунку та Бонусному рахунку користувача.":
			"это совокупная сумма средств, состоящая из средств находящихся на Личном счёте и Бонусном счёте пользователя.",
		"Інші оголошення автора": "Другие объявления автора",
		Активна: "Активная",
		Відхилено: "Отклонено",
		"Не активна": "Не активна",
		Неактивна: "Неактивная",
		Активно: "Активная",
		"Бізнес профіль": "Бизнес профиль",
		Активний: "Активный",
		Неактивний: "Неактивный",
		"Редагувати Бізнес профіль": "Редактировать бизнес профиль",
		"Перетягніть сюди фото банера або натисніть на нього, щоб відкрити вікно завантаження":
			"Перетащите сюда фото баннера или нажмите на него, чтобы открыть окно загрузки",
		"Рекомендований розмір банера: 1506 х 250 px":
			"Рекомендуемый размер баннера: 1506 х 250 px",
		"Головний банер": "Главный баннер",
		"Всі оголошення": "Все объявления",
		"Про продавця": "О продавце",
		Контакти: "Контакты",
		"Ваша сторінка знаходиться на модерації":
			"Ваша страница находится на модерации",
		"Ваша сторінка відправилась на модерацію.":
			"Ваша страница отправлена на модерацию.",
		"Допускаються лише латинські символи та дефіс.":
			"Допускаются только латинские символы и дефис.",
		"Слаг має бути від 3 до 255 символів.":
			"Слог должен быть от 3 до 255 символов.",
		"Ваш шлях до сторінки встановлено.": "Ваш путь к странице установлен.",
		"Введіть ваш шлях до сторінки": "Введите ваш путь к странице",
		"Ваша сторінка активована": "Ваша страница активирована",
		"Ваша сторінка відхилена": "Ваша страница отклонена",
		"Оплата та гарантія": "Оплата и гарантия",
		"Видимий блок для інших": "Видимый блок для других",
		"Невидимий блок для інших": "Невидимый блок для других",
		"Ваші зміни збережені.": "Ваши изменения сохранены.",
		"Додати телефон": "Добавить телефон",
		"Введіть ваш контактний email": "Введите ваш контактный email",
		"Виберіть зручні вам способи доставки. Ці налаштування будуть застосовані до всіх ваших оголошень.":
			"Выберите удобные для вас способы доставки. Эти настройки будут применены ко всем Вашим объявлениям",
		"Відправити на модерацію": "Отправить на модерацию",
		"Оберіть назву вашої сторінки": "Выберите название вашей странички",
		Місто: "Город",
		"Дивитись всі": "Смотреть все",
		"Інші оголошення продавця": "Другие объявления продавца",
		"Будь ласка, завантажуйте лише зображення.":
			"Пожалуйста, загружайте только изображения.",
		"Розмір файлу перевищує 5MB.": "Размер файла превышает 5MB.",
		"Не вдалося зберегти слоган.": "Не удалось сохранить слоган.",
		"Ваші зміни збережені. Ваша сторінка відправлена на модерацію.":
			"Ваши изменения сохранены. Ваша страница отправлена на модерацию.",
		"Не показувати це оголошення з іншими оголошеннями автора":
			"Не показывать это объявление с другими объявлениями автора",
			"Не показувати на бізнес-сторінці": "Не показывать на бизнес-странице",
		"На карту Приватбанка": "На карту ПриватБанка",
		"Накладений платіж": "Наложенный платеж",
		"Безпечна угода BAGO": "Безопасная сделка BAGO",
		"По реквізитам (рахунок фактура)": "По реквизитам (счёт-фактура)",
		"Умови гарантії": "Условия гарантии",
		"Умови повернення": "Условия возврата",
		"На протязі 14 днів": "В течение 14 дней",
		"Зворотня доставка за рахунок покупця":
			"Обратная доставка за счет покупателя",
		"Зберігайте оригінальну упаковку, якщо така упаковка передбачена":
			"Сохраняйте оригинальную упаковку, если такая упаковка предусмотрена",
		"Опишіть свої умови повернення": "Опишите свои условия возврата",
		"Опишіть свої умови гарантії": "Опишите свои условия гарантии",
		"Гарантійний строк вказано в картці товару":
			"Гарантийный срок указан в карточке товара",
		"2 роки": "2 года",
		"1 міс.": "1 мес.",
		"14 днів": "14 дней",
		Телефони: "Телефоны",
		"Додати новий номер телефону": "Добавить новый номер телефона",
		"Номер телефону": "Номер телефона",
		"Надіслати SMS": "Отправить SMS",
		"Введіть код з SMS, надісланий на номер":
			"Введите код из SMS, отправленный на номер",
		"Розкажіть про вашу компанію": "Расскажите о вашей компании",
		"Напишіть текст не менше 200 символів.":
			"Напишите текст не менее 200 символов.",
		// Work type description
		"Спробуйте детально описати вакансію:\n  - необхідні навички;\n  - суть роботи;\n  - графік роботи;\n  - відпустка;\n  - умови працевлаштування.":
			"Постарайтесь детально описать вакансию:\n  - требуемые навыки;\n  - суть работы;\n  - график работы;\n  - отпуск;\n  - условия трудоустройства.",
		"Опишіть яку роботу ви шукаєте:\n  - освіта;\n  - ваш стаж роботи;\n  - останні 3 місця роботи;\n  - наявність Автомобіля і категорій прав;\n  - сімейний стан;\n  - бажану Зарплату.":
			"Опишите какую работу вы ищете:\n  - образование;\n  - ваш стаж работы;\n  - последние 3 места работы;\n  - наличие Автомобиля и категорий прав;\n  - семейное положение;\n  - желаемую Зарплату.",
		"Робота": "Работа",
		"роботу": "работу",
		"Логотип компанії": "Логотип компании",
		"Сума грн.": "Сумма грн.",
		"від грн.": "от грн.",
		"до грн.": "до грн.",
		"Заробітна плата": "Заработная плата",
		"Рекомендований розмір логотипу – не більше 300x150 пікселів, максимальний розмір файлу – 1 МБ":
			"Рекомендуемый размер логотипа – не более 300x150 пикселей, максимальный размер файла – 1 МБ",
		"Одне значення": "Одно значение",
		Діапазон: "Диапазон",
		"Пропоную роботу": "Предлагаю работу",
		"Шукаю роботу": "Ищу работу",
		Відгукнутись: "Откликнуться",
		"Інші вакансії в цій рубриці": "Другие вакансии в этой рубрике",
		"Сайт компанії:": "Сайт компании:",
		"Адреса": "Адрес",
		"1 місяць": "1 месяц",
		"наприклад men-clothes-store": "например men-clothes-store",
		"Застосувати": "Применить",
		"Приховати": "Скрыть",
		"У цього продавця немає жодних оголошень": "У этого продавца нет никаких объявлений",
		"Повний URL вашої сторінки": "Полный URL вашей страницы",
		"Опис вакансії": "Описание вакансии",
		"Назву змінити не можна": "Название изменить нельзя",
		// Comments
		"Напишіть свій коментар": "Напишите свой комментарий",
		"Необхідно заповнити": "Необходимо заполнить",
		"Додати коментар": "Добавить комментарий",
		"Автор": "Автор",
		"Опублікувати": "Опубликовать",
		"коментарі": "комментарии",
		"Не менше ніж": "Не менее",
		"Не більше ніж": "Не более",
		"символів": "символов",
		"Коментарі до оголошення": "Коментарии к объявлению",
		"Ваш коментар відправлено на перевірку. Він буде опублікований після модерації.": "Ваш комментарий отправлен на проверку. Он будет опубликован после модерации.",
		"pозгорнути": "развернуть",
	},
};
