import React, { useState } from 'react';
import s from '../CommentSection.module.css';
import PublishedTime from '../../../../Components/TimeAndDate/PublishedTime';
import { useTranslation } from 'react-i18next';

interface CommentProps {
  author: string;
  text: string;
  date: number;
}

const Comment: React.FC<CommentProps> = ({ author, text, date }) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const maxTextLength = 200;

  const renderText = (text: string) => {
    if (isExpanded || text.length <= maxTextLength) {
      return text;
    }
    return (
      <>
        {text.slice(0, maxTextLength)}...
        <span 
          className={s.showMore} 
          onClick={() => setIsExpanded(true)}
        >
          {t("pозгорнути")}
        </span>
      </>
    );
  };

  return (
    <>
      <div className={s.commentTop}>
        <span className={s.author}>{author}</span>
        <PublishedTime time={date || 0} />
      </div>
      <p className={s.text}>{renderText(text)}</p>
    </>
  );
};

export default Comment;