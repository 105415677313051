import { Field } from "formik";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { numberAddSpace } from "../../../common/Logic/supporting";
import s from "../CreateBoardAD.module.css";

interface Props {
	status: any;
	setStatus: any;
	setFieldValue: any;
	values: any;
}
const PriceField = forwardRef<HTMLDivElement, Props>(
	({ status, setStatus, setFieldValue, values }, ref) => {
		const { t } = useTranslation();
		return (
			<div className={s.nameSide} ref={ref}>
				<span className={s.elementName}>
					{t(`Ціна`)}
					<span className={s.required}> * </span>
				</span>
				<div className={`${s.fieldSide} ${status?.price ? s.error : ""}`}>
					<Field
						onFocus={() => setStatus({})}
						disabled={values.price_type === "2"}
						value={values.price}
						name={"price"}
						onChange={(e: any) => {
							const formattedEvent = numberAddSpace(e.target.value);
							setFieldValue("price", formattedEvent);
							if (formattedEvent === "") {
								setFieldValue("price_type", "0");
							}
						}}
					/>
					<span
						style={{
							marginLeft: "20px",
							color: "#727272",
							fontSize: "18px",
						}}
					>
						грн
					</span>
					{status && status.price && (
						<span className={`${s.error} ${s.priceError}`}>{status.price}</span>
					)}

					<div className={`${s.stateButtons} ${s.priceButtons}`}>
						<button
							type={"button"}
							onClick={() => {
								setStatus({});
								if (values?.price >= "0" && values.price_type !== "1") {
									setFieldValue("price_type", "1");
								} else {
									setFieldValue("price_type", "0");
								}
							}}
							className={+values.price_type === 1 ? s.active : ""}
						>
							{t(`Договірна`)}
						</button>
						<button
							type={"button"}
							onClick={() => {
								setStatus({});
								if (values.price_type !== "2") {
									setFieldValue("price_type", "2");
									setFieldValue("price", "");
								} else {
									setFieldValue("price_type", "0");
								}
							}}
							className={+values.price_type === 2 ? s.active : ""}
						>
							{t(`Безкоштовно`)}
						</button>
					</div>
				</div>
			</div>
		);
	}
);

export default PriceField;
