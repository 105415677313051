import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import FavoriteSearchCardPopup from "../../../../../Components/Cards/FavoriteSearchCard/FavoriteSearchCardPopup";
import SearchCardSvgSelector from "../../../../../Components/Cards/FavoriteSearchCard/SearchCardSvgSelector";
import { formattingPrice } from "../../../../../common/Logic/supporting";
import {
	publicOneNotice,
	TreeLinksType,
} from "../../../../../types/publicNoticeTypes";
import s from "./NoticeCard.module.css";
import FavoriteHeartSelector from '../../../../../Components/Cards/FavoriteHeartSelector/FavoriteHeartSelector'

interface NoticeCardRowProps {
	notice: publicOneNotice;
	sort?: string;
	page?: number;
	perPage?: number;
}

export const NoticeCardRow = ({ 
	notice,
	sort = "2",
	page = 1,
	perPage = 48
}: NoticeCardRowProps) => {
	const noticeTree = notice?.tree?.map((s: TreeLinksType) => s.alias);
	const noticeUrl = noticeTree?.join("/");
	const navigate = useNavigate();
	const { i18n, t } = useTranslation();
	const currentLanguage = i18n.language === "ru" ? "" : "/ua";

	return (
		<div className={s.cardRowContainer}>
			<div
				className={s.image}
				onClick={() =>
					//navigate(`/o/${noticeUrl ? noticeUrl +"/" : ""}${notice?.slug}`)
					navigate(
						`${currentLanguage}/o/${noticeUrl ? noticeUrl + "/" : ""}${notice?.slug}`
					)
				}
			>
				{notice?.noticeImages.length && (
					<img
						className={`${s.image}`}
						loading={"lazy"}
						src={notice?.noticeImages[0]?.originalImageUrl}
						alt={notice?.slug || "image"}
					/>
				)}
			</div>
			<div className={s.info}>
				<div
					className={s.infoTitle}
					onClick={() =>
						//navigate(`/o/${noticeUrl ? noticeUrl +"/" : ""}${notice?.slug}`)
						navigate(
							`${currentLanguage}/o/${noticeUrl ? noticeUrl + "/" : ""}${notice?.slug}`
						)
					}
				>
					<h4>{notice?.name || ""}</h4>
				</div>
				<div className={s.infoDescription}>
					<p>{notice?.description || ""}</p>
				</div>
				<div className={s.infoLocation}>
					<span>
						<SearchCardSvgSelector id={"location"} />
					</span>
					<span>{notice?.short_settlement_name || ""}</span>
				</div>
			</div>
			<div className={s.panel}>
				<div className={s.panelTop}>
					<span>
						<FavoriteHeartSelector
							id={notice?.id || 0}
							isFavorite={notice?.isFavorite || 0}
							userId={notice?.users_id || 0}
							sort={sort}
							page={page}
							perPage={perPage}
						/>
					</span>
					<span>
						{notice?.price && notice.price !== "0.00"
							? formattingPrice(notice?.price)
							: t("Безкоштовно")}
						<span>
							{notice?.price && notice.price !== "0.00" ? " грн" : ""}
						</span>
					</span>
				</div>

				<div className={s.panelBottom}>
					<span className={s.hiddenInfo}>
						<span className={s.menuButton}>
							<FavoriteSearchCardPopup />
						</span>
					</span>
				</div>
			</div>
		</div>
	);
};
