import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StateType } from "../../../redux/redux-store";
import { formattingPrice, testActualityService } from "../../../common/Logic/supporting";
import {
	publicOneNotice,
	TreeLinksType,
} from "../../../types/publicNoticeTypes";
import PublishedTime from "../../TimeAndDate/PublishedTime";
import FavoriteHeartSelector from "../FavoriteHeartSelector/FavoriteHeartSelector";
import SearchCardSvgSelector from "../FavoriteSearchCard/SearchCardSvgSelector";
import s from "./MobileProductCard.module.css";
import MobileWorkProductCard from "./MobileWorkProductCard";

const MobileProductCard = ({
	notice,
	isNew = false,
	fullWidth = false,
}: {
	notice: publicOneNotice;
	isNew?: boolean;
	fullWidth?: boolean;
}) => {
	const userView = useSelector((state: StateType) => state.userData.user);
	const noticeTree = notice?.tree?.map((s: TreeLinksType) => s.alias);
	const noticeUrl = noticeTree?.join("/");
	const { i18n, t } = useTranslation();
	const currentLanguage = i18n.language === "ru" ? "" : "/ua";
	const navigate = useNavigate();
	const isWorkNotice = notice?.noticeType?.value === 1;

	const handleNavigate = () => {
		navigate(
			`${currentLanguage}/o/${noticeUrl ? noticeUrl + "/" : ""}${notice?.slug}`
		);
	};

	const image = notice?.noticeImages.length ? (
		<img
			src={notice?.noticeImages[0]?.originalImageUrl}
			alt={notice?.slug || "image"}
		/>
	) : null;

	const top = (notice?.top_at && testActualityService(notice?.top_at ? notice.top_at : null)) ? (
		<div className={s.topMobileFlag}>
			<SearchCardSvgSelector id={"topFlagLittle"} />
		</div>
	) : null;
	const topHeart = notice?.users_id && +notice?.users_id !== userView.id ? (
			<FavoriteHeartSelector
				isFavorite={notice?.isFavorite || 0}
				id={notice?.id || 0}
				userId={notice?.users_id || 0}
			/>
	) : null;

	return (
		<div
			key={notice?.id}
			className={`${s.cardContainer} ${fullWidth ? s.fullWidth : ""}`}
			onClick={handleNavigate}
		>
			{!isWorkNotice ? (<>
				<div className={s.cardImage}>
					{image}
					{top}
					<div className={s.topHeart} onClick={(e) => e.stopPropagation()}>
						{topHeart}
					</div>
				</div>

				<div className={s.infoContainer}>
					<div className={s.cardName}>
						<h4>{notice?.name || ""} </h4>
					</div>
					<div className={s.cardPrice}>
						{notice?.price && notice.price !== "0.00"
							? `${formattingPrice(notice?.price)} грн`
							: t("Безкоштовно")}
					</div>
					<div className={s.cardLocation}>
						<span>{notice?.short_settlement_name || ""}</span>
					</div>
					<div className={s.timeAndState}>
						<p>
							{
								<PublishedTime
									time={
										isNew ? notice?.created_at || 0 : notice?.published_at || 0
									}
								/>
							}
						</p>
						{/* <p>{notice?.stateName}</p> */}
					</div>
				</div>
			</>)	: (
				<MobileWorkProductCard notice={notice} topHeart={topHeart} top={top} image={image} />
			)}
		</div>
	);
};

export default MobileProductCard;
