import React, { useEffect, useState } from "react";
import s from "./Logo.module.css";
import NavBarSvgSelector from "../NavBar/NavBarSvgSelector";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";


const Logo: React.FC = () => {
	const [currentPath, setCurrentPath] = useState("");
	const location = useLocation();
	const { i18n } = useTranslation();

	useEffect(() => {
		setCurrentPath(location.pathname);
	}, [location.pathname]);

	const handleReload = () => {
		window.location.reload();
	};
	return (
		<div className={s.logoContainer}>
			{currentPath === "/" ? (
				<div onClick={handleReload} style={{ cursor: "pointer" }}>
					<NavBarSvgSelector id={"logoTop"} />
				</div>
			) : (
				<Link to={i18n.language === "ru" ? "/" : "/ua"}>
					<NavBarSvgSelector id={"logoTop"} />
				</Link>
			)}
		</div>
	);
};

export default Logo;
