import "./sentryInstrument"
import { DevSupport } from "@react-buddy/ide-toolbox";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { initReactI18next } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import Notification from "./Components/Notification/Notification";
import { ComponentPreviews, useInitial } from "./dev";
import "./index.css";
import store from "./redux/redux-store";
import reportWebVitals from "./reportWebVitals";
import { ru } from "./translate";
import { createRoot } from "react-dom/client";

/*6LfTBfAUAAAAAPqIrFmQ24dE4oKhU7F8efdxSpeZ */
/*.use(LanguageDetector)*/

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		debug: false,
		fallbackLng: "uk",
		resources: {
			ru: ru,
		},
		react: {
			useSuspense: true,
		},

	}).then(() => {
	// Устанавливаем язык по умолчанию
	i18n.changeLanguage("ru");
});

const queryClient = new QueryClient({});

const root = createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<React.StrictMode>
		<HelmetProvider>
				<Provider store={store}>
					<BrowserRouter>
						<QueryClientProvider client={queryClient}>
							<GoogleOAuthProvider clientId="61015268502-7412vjh0im1v90db9uek7kvvq8m1tpi7.apps.googleusercontent.com">
								<DevSupport
									ComponentPreviews={ComponentPreviews}
									useInitialHook={useInitial}
								>
									<App />
								</DevSupport>
							</GoogleOAuthProvider>
							{/* <ReactQueryDevtools
								buttonPosition="bottom-left"
								initialIsOpen={false}
							/> */}
						</QueryClientProvider>
					</BrowserRouter>
					<Notification />
				</Provider>
		</HelmetProvider>
	</React.StrictMode>
);

reportWebVitals();
