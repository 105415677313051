import s from "./MainSearch.module.css";
import React, { useEffect, useState } from "react";
import MainSearchSvgSelector from "./MainSearchSvgSelector";
import { SearchHistoryEl, SearchNotice } from "../../types/searchTypes";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
	getClearHistorySearches,
	getHistoryUserSearches,
	setSearchingNoticeName,
} from "../../redux/search_Reducer";
import { StateType } from "../../redux/redux-store";
import { Desktop, Mobile } from "../../responsive";
import MobileSearchButton from "./MobileSearchButton/MobileSearchButton";
import ClearSearchHistoryImg from "../FunnyLogoBlock/ClearHistory/ClearSearchHistoryImg";

type Props = {
	notices: SearchNotice[];
	type: string;

	setSearchInputValue: (b: string) => void;
	searchInputValue: string;
	setOpen?: (open: boolean) => void;
};

export function MainSearchPopup({
	notices,
	type,
	setSearchInputValue,
	searchInputValue,
}: Props) {
	const { i18n, t } = useTranslation();
	const dispatch: any = useDispatch();
	const navigate = useNavigate();
	const getHistory = useSelector(() => getHistoryUserSearches);
	const clearSearchList = useSelector(() => getClearHistorySearches);
	const searchHistory = useSelector(
		(state: StateType) => state.searchData.historyUserSearches
	);
	const auth = useSelector((state: StateType) => state.userData.isAuth);
	const sessionHistory: any = sessionStorage.getItem("bagoSearchesHistory");
	const [sessionSearchHistory, setSessionSearchHistory] = useState(
		JSON.parse(sessionHistory) || []
	);
	const setSearchName = useSelector(() => setSearchingNoticeName);
	const searchName = useSelector(
		(state: StateType) => state.searchData.searchingNoticeName
	);
	const [openClearHistoryImg, setOpenClearHistoryImg] = useState(false);

	const handleClickResult = (name: string, tree: string[]) => {
		const noticeUrl = tree
			.map((s, index) => {
				if (index === 0) {
					if(tree.length === 2) {
						return `${s}/c2/${s}`; // Добавляем '/c2' ко второму элементу
					}else {
						return `${s}/c2`; // Добавляем '/c2' ко второму элементу
					}
				} else {
					return s; // Возвращаем остальные элементы без изменений
				}
			})
			.join("/");
		setSearchInputValue(searchName);
		//const noticeUrl = tree.map((s:string)=>s).join('/')
		navigate(
			`${i18n.language === "uk" ? "/ua" : ""}/c/${noticeUrl ? noticeUrl : ""}?search=${searchName}`
			//`${i18n.language === "uk" ? "/ua" : ""}/c/${noticeUrl ? noticeUrl : ""}/search_result?search=${searchName}`
		);
	};

	const slicedNotice = Boolean(notices) ? notices?.slice(0, 3) : [];

	const handleClickHistory = (query: string) => {
		dispatch(setSearchName(query));
		setSearchInputValue(query);
		//handleSubmitForm("", query);
	};
	const clearButtonClick = () => {
		sessionStorage.removeItem("bagoSearchesHistory");
		dispatch(clearSearchList());
		setSessionSearchHistory([]);
		setOpenClearHistoryImg(true);
		setSearchInputValue("");
	};
	useEffect(() => {
		if (auth) {
			dispatch(getHistory());
		}
	}, [auth]);
	return (
		<>
			{type === "history" && (
				<Mobile>
					<div className={s.cleanHistoryMobileContainer}>
						<button
							type={"button"}
							onClick={clearButtonClick}
							className={s.cleanHistoryMobile}
						>
							{t("Очистити історію")}
						</button>
					</div>
				</Mobile>
			)}
			<div className={s.wrapper}>
				{type === "history" && (
					<div className={s.mainSearchPopup}>
						<div className={s.mainSearchContent}>
							<ul>
								{(auth ? searchHistory : sessionSearchHistory).length > 0 ? (
									(auth ? searchHistory : sessionSearchHistory).map(
										(n: SearchHistoryEl) => (
											<li
												className={s.searchHistoryEl}
												key={n.query + n.created_at + Math.random()}
												onClick={() => handleClickHistory(n.query)}
											>
												<MainSearchSvgSelector id={"popupLupa"} />
												<span className={s.name}>{n.query}</span>
											</li>
										)
									)
								) : (
									<div>{openClearHistoryImg && <ClearSearchHistoryImg />}</div>
								)}
							</ul>
							<Desktop>
								<button
									type={"button"}
									onClick={clearButtonClick}
									className={s.cleanHistory}
								>
									{t("Очистити історію")}
								</button>
							</Desktop>
						</div>
						<div className={s.searchButtonContainer}>
							{searchInputValue.length >= 3 && (
								<MobileSearchButton searchInputValue={searchInputValue} />
							)}
						</div>
					</div>
				)}
				{type === "search" && ( //&& notices?.length > 0
					<div className={s.mainSearchPopup}>
						<div className={s.search}>
							<div className={s.searchItemInCategory}>
								<ul className={s.listSearchResult}>
									{slicedNotice?.map((n: SearchNotice) => (
										<li
											onClick={() => handleClickResult(n.name, n.categoryTree)}
											key={n.id}
										>
											<span className={s.name}>{searchInputValue}</span>
											<span className={s.categoryName}>
												{t(" в ") + n.categoryName}
											</span>
										</li>
									))}
									<MobileSearchButton searchInputValue={searchInputValue} />
								</ul>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
}
