import React from "react"
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import mockListing from "../../../images/mock-listing.svg";
import style from "../../../pages/SearchResults/SearchResult.module.css";
import { setClearFiltersList } from "../../../redux/category_Reducer";
import {  setImagesOriginal } from "../../../redux/notice_Reducer";
import { StateType } from "../../../redux/redux-store";
import { setOpenRegAuthPopup, setPhoneEnterPopup } from "../../../redux/user_Reducer";
import { OneCategoryType, stateUserType } from "../../../types/reduxStoreTypes";
import s from "../FavoriteSearchCard/ProductCardGrid.module.css";
import i18n from "i18next";

type Props = {
	category:OneCategoryType
	grid?: string;
};


export const AddListingCard = (props: Props) => {
	const { grid ,category } = props;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const auth = useSelector((state: stateUserType) => state.userData.isAuth);
	const userView = useSelector((state: StateType) => state.userData.user);
	const segmentsArray = location.pathname?.split("/").filter(Boolean);

	const languagePath = i18n.language === "ru" ? "" : "ua/"

	const openEnterDialog = () => {
		dispatch(setOpenRegAuthPopup(true));
	};

	const clearInputs = () => {
		dispatch(setImagesOriginal([]));
		dispatch(setClearFiltersList());
	};

	const onClickOnCard = () => {
		clearInputs();
		if (auth && userView.is_approve_phone !== 1) {
			dispatch(setPhoneEnterPopup(true));
		} else if (auth && userView.is_approve_phone === 1) {
			if (
				segmentsArray?.length > 0 &&
				(segmentsArray[0] === "create_ad" || segmentsArray[1] === "create_ad")
			) {
				alert(
					t(
						"Оголошення, яке ви розпочали подавати," +
							" ще не було опубліковано. Бажаєте повернутися до цього оголошення?"
					)
				);
			} else {
				navigate(`/${languagePath}create_ad/${category.alias}`);
			}
		}
	};

	return (
		<>
			<div
				onClick={() => {
					!auth ? openEnterDialog() : onClickOnCard();
				}}
				className={`${s.searchCardContainer} ${style.border}`}
				style={{
					width: grid === "3" ? "307px" : grid === "5" ? "265px" : "",
					height: grid === "3" ? "425px" : "",
					padding: grid === "3" ? "10px" : "",
				}}
			>
				<div
					className={s.cardImage}
					style={{
						height: grid === "3" ? "280px" : "206px",
					}}
				>
					<img src={mockListing} alt={"listing"} />
				</div>
				<div className={s.addAD}>
					<h2>
						{t("Додати оголошення")}

						{/*{segmentsArray.includes("search_result") ||
						segmentsArray.includes("search")
							? t("Додати оголошення")
							: t("Додати в цю рубрику оголошення")}*/}
					</h2>
					<button onClick={onClickOnCard}>
						<h3>+ {t(`оголошення`)}</h3>
					</button>
				</div>
			</div>
		</>
	);
};
