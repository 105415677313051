import React from "react";
import s from "../../App.module.css";
import { Desktop, Mobile } from "../../responsive";
import HeaderProfile from "../NavBar/HeaderProfileMenu/HeaderProfile";
import { useSelector } from "react-redux";
import { stateUserType } from "../../types/reduxStoreTypes";
import { Link } from "react-router-dom";
import i18n from "i18next";


const HeaderAuthBlock = ({
	openEnterDialog,
}: {
	openEnterDialog: (b:boolean) => void;
}) => {
	//const [showLogo, setShowLogo] = useState(true);
	const auth = useSelector((state: stateUserType) => state?.userData?.isAuth);

	const getPath = () => {
		return i18n.language === "ru" ? "" : "ua/"
	}

	/*useEffect(() => {
		/!*const timer = setTimeout(() => {
            setShowLogo(!showLogo);
        }, 3000);*!/
		/!*return () => {
             clearTimeout(timer);
         };*!/
	}, [showLogo]);*/

	return (
		<div className={s.auth}>
			{/*<Mobile>
                {!showLogo ?
                        <LogoMobile />
                    :
                        <HeaderProfile />
                }
            </Mobile>*/}
			<Mobile>
				<HeaderProfile  />
			</Mobile>
			<Desktop>
				{!auth ? (
					<div className={s.authBlock} onClick={()=>openEnterDialog(true)}>
						<HeaderProfile  />
					</div>
				) : (
					<Link className={s.authBlock} to={`/${getPath()}personal_cabinet/ads`}>
						<HeaderProfile />
					</Link>
				)}
			</Desktop>
		</div>
	);
};

export default HeaderAuthBlock;
