import { AxiosResponse } from "axios";
import { ReactNode, useRef } from "react";
import { useTranslation } from "react-i18next";
import { UseMutateFunction } from "react-query";
import { useDispatch } from "react-redux";
import { setNotification } from "../../../../../redux/notificationReducer";
import styles from "../SellerInfo/SellerInfo.module.css";
import { isEqual } from "../../../../../common/Logic/supporting";


type Props = {
	title: string;
	onChange?: (checked: boolean) => void;
	content: ReactNode;
	bgColor?: string;
	isEdit: boolean;
	values: {
		var1: number;
		var2: number;
		var3: number;
		var4?: string | number;
		var5?: string | number;
	};
	mutate: UseMutateFunction<
		AxiosResponse<any, any>,
		Error,
		{
			var1: number;
			var2: number;
			var3: number;
			var4?: string | number;
			var5?: string | number;
		},
		unknown
	>;
};

export const CheckboxesBlock = (props: Props) => {
	const { t } = useTranslation();
	const { title, content, bgColor, isEdit, values, mutate } = props;
	const dispatch = useDispatch();

	const initialValuesRef = useRef(values); 

	const isChanged = !isEqual(values, initialValuesRef.current); 

	const onSubmit = () => {
		if (!isChanged) {
			return;
		}

		mutate(values, {
			onSuccess: ({ data }) => {
				if (data?.errors && Object.keys(data.errors).length) {
					for (const key in data.errors) {
						dispatch(setNotification(data.errors[key], "error"));
						return;
					}
				} else {
					dispatch(setNotification(t("Ваші зміни збережені."), "success"));
					initialValuesRef.current = { ...values }; 
				}
			},
			onError: () => {
				dispatch(setNotification(t("Помилка збереження змін."), "error"));
			},
		});
	};

	return (
		<div
			className={styles.block}
			style={{ backgroundColor: bgColor ? bgColor : "white" }}
		>
			<div className={styles.container}>
				<div className={styles.top}>
					<p className={styles.title}>{title}</p>
					<div>{content}</div>
				</div>
			</div>
			{isEdit && (
				<button
					onClick={onSubmit}
					className={styles.button}
					type="button"
					disabled={!isChanged}
				>
					{t("Зберегти")}
				</button>
			)}
		</div>
	);
};
