import React, { useState } from "react";
import s from "./ModalPitchToTop.module.css";
import PersonalCabinetSvgSelector from "../../PersonalCabinetSvgSelector";
import Select from "react-select";
import TopModalCardComponent from "../TopModalCardComponent";
import TitleModalCardComponent from "../TitleModalCardComponent";
import BottomPayModalCardComponent from "../BottomPayModalCardComponent";
import { Desktop, Mobile } from "../../../../responsive";
import { NoticeDataForModal } from "../../../../types/noticeTypes";
import { OnePrice } from "../../../../redux/price_Reducer";
import { useDispatch, useSelector } from "react-redux";
import { buyService } from "../../../../redux/notice_Reducer";
import { formattingOptionsForBuyService } from "../../../../common/Logic/supporting";
import { useTranslation } from "react-i18next";

type Props = {
	handleClose: (b: boolean) => void;
	noticeData: NoticeDataForModal;
	data: OnePrice[] | null;
};

const ModalPitchToTop: React.FC<Props> = ({
	handleClose,
	noticeData,
	data,
}) => {
	const dispatch: any = useDispatch();
	const buy = useSelector(() => buyService);
	const { t } = useTranslation();
	const formattingData = formattingOptionsForBuyService(data);
	const [value, setValue] = useState(
		formattingData[0]
			? formattingData[0]
			: {
					label: "",
					value: "",
					period: "",
					price: "",
					periodName: "",
				}
	);
	const handlePayService = () => {
		dispatch(
			buy(
				+noticeData.id,
				+value.value,
				handleClose,
				value.price,
				noticeData.name
			)
		);
	};
	const handleChangePeriod = (newValue: any) => {
		setValue(newValue);
	};
	return (
		<div className={s.modalContainer}>
			<TitleModalCardComponent
				title={`${t("Налаштувати")} ТОП`}
				handleClose={handleClose}
			/>
			<TopModalCardComponent noticeData={noticeData} />
			<div className={s.topCardSelectLine}>
				<div className={s.topCardSelectLine_Info}>
					{t("Кількість днів")}
					<Mobile>
						<div className={s.topCardSelectLine_Flag}>
							<PersonalCabinetSvgSelector id={"topFlag"} />
						</div>
					</Mobile>
				</div>
				<div className={s.topCardSelectLine_Select}>
					<Select
						isClearable={false}
						value={value}
						onChange={handleChangePeriod}
						options={formattingData}
						styles={{
							control: (baseStyles) => ({
								...baseStyles,
								borderColor: "#E7E7E7",
								width: "141px",
								borderRadius: "4px",
								"@media (max-width: 1025px)": {
									width: "100%",
								},
							}),
							singleValue: (styles) => {
								return {
									...styles,
									fontFamily: "var(--myMainFontBold)",
									fontSize: "16px",
									lineHeight: "16px",
									color: "#939393",
								};
							},
						}}
					/>
				</div>
				<Desktop>
					<div className={s.topCardSelectLine_Flag}>
						<PersonalCabinetSvgSelector id={"topFlag"} />
					</div>
				</Desktop>
			</div>
			<div className={s.topCardSelectBuyInfo}>
				<span>
					{t("Ваше оголошення відображатиметься")}
					{t(" частіше та з періодичним повтором у")}
				</span>
				&nbsp;
				{t("результатах пошуку разом з іншими оголошеннями")}.<br />
				<br />
				{`${t("Оголошення")} ${t("зі значком")}`}
				<span> ТОП {t("привертає увагу та збільшує кількість реакцій")}.</span>
			</div>
			<BottomPayModalCardComponent
				totalBalance={noticeData.balance}
				handleClick={handlePayService}
				sum={value.price}
			/>
		</div>
	);
};

export default ModalPitchToTop;
