import { useMediaQuery } from "react-responsive";


export const Desktop = ({ children }:any) => {
	const isDesktop = useMediaQuery({ minWidth: 1025 });
	return isDesktop ? children : null;
};

export const Mobile = ({ children }: any) => {
	const isMobile = useMediaQuery({ maxWidth: 1024 });
	return isMobile ? children : null;
};
export const MobileBalanceTableMin = ({ children }: any) => {
	const isMobile = useMediaQuery({ minWidth: 799 });
	return isMobile ? children : null;
};
export const MobileBalanceTableMax = ({ children }: any) => {
	const isMobile = useMediaQuery({ maxWidth: 798 });
	return isMobile ? children : null;
};
export const MobileInErrorPage = ({ children }: any) => {
	const isMobile = useMediaQuery({ maxWidth: 634 });
	return isMobile ? children : null;
};
export const DesktopInErrorPage = ({ children }: any) => {
	const isMobile = useMediaQuery({ minWidth: 635 });
	return isMobile ? children : null;
};


