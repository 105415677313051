import React from "react";
import { useTranslation } from "react-i18next";
import { formattingPrice, numberAddSpace } from "../../../common/Logic/supporting";
import { NoticeDataForModal } from "../../../types/noticeTypes";
import s from "./ModalAutoRenewal/ModalAutoRenewal.module.css";
import { useSelector } from "react-redux";
import { StateType } from "../../../redux/redux-store";

type Props = {
	noticeData: NoticeDataForModal;
};

const TopModalCardComponent: React.FC<Props> = ({ noticeData }) => {
	const { t } = useTranslation();
	const userStatic = useSelector(
		(state: StateType) => state.userData.userStatic
	);
	return (
		<div className={s.topCardInfo}>
			<div className={s.topCardInfo_Left}>
				<div className={s.topCardInfoImage}>
					<img src={noticeData?.img} alt={noticeData?.name} />
				</div>
			</div>
			<div className={s.topCardInfo_Right}>
				<div className={s.rightInfoTop}>
					<div className={s.topCardInfoName_Title}>
						<h5>{noticeData?.name}</h5>
					</div>
					<div className={s.topCardInfoPrice_Sum}>
						{formattingPrice(noticeData?.price ?? "")} грн
					</div>
				</div>
				<div className={s.rightInfoMiddle}>
					<div className={s.topCardInfoName_Location}>
						{noticeData?.settlement}
					</div>
				</div>
				<div className={s.rightInfoBottom}>
					<div className={s.topCardInfoPrice_Balance}>
						{t("Ваш баланс")}: {userStatic?.userBonus === null
						? numberAddSpace(+userStatic.userBalance)
						: numberAddSpace(
							+userStatic.userBonus + +userStatic.userBalance
						)} грн
					</div>
				</div>
			</div>
		</div>
	);
};

export default TopModalCardComponent;
