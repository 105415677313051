import React, { useRef, useState } from "react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";
import "swiper/css/pagination";
import s from "../../pages/OutputBordAD/OutputBoardAD.module.css";
import { Desktop, Mobile } from "../../responsive";
import DeliveryBlock from "../DeliveryBlock/DeliveryBlock";
import { useTranslation } from "react-i18next";

type Props = {
	marginTop: string;
	scrollBarData?: {
		title: string;
		description: string;
		properties: any;
		state: string;
	};
	isShowStateProduct?: boolean;
	isShowDelivery?: boolean;
};

const VerticalScrollBar = ({
	marginTop,
	scrollBarData,
	isShowStateProduct,
	isShowDelivery,
}: Props) => {
	const swiperRef: any = useRef(null);
	const descriptionRef: any = useRef(null);
	const characteristicsRef: any = useRef(null);
	const deliveryRef: any = useRef(null);
	const [activeButton, setActiveButton] = useState(
		swiperRef.current?.swiper?.activeIndex || 0
	);
	const { t } = useTranslation();
	const property = scrollBarData?.properties;

	const scrollToComments = () => {
    const commentsBlock = document.getElementById("comments");
    if (commentsBlock) {
			const topPosition = commentsBlock.getBoundingClientRect().top + window.scrollY - 50;
			window.scrollTo({ top: topPosition, behavior: "smooth" });
    }
		handleNextClick(3);
  };

	const handleNextClick = (slideNum: number) => {
		setActiveButton(slideNum);
	};

	const buttons: Array<{ label: string, onClick: () => void, isActive: boolean }> = [
    {
			label: t("опис"),
			onClick: () => handleNextClick(0),
			isActive: activeButton === 0
		},
    {
			label: "характеристики",
			onClick: () => handleNextClick(1),
			isActive: activeButton === 1
		},
    ...(isShowDelivery
			? [{
					label: "доставка",
					onClick: () => handleNextClick(2),
					isActive: activeButton === 2
				}]
			: []),
    {
			label: t("коментарі"),
			onClick: () => scrollToComments(),
			isActive: activeButton === 3
		}
  ];

	return (
		<>
			<div className={s.adContentRightTabs}>
				<div style={{ marginTop: marginTop }} className={`${s.tabsButtons} `}>
					<Mobile>
						{buttons.map(({ label, onClick, isActive }) => (
							<button
								key={label}
								onClick={onClick}
								className={isActive ? s.active : ""}
							>
								{label}
							</button>
						))}
					</Mobile>
					<Desktop>
						<div>
							{buttons.map(({ label, onClick, isActive }) => (
								<button
									key={label}
									onClick={onClick}
									className={isActive ? s.active : ""}
								>
									{label}
								</button>
							))}
						</div>
						{isShowStateProduct && (
							<div className={s.stateInfo}>
								{t("Стан")}:{" "}
								{scrollBarData?.state?.toString() === "1"
									? t("Нове")
									: t("б/в")}
							</div>
						)}
					</Desktop>
				</div>
			</div>
			<Desktop>
				<div className={s.scrollBarContainer} ref={swiperRef}>
					{activeButton === 0 && (
						<div
							className={`${s.swiperSlide} ${activeButton !== 0 && s.hide}`}
							ref={descriptionRef}
						>
							<div
								className={s.descriptionText}
								style={{ whiteSpace: "pre-line" }}
							>
								<span>{scrollBarData?.description || ""}</span>
							</div>
						</div>
					)}
					{activeButton === 1 && (
						<div
							className={`${s.swiperSlide} ${activeButton !== 1 && s.hide}`}
							ref={characteristicsRef}
						>
							<div className={s.characteristics}>
								<ul className={s.characteristicsTable}>
									{property &&
										property.length > 0 &&
										property.map((p: any) => {
											return (
												<div key={p.filterPosition + p.propertyValues}>
													<li className={s.characteristicsElement}>
														{p.propertyName}:
													</li>
													<li
														className={s.characteristicsElement}
														style={{ whiteSpace: "pre-line" }}
													>
														{p.propertyValues.replace(/,(?!\s)/g, ",\n")}
													</li>
												</div>
											);
										})}
								</ul>
							</div>
						</div>
					)}
					{activeButton === 2 && (
						<div
							className={`${s.swiperSlide} ${activeButton !== 2 && s.hide}`}
							ref={deliveryRef}
						>
							<DeliveryBlock />
						</div>
					)}
				</div>
			</Desktop>
			<Mobile>
				<div className={s.scrollBarContainer} ref={swiperRef}>
					{activeButton === 0 && (
						<div
							className={`${s.swiperSlide} ${activeButton !== 0 && s.hide}`}
							ref={descriptionRef}
						>
							<div
								className={s.descriptionText}
								style={{ whiteSpace: "pre-line" }}
							>
								<span>{scrollBarData?.description || ""}</span>
							</div>
						</div>
					)}
					{activeButton === 1 && (
						<div
							className={`${s.swiperSlide} ${activeButton !== 1 && s.hide}`}
							ref={characteristicsRef}
						>
							<div className={s.characteristics}>
								<ul className={s.characteristicsTableMobile}>
									{property &&
										property.length > 0 &&
										property.map((p: any) => {
											return (
												<div
													key={p.filterPosition + p.propertyValues}
													className={s.characteristicsTableItemWrapMobile}
												>
													<li className={s.characteristicsTableItemNameMobile}>
														{p.propertyName}:
													</li>
													<li
														className={s.characteristicsTableItemValueMobile}
														style={{ whiteSpace: "pre-line" }}
													>
														{p.propertyValues.replace(/,(?!\s)/g, ",\n")}
													</li>
												</div>
											);
										})}
								</ul>
							</div>
						</div>
					)}
					{activeButton === 2 && (
						<div
							className={`${s.swiperSlide} ${activeButton !== 2 && s.hide}`}
							ref={deliveryRef}
						>
							<DeliveryBlock />
						</div>
					)}
				</div>
			</Mobile>
		</>
	);
};

export default VerticalScrollBar;
