import { OnePrice } from "../../redux/price_Reducer";

export const formattingPrice = (value: string) => {
	const formatIntegerPart = (integerPart: string) => {
		if (integerPart.length > 3) {
			const strPrice = integerPart.replace(/\s/g, "");
			let spacedNumber = "";
			for (let i = strPrice.length - 1; i >= 0; i--) {
				spacedNumber = strPrice[i] + spacedNumber;
				if ((strPrice.length - i) % 3 === 0 && i !== 0) {
					spacedNumber = " " + spacedNumber;
				}
			}
			return spacedNumber;
		} else {
			return integerPart;
		}
	};
	if (value) {
		const strValue = value
			.toString()
			.replace(/[^\d.,]/g, "")
			.replace(",", ".");
		const parts = strValue.split(".");
		if (parts.length === 2) {
			// Если есть дробная часть, проверим ее значение
			const integerPart = parts[0];
			const decimalPart = parts[1];
			const formattedInteger = formatIntegerPart(integerPart);
			if (decimalPart === "00") {
				return formattedInteger;
			} else {
				return formattedInteger + "." + decimalPart;
			}
		} else {
			// Если нет дробной части, форматируем всю строку
			return formatIntegerPart(strValue);
		}
	} else {
		return value;
	}
};
export const numberAddSpace = (value: number) => {
	const strValue = value.toString();
	// Удаляем все символы, кроме цифр и точки
	const sanitizedValue = strValue.replace(/[^\d.,]/g, "").replace(",", ".");

	// Разделяем ввод на целую и десятичную части (если есть)
	const parts = sanitizedValue.split(".");
	let integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");

	// Если есть десятичная часть, добавляем её после целой части с точкой
	if (parts.length > 1) {
		integerPart += "." + parts[1];
	}
	return integerPart;
};
export const scrollToTop = (behavior?: ScrollBehavior | undefined) => {
	window.scrollTo({
		top: 0,
		behavior: behavior ? behavior : "auto", // Для плавной прокрутки
	});
};


export const formattingOptionsForBuyService = (data: OnePrice[] | null) => {
	if (data) {
		return data?.map((el: OnePrice) => ({
			label: el.periodName,
			value: el.id.toString(),
			period: el.period,
			price: el.price,
			periodName: el.periodName,
		}));
	} else {
		return [];
	}
};

export const testActualityService = (time: number | null) => {
	const date = Date.now();
	return time && time * 1000 > date;
};
export const daysLef = (time: number | null) => {
	const now = new Date();
	const millisecondsSinceEpoch = now.getTime();
	const milliseconds =time ? time * 1000 : 0
	const millisecondsPerDay = 24 * 60 * 60 * 1000;
	const daysSinceEpoch = Math.floor(millisecondsSinceEpoch / millisecondsPerDay);
	const days = Math.floor(milliseconds / millisecondsPerDay);
	return days -daysSinceEpoch ;
};

export const capitalizeFirstLetter = (str: string): string => {
	return str.charAt(0).toUpperCase() + str.slice(1);
}


export function isEqual(value: any, other: any) {
	if (typeof value !== "object" && typeof other !== "object") {
	  return Object.is(value, other);
	}

	if (value === null && other === null) {
	  return true;
	}

	if (typeof value !== typeof other) {
	  return false;
	}

	if (value === other) {
	  return true;
	}

	if (Array.isArray(value) && Array.isArray(other)) {
	  if (value.length !== other.length) {
		return false;
	  }

	  for (let i = 0; i < value.length; i++) {
		if (!isEqual(value[i], other[i])) {
		  return false;
		}
	  }

	  return true;
	}

	if (Array.isArray(value) || Array.isArray(other)) {
	  return false;
	}

	if (Object.keys(value).length !== Object.keys(other).length) {
	  return false;
	}

	for (const [k, v] of Object.entries(value)) {
	  if (!(k in other)) {
		return false;
	  }

	  if (!isEqual(v, other[k])) {
		return false;
	  }
	}

	return true;
  }

export const formattingPhone = (phone: string) => {
	return phone.replace(/[()]/g, "").replace(/\s/g, "").replace(/\+/g, "");
};
