import {
	QueryObserverResult,
	RefetchOptions,
	useQueryClient,
} from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { favoriteHandler } from "../../../redux/publicNotice_Reducer";
import { StateType } from "../../../redux/redux-store";
import SearchCardSvgSelector from "../FavoriteSearchCard/SearchCardSvgSelector";
import { memo, useCallback, useState } from 'react'

type Props = {
	isFavorite: number;
	id: number;
	userId?: number;
	isOrange?: boolean;
	perPage?: number;
	page?: number;
	sort?: string;
};

const FavoriteHeartSelector = memo(({
	isFavorite,
	id,
	userId,
	isOrange,
	perPage = 48,
	page = 1,
	sort = "2"
}: Props) => {
	const dispatch: any = useDispatch();
	const userWId = useSelector((state: StateType) => state.userData.user.id);
	const queryClient = useQueryClient();

	const [localIsFavorite, setLocalIsFavorite] = useState(isFavorite);

	const handleFavoriteClick = useCallback(
		(action: "add" | "remove") => {
		setLocalIsFavorite(action === "add" ? 1 : 0);

		dispatch(favoriteHandler(id, action))
			.then(() => {
				queryClient.invalidateQueries({
					queryKey: ["get/noticesByUserId", userId, perPage, page, sort],
					refetchType: 'all'
				});
			})
			.catch(() => {
				setLocalIsFavorite(prev => (action === "add" ? 0 : 1));
			});
		},
		[id, dispatch, queryClient, userId, perPage, page, sort]
	);

	if (userWId !== userId) {
		if (isOrange) {
			return (
				<span
					onClick={(event) => {
						event.preventDefault();
					}}
				>
					{localIsFavorite === 1 ? (
						<button
							className="no-link"
							onClick={() => handleFavoriteClick("remove")}
						>
							<SearchCardSvgSelector id={"orangeHeartActive"} />
						</button>
					) : (
						<button onClick={() => handleFavoriteClick("add")}>
							<SearchCardSvgSelector id={"orangeHeart"} />
						</button>
					)}
				</span>
			);
		}

		return (
			<span
				onClick={(event) => {
					event.stopPropagation()
					event.preventDefault();
				}}
			>
				{localIsFavorite === 1 ? (
					<button
						className="no-link"
						onClick={() => handleFavoriteClick("remove")}
					>
						<SearchCardSvgSelector id={"yellowHeart"} />
					</button>
				) : (
					<button onClick={() => handleFavoriteClick("add")}>
						<SearchCardSvgSelector id={"heart"} />
					</button>
				)}
			</span>
		);
	} else {
		return null;
	}
});

export default FavoriteHeartSelector;
