import React, { forwardRef } from 'react';
import s from "./WorkInfo.module.css";
import { useTranslation } from 'react-i18next';
import OutputADSvgSelector from "../../../../Components/OutputBoardAdComponents/OutputADSvgSelector";
import { publicOneNotice } from '../../../../types/publicNoticeTypes';
import { Desktop, Mobile } from '../../../../responsive';
import { formattingPrice } from "../../../../common/Logic/supporting";


export const WorkInfoList: React.FC<{
  notice: publicOneNotice,
  card?: boolean,
  cardGrid?: boolean,
  row?: boolean,
  isPopular?: boolean
}> = ({ notice, card, cardGrid, row, isPopular }) => {
  const { t } = useTranslation();
  const { price, maxSalary, settlement_name, short_settlement_name } = notice;

  const salary = maxSalary
		? <span>
        {t('від')} <b className={isPopular ? s.popular : ''}>{formattingPrice(price)} {t('грн')}</b> {t('до')} <b className={isPopular ? s.popular : ''}>{formattingPrice(maxSalary)} {t('грн')}</b>
      </span>
		: <span>
        <b className={isPopular ? s.popular : ''}>{formattingPrice(price)} {t('грн')}</b>
      </span>

  const work_type_of_time = notice.noticeType?.notice_properties?.find((item) => item.specialViewOnFront === 'work_type_of_time');
  const work_type_of_place = notice.noticeType?.notice_properties?.find((item) => item.specialViewOnFront === 'work_type_of_place');

	const list = [
    {
      type: 'salary',
      value: !row ? salary : null,
      icon: 'moneybag'
    },
    {
      type: 'place',
      value: work_type_of_place?.propertyValues,
      icon: 'suitcase'
    },
    {
      type: 'time',
      value: work_type_of_time?.propertyValues,
      icon: 'greyClock'
    },
		{
      type: 'location',
			value: card ? short_settlement_name : settlement_name,
			icon: 'location'
		}
	];

  return (
    <>
      {['Desktop', 'Mobile'].map((device) => {
        const isMobile = device === 'Mobile';
        const Component = isMobile ? Mobile : Desktop;
        return (
          <Component key={device}>
            <ul className={`${s.list} ${isMobile ? s.mobile : ''} ${card ? s.card : ''}`}>
              {list.map(item => {
                if (!item.value) return null;
                else return (
                  <li key={item.value.toString()}>
                    <OutputADSvgSelector id={item.icon}/>
                    <span 
                      className={`${(item.type === 'salary' && cardGrid) ? s.cardGrid : ''} ${item.type === 'salary' ? "" : s.text}`}
                      title={typeof item.value === 'string' ? item.value : undefined }
                    >
                      {item.value}
                    </span>
                  </li>
                );
              })}
            </ul>
          </Component>
        );
      })}
    </>
  );
};

export const VacancyType = forwardRef<HTMLDivElement, { type: string, card?: boolean }>(({ type, card }, ref) => {
  const vacancyTypeList: { [key: string]: { icon: JSX.Element, color: string } } = {
    "Гаряча вакансія": {
      icon: <OutputADSvgSelector id={"fire"} />,
      color: '#E25528'
    },
    "Горячая вакансия": {
      icon: <OutputADSvgSelector id={"fire"} />,
      color: '#E25528'
    },
    "Відкрита вакансія": {
      icon: <OutputADSvgSelector id={"blueClock"} />,
      color: '#00A9CE'
    },
    "Открытая вакансия": {
      icon: <OutputADSvgSelector id={"blueClock"} />,
      color: '#00A9CE'
    }
  }
  return (<>
    {['Desktop', 'Mobile'].map((device) => {
      const isMobile = device === 'Mobile';
      const Component = isMobile ? Mobile : Desktop;
      return (
        <Component key={device} ref={ref}>
          <span className={`${s.vacancyType} ${isMobile ? s.mobile : ''} ${card ? s.card : ''}`} style={{ color: vacancyTypeList[type as string]?.color }}>
            {vacancyTypeList[type as string]?.icon} {type}
          </span>
        </Component>
      )
    })}
  </>);
});