import React, { useEffect, useState } from "react";
import s from "./PasswordFormInput.module.css";
import { ErrorMessage, Field } from "formik";
import style from "../../GlobalStyles.module.css";
import CustomCheckbox from "../CustomCheckBox/CustomCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { getRestorePass } from "../../redux/user_Reducer";
import { useTranslation } from "react-i18next";
import VisibilityOffSharpIcon from "@mui/icons-material/VisibilityOffSharp";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { getRecaptchaCode } from "../../common/Logic/recaptchaService";

type passwordInputProps = {
	isAuth?: boolean;
	values: { password: string, phone?: string; captcha?: string,saveMe?:boolean };
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) => void;
	status: string;
};

const PasswordFormInput = ({
	isAuth,
	values,
	setFieldValue,
	status,
}: passwordInputProps) => {
	const [cyrillic, setCyrillic] = useState(false);
	const { t } = useTranslation();
	const [pass, setPass] = useState(true);
	const dispatch: any = useDispatch();
	const [capsLockPressed, setCapsLockPressed] = useState(false);
	const forgotPass = useSelector(() => getRestorePass);


	function generatePassword() {
		const length = 10; // Минимальная длина пароля
		const lowercaseCharset = "abcdefghijklmnopqrstuvwxyz";
		const uppercaseCharset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		const numericCharset = "0123456789";
		// Составим пароль из по меньшей мере одной буквы в нижнем регистре, одной в верхнем регистре и одной цифры
		const requiredChars = [
			lowercaseCharset[Math.floor(Math.random() * lowercaseCharset.length)],
			uppercaseCharset[Math.floor(Math.random() * uppercaseCharset.length)],
			numericCharset[Math.floor(Math.random() * numericCharset.length)],
		];
		// Дополним пароль случайными символами из общего набора
		const charset = lowercaseCharset + uppercaseCharset + numericCharset;
		for (let i = requiredChars.length; i < length; i++) {
			const randomIndex = Math.floor(Math.random() * charset.length);
			requiredChars.push(charset[randomIndex]);
		}
		// Перемешаем символы пароля, чтобы они были в случайном порядке
		requiredChars.sort(() => Math.random() - 0.5);
		return handleChangePassInput(
			requiredChars.join("")
		);
	}
	const { executeRecaptcha } = useGoogleReCaptcha();

	const handleKeyDown = (e: KeyboardEvent) => {
		if (e.getModifierState && e.getModifierState("CapsLock")) {
			setCapsLockPressed(true);
		} else {
			setCapsLockPressed(false);
		}
	};

	const handleChangePassInput = (password: string) => {
		const containsCyrillic = /[а-яА-Я]/.test(password);
		setCyrillic(containsCyrillic);
		//checkPass(values.password)
		setFieldValue("password", password);
	};
	const handleCheckBox = () => {
		setFieldValue("saveMe",!values.saveMe);
	};
	const validate = (values: any) => {
		const errors: any = { password: "" };
		if (values.password === "") {
			errors.password = t("Пароль не має містити пробіли");
		}
		if (!values.password) {
			errors.password = t("Пароль не може бути пустий");
		} else if (/\s/.test(values.password)) {
			errors.password = t("Пароль не має містити пробіли");
		} else if (values.password.length < 8) {
			errors.password = t("Пароль має бути не менше 8 символів");
		}
		// Другие правила валидации по необходимости

		return errors.password;
	};
	useEffect(() => {
		window.addEventListener("keydown", handleKeyDown);
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [capsLockPressed]);

	return (
		<div className={s.container}>
			<Field
				maxLength={16}
				type={pass ? "password" : "text"}
				name={"password"}
				onPaste={(event: any) =>
					handleChangePassInput(event.currentTarget.value)
				}
				//onFocus={checkPass(values.password)}
				/*onBlur={checkPass(values.password)}*/
				onChange={(event: any) =>
					handleChangePassInput(event.currentTarget.value)
				}
				value={values.password}
				placeholder={!isAuth ? t(`Придумайте пароль`) : t(`Введіть пароль`)}
				validate={() => validate(values)}
			/>
			<ErrorMessage name="password" component="div" className={s.error} />

			{capsLockPressed && <span className={style.error}>CapsLock</span>}
			{cyrillic && (
				<span className={style.error}>
					{t("Пароль не може містити кирилицю")}
				</span>
			)}
			{status && <span className={style.error}>{status}</span>}
			{!isAuth && (
				<span className={s.generationPass} onClick={generatePassword}>
					генератор пароля
				</span>
			)}
			{/*<span className={s.seePassButton}>
                                    {safePass &&
                                        <AuthSvgSelector id={"seePassword"}/>}
                                </span>*/}
			<span className={s.seePassButtonEye} onClick={() => setPass(!pass)}>
				{!pass ? <VisibilitySharpIcon /> : <VisibilityOffSharpIcon />}
			</span>
			{status && isAuth ? (
				<div className={s.rememberPassContainer}>
					<div>{t("Забули пароль")}?</div>
					<span
						onClick={async () => {
							if (values.phone && values.phone?.length > 18) {
								const newCaptcha = await getRecaptchaCode(executeRecaptcha);
								dispatch(forgotPass(values.phone, newCaptcha ?? ""));
							}
						}}
					>
						{t("Відновити")}
					</span>
				</div>
			) : (
				<>
				<CustomCheckbox
					saveMe={values.saveMe ?? true}
					handleCheckBox={handleCheckBox}
					labelTxt={t("Запам'ятати мене")}
					id={"1"}
				/></>
			)}

			{/*{capsLockPressed &&
                <div className={s.capsInfoContainer}>
                    <div className={s.cadsText}>
                        <span className={s.cadsIcon}>
                            <ArrowsAndCross id={"attention"} />
                        </span>
                        <span>
                            CapsLock
                        </span>

                    </div>
                </div>}*/}
			{/*{cyrillic &&
                <div className={`${s.capsInfoContainer} ${s.attentionCurilic}`}>
                    <div className={s.cadsText}>
                        <span className={s.cadsIcon}>
                        <ArrowsAndCross id={"attention"} />
                        </span>
                        {t('Пароль не може містити кирилицю')}
                    </div>
                </div>}*/}
		</div>
	);
};

export default PasswordFormInput;
