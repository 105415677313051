import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import type { Slogan } from "../../../api/business-page/types";
import SearchCardSvgSelector from "../../../Components/Cards/FavoriteSearchCard/SearchCardSvgSelector";
import {
	useSetSlogan,
	useUploadBusinessImage,
} from "../../../CustomHooks/api/business-page/useBusinessPage";
import DEFAULT_BANER from "../../../images/default-banner.png";
import { setNotification } from "../../../redux/notificationReducer";
import styles from "../BusinessPage.module.css";

type Props = {
	bannerUrl: string;
	phone: string[];
	slogan?: Slogan;
	city: string;
	isUserPage: boolean;
};

const ImageUpload = (props: Props) => {
	const { t } = useTranslation();
	const [bannerUrl, setBannerUrl] = useState<string>(
		props.bannerUrl || DEFAULT_BANER
	);
	const [isImageUploaded, setIsImageUploaded] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [successMessage, setSuccessMessage] = useState<string | null>(null);
	const [isEditingSlogan, setIsEditingSlogan] = useState<boolean>(false);
	const [currentSlogan, setCurrentSlogan] = useState<string>(
		props.slogan?.slogan.value || ""
	);
	const { mutate: setSlogan } = useSetSlogan("");
	const dispatch: any = useDispatch();

	const fileUploadRef = useRef<HTMLInputElement | null>(null);
	const sloganInputRef = useRef<HTMLInputElement | null>(null);

	const { mutate: uploadImage, isPending: isUploading } =
		useUploadBusinessImage("");

	const handleImageUpload = (
		event: React.MouseEvent<HTMLImageElement | HTMLDivElement>
	) => {
		if (!props.isUserPage) return;

		event.preventDefault();
		fileUploadRef.current?.click();
	};

	const uploadImageDisplay = (event: ChangeEvent<HTMLInputElement>) => {
		if (!props.isUserPage) return;

		const uploadedFile = event.target.files?.[0];
		if (!uploadedFile) return;

		// Валідація типу файлу
		if (!uploadedFile.type.startsWith("image/")) {
			setErrorMessage(t("Будь ласка, завантажуйте лише зображення."));
			return;
		}

		// Валідація розміру файлу (не більше 5MB)
		const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
		if (uploadedFile.size > maxSizeInBytes) {
			setErrorMessage(t("Розмір файлу перевищує 5MB."));
			return;
		}

		// Показати попередній перегляд
		const previewUrl = URL.createObjectURL(uploadedFile);
		setBannerUrl(previewUrl);
		setErrorMessage(null);
		setSuccessMessage(null);

		// Відправка файлу безпосередньо
		uploadImage({
			scenario: "banner",
			image: uploadedFile,
		});
	};

	const handleSloganClick = (e: React.MouseEvent<HTMLParagraphElement>) => {
		if (!props.isUserPage) return;

		e.stopPropagation();

		setIsEditingSlogan(true);
		sloganInputRef.current?.focus();
	};

	const handleSloganChange = (e: ChangeEvent<HTMLInputElement>) => {
		e.stopPropagation();

		setCurrentSlogan(e.target.value);
	};

	const handleSloganSave = () => {
		// if (currentSlogan.trim() === "") {
		// 	dispatch(
		// 		setNotification(t("Ваша сторінка знаходиться на модерації."), "success")
		// 	);
		// 	return;
		// }
		if (currentSlogan !== props.slogan?.slogan.value) {
			setSlogan(currentSlogan, {
				onSuccess: ({ data }) => {
					if (data.errors && Object.keys(data.errors).length) {
						for (const key in data.errors) {
							dispatch(setNotification(data.errors[key], "error"));
							setIsEditingSlogan(true);
							return;
						}
						return;
					}
					dispatch(setNotification("Ваші зміни збережені.", "success"));

					setIsEditingSlogan(false);
					setErrorMessage(null);
				},
				onError: () => {
					setErrorMessage(t("Не вдалося зберегти слоган."));
				},
			});

			// dispatch(
			// 	setNotification(
			// 		t("Ваші зміни збережені. Ваша сторінка відправлена на модерацію."),
			// 		"success"
			// 	)
			// );
		}

		setIsEditingSlogan(false);
	};

	const handleSloganKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		e.stopPropagation();
		if (e.key === "Enter") {
			handleSloganSave();
		}
	};

	const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
		if (!props.isUserPage) return;

		event.preventDefault();
		const uploadedFile = event.dataTransfer.files[0];
		if (!uploadedFile) return;

		// File validation
		if (!uploadedFile.type.startsWith("image/")) {
			setErrorMessage(t("Будь ласка, завантажуйте лише зображення."));
			return;
		}
		if (uploadedFile.size > 5 * 1024 * 1024) {
			setErrorMessage(t("Розмір файлу перевищує 5MB."));
			return;
		}

		// Show preview and upload
		const previewUrl = URL.createObjectURL(uploadedFile);
		setBannerUrl(previewUrl);
		setErrorMessage(null);
		setSuccessMessage(null);

		uploadImage({
			scenario: "banner",
			image: uploadedFile,
		});
	};

	const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
		if (!props.isUserPage) return;

		event.preventDefault();
		event.dataTransfer.dropEffect = "copy";
	};

	useEffect(() => {
		return () => {
			if (bannerUrl && bannerUrl !== DEFAULT_BANER) {
				URL.revokeObjectURL(bannerUrl);
			}
		};
	}, [bannerUrl]);

	useEffect(() => {
		if (isEditingSlogan) {
			sloganInputRef.current?.focus();
		}
	}, [isEditingSlogan]);

	return (
		<div className={styles.bannerContainer}>
			<div
				onClick={handleImageUpload}
				className={styles.bannerWrapper}
				onDrop={handleDrop}
				onDragOver={handleDragOver}
			>
				<img src={bannerUrl} alt="Banner" className={styles.banner} />
				{bannerUrl && (
					<div className={styles.sloganOverlay}>
						{isEditingSlogan ? (
							<input
								ref={sloganInputRef}
								type="text"
								onClick={(e) => e.stopPropagation()}
								value={currentSlogan}
								onChange={handleSloganChange}
								onBlur={handleSloganSave}
								onKeyDown={handleSloganKeyDown}
								className={styles.sloganInput}
							/>
						) : (
							<div
								onClick={handleSloganClick}
								className={styles.sloganContainer}
							>
								<p className={styles.sloganText}>
									{props.slogan?.slogan.value}
								</p>
								{props.isUserPage && props.slogan?.slogan.value !== "" ? (
									<svg
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										className={styles.sloganIcon}
									>
										<path
											d="M16.4283 10C16.0338 10 15.714 10.3198 15.714 10.7143V17.8571C15.714 18.2516 15.3942 18.5714 14.9998 18.5714H2.14283C1.74833 18.5714 1.42854 18.2516 1.42854 17.8571V3.57143C1.42854 3.17693 1.74833 2.85713 2.14283 2.85713H10.7141C11.1086 2.85713 11.4284 2.53733 11.4284 2.14283C11.4284 1.74832 11.1086 1.42857 10.7141 1.42857H2.14283C0.959376 1.42857 0 2.38796 0 3.57143V17.8571C0 19.0406 0.959376 20 2.14283 20H14.9998C16.1832 20 17.1426 19.0406 17.1426 17.8571V10.7143C17.1426 10.3198 16.8228 10 16.4283 10Z"
											fill="white"
										/>
										<path
											d="M19.1993 0.800988C18.6865 0.288042 17.9908 -7.24047e-05 17.2655 1.13007e-05C16.5397 -0.00208134 15.8434 0.286535 15.332 0.801449L5.9235 10.2093C5.84544 10.2879 5.78656 10.3835 5.75136 10.4886L4.32282 14.7743C4.19814 15.1486 4.4005 15.553 4.77478 15.6777C4.84739 15.7019 4.92344 15.7142 4.99995 15.7143C5.07662 15.7142 5.15283 15.7019 5.22565 15.6779L9.51131 14.2493C9.61657 14.2142 9.7122 14.155 9.79059 14.0765L19.199 4.66789C20.2669 3.60014 20.267 1.86886 19.1993 0.800988ZM18.1891 3.65857L8.90345 12.9443L6.1292 13.8707L7.05275 11.1L16.3419 1.81428C16.8526 1.3046 17.6798 1.30544 18.1895 1.81613C18.4329 2.06009 18.5701 2.39035 18.5712 2.735C18.572 3.0815 18.4345 3.41398 18.1891 3.65857Z"
											fill="white"
										/>
									</svg>
								) : null}
							</div>
						)}
					</div>
				)}
				{isUploading && (
					<div className={styles.uploadingOverlay}>Завантаження...</div>
				)}
				{!props.bannerUrl ? (
					<div className={styles.bannerText}>
						<p className={styles.bannerTextDownload}>
							{t(
								"Перетягніть сюди фото банера або натисніть на нього, щоб відкрити вікно завантаження"
							)}
						</p>
						<p className={styles.bannerTextRecommend}>
							{t("Рекомендований розмір банера: 1506 х 250 px")}
						</p>
					</div>
				) : null}
			</div>
			{props.isUserPage && props.bannerUrl ? (
				<div className={styles.mainBanner} onClick={handleImageUpload}>
					{t("Головний банер")}
					<svg
						onClick={(e) => {
							e.stopPropagation();
							setIsEditingSlogan(true);
							sloganInputRef.current?.focus();
						}}
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="white"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M16.4283 10C16.0338 10 15.714 10.3198 15.714 10.7143V17.8571C15.714 18.2516 15.3942 18.5714 14.9998 18.5714H2.14283C1.74833 18.5714 1.42854 18.2516 1.42854 17.8571V3.57143C1.42854 3.17693 1.74833 2.85713 2.14283 2.85713H10.7141C11.1086 2.85713 11.4284 2.53733 11.4284 2.14283C11.4284 1.74832 11.1086 1.42857 10.7141 1.42857H2.14283C0.959376 1.42857 0 2.38796 0 3.57143V17.8571C0 19.0406 0.959376 20 2.14283 20H14.9998C16.1832 20 17.1426 19.0406 17.1426 17.8571V10.7143C17.1426 10.3198 16.8228 10 16.4283 10Z"
							fill="white"
						/>
						<path
							d="M19.1993 0.800988C18.6865 0.288042 17.9908 -7.24047e-05 17.2655 1.13007e-05C16.5397 -0.00208134 15.8434 0.286535 15.332 0.801449L5.9235 10.2093C5.84544 10.2879 5.78656 10.3835 5.75136 10.4886L4.32282 14.7743C4.19814 15.1486 4.4005 15.553 4.77478 15.6777C4.84739 15.7019 4.92344 15.7142 4.99995 15.7143C5.07662 15.7142 5.15283 15.7019 5.22565 15.6779L9.51131 14.2493C9.61657 14.2142 9.7122 14.155 9.79059 14.0765L19.199 4.66789C20.2669 3.60014 20.267 1.86886 19.1993 0.800988ZM18.1891 3.65857L8.90345 12.9443L6.1292 13.8707L7.05275 11.1L16.3419 1.81428C16.8526 1.3046 17.6798 1.30544 18.1895 1.81613C18.4329 2.06009 18.5701 2.39035 18.5712 2.735C18.572 3.0815 18.4345 3.41398 18.1891 3.65857Z"
							fill="white"
						/>
					</svg>
				</div>
			) : null}

			<div className={styles.bannerInfoSeller}>
				{props.phone.length > 0 && (
					<>
						{props.phone.map((phoneNumber) => (
							<p key={phoneNumber}>
								<SearchCardSvgSelector id="phone" />
								<a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
							</p>
						))}
					</>
				)}
				{props.city ? (
					<p>
						<SearchCardSvgSelector id={"location_white"} />
						{props.city.split(",")[0]}
					</p>
				) : null}
			</div>
			<input
				type="file"
				id="file"
				ref={fileUploadRef}
				onChange={uploadImageDisplay}
				accept="image/*"
				hidden
			/>
		</div>
	);
};

export default ImageUpload;
