import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import mockListing from "../../../images/listing-card-mobile.svg";
import { setClearFiltersList } from "../../../redux/category_Reducer";
import { setImagesList } from "../../../redux/notice_Reducer";
import { StateType } from "../../../redux/redux-store";
import {  setPhoneEnterPopup } from "../../../redux/user_Reducer";
import { stateUserType } from "../../../types/reduxStoreTypes";
import s from "../ProductCard/ProductCardHorizontalMobile.module.css";

const AddListingCardRowMobile = () => {
	const { i18n, t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const auth = useSelector((state: stateUserType) => state.userData.isAuth);
	const userView = useSelector((state: StateType) => state.userData.user);
	const clearFilters = useSelector(() => setClearFiltersList);
	const segmentsArray = location.pathname?.split("/").filter(Boolean);

	const languagePath = i18n.language === "ru" ? "" : "ua/"

	const openEnterDialog = () => {
		navigate(`/registration_auth`)
	};

	const clearInputs = () => {
		dispatch(setImagesList([]));
		dispatch(clearFilters());
	};

	const onClickOnCard = () => {
		clearInputs();
		if (auth && userView.is_approve_phone !== 1) {
			dispatch(setPhoneEnterPopup(true));
		} else if (auth && userView.is_approve_phone === 1) {
			if (
				segmentsArray?.length > 0 &&
				(segmentsArray[0] === "create_ad" || segmentsArray[1] === "create_ad")
			) {
				alert(
					t(
						"Оголошення, яке ви розпочали подавати," +
							" ще не було опубліковано. Бажаєте повернутися до цього оголошення?"
					)
				);
			} else {
				navigate(`/${languagePath}create_ad/${segmentsArray[segmentsArray.length - 1]}`);
			}
		}
	};

	return (
		<div
			className={s.horizontalCardBlock}
			onClick={() => {
				!auth ? openEnterDialog() : onClickOnCard();
			}}
		>
			<div className={s.horizontalCardImageBlock}>
				<img src={mockListing} alt={"listing"} loading="lazy" />
			</div>
			<div className={s.horizontalCardContentBlock}>
				<div className={s.addAD}>
					<h2>
					{segmentsArray.includes("search_result") ||
						segmentsArray.includes("search")
							? t("Додати оголошення")
							: t("Додати в цю рубрику оголошення")}
					</h2>
					<button>
						<h3>+ {t(`оголошення`)}</h3>
					</button>
				</div>
			</div>
		</div>
	);
};

export default AddListingCardRowMobile;
