import React, { useEffect, useState } from "react";
import s from "./MyRecentlyWatchedMobile.module.css";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../../redux/redux-store";

import {
	getFilteredNoticeList,
	setFilteredNoticesAllList,
} from "../../../redux/publicNotice_Reducer";
import { scrollToTop } from "../../../common/Logic/supporting";
import ExpandModernButton from "../../ExpandButton/ExpandModernButton";
import useCatIdsHandler from "../../../CustomHooks/useCatIdsHandler";
import ProductCardHorizontalForSearchMobile from "../../Cards/ProductCard/ProductCardHorizontalForSearchMobile";
import MobileProductCard from "../../Cards/ProductCard/MobileProductCard";
import CabinetTopFiltersBlock from "../CabinetTopFiltersBlock/CabinetTopFiltersBlock";

const MyRecentlyWatchedMobile = () => {
	const [page, setPage] = useState(1);
	const [row, setRow] = useState(false);
	const dispatch: any = useDispatch();
	const watchedList = useSelector(
		(state: StateType) => state.publicNotice.filteredNotices
	);
	const watchedAllList = useSelector(
		(state: StateType) => state.publicNotice.filteredNoticesAllList
	);
	const watchedMeta = useSelector(
		(state: StateType) => state.publicNotice.filteredNoticesPagination
	);

	const catIdsForHook = useSelector(
		(state: StateType) => state.noticeData.viewedStatisticIds
	);
	const { catIds, handleCatIds } = useCatIdsHandler(catIdsForHook);
	const getWatchedList = useSelector(() => getFilteredNoticeList);
	const viewedStat = useSelector(
		(state: StateType) => state.publicNotice.viewedStatistic
	);
	/*const elements = [
		{
			link: "",
			name: t("Обраний товар"),
			elementData: favoriteCounts,
			status: 30,
		} /!*{
            link: "favoriteSearches",
            name: t("Обрані пошуки"),
            elementData: "0",
            status: 10
        }, {
            link: "businessCards",
            name: t("Візитки"),
            elementData: "0",
            status: 20
        },*!/,
	];*/
	useEffect(() => {
		dispatch(
			getWatchedList({
				onlyViewed: "1",
				perPage: `${48}`,
				page: page.toString(),
				catIds: catIds,
			})
		);
		scrollToTop();
	}, [page, catIds, watchedMeta.perPage]);

	useEffect(() => {
		if (watchedList && watchedList.length) {
			if (page == 0 || page == 1) {
				dispatch(setFilteredNoticesAllList(watchedList));
			} else {
				const newArr = [...watchedAllList, ...watchedList];
				const arrayLength = newArr.length;
				const limit = 150;
				if (arrayLength > limit) {
					const difference = arrayLength - limit;
					const slicedArr = newArr.slice(difference); // Зберігаємо результат виклику slice
					dispatch(setFilteredNoticesAllList(slicedArr));
				} else {
					dispatch(setFilteredNoticesAllList(newArr));
				}
			}
		}
	}, [watchedList]);

	return (
		<div className={s.favoriteMobileProductContainer}>
			<div>
				<CabinetTopFiltersBlock
					row={row}
					setRow={setRow}
					type={"recentlyWatched"}
					handleCatIds={handleCatIds}
					catIds={catIds}
					categories={viewedStat}
				/>
			</div>
			{row && (
				<div className={s.cardsContainerRow}>
					{watchedAllList.map((item) => (
						<ProductCardHorizontalForSearchMobile notice={item} key={item.id} isRow />
					))}
				</div>
			)}
			{!row && (
				<div className={s.cardsContainerColumn}>
					{watchedAllList.map((item) => (
						<MobileProductCard notice={item} key={item.id} />
					))}
				</div>
			)}
			{watchedMeta.pageCount > watchedMeta.currentPage && (
				<div className={s.paginatorContainer}>
					<ExpandModernButton
						onClickCallback={() => {
							setPage((prevState) => prevState + 1);
						}}
					/>
				</div>
			)}
		</div>
	);
};

export default MyRecentlyWatchedMobile;
