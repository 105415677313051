import { Slogan } from "../../../api/business-page/types";
import ImageUpload from "./ImageUpload";

type Props = {
	bannerUrl: string;
	phone: string[];
	slogan: Slogan;
	city: string;
	isUserPage: boolean;
};

export const TopBanner = (props: Props) => {
	const { bannerUrl, phone, slogan, city, isUserPage } = props;
	return (
		<>
			<ImageUpload
				slogan={slogan}
				phone={phone}
				bannerUrl={bannerUrl}
				city={city}
				isUserPage={isUserPage}
			/>
		</>
	);
};
