import { testActualityService } from "../../../common/Logic/supporting";
import { publicOneNotice } from "../../../types/publicNoticeTypes";
import PersonalCabinetSvgSelector from "../../PersonalCabinet/PersonalCabinetSvgSelector";
import FavoriteHeartSelector from "../FavoriteHeartSelector/FavoriteHeartSelector";
import s from "./ProductCardGrid.module.css";
import { VacancyType, WorkInfoList } from "../../../pages/OutputBordAD/WorkNotice/WorkInfoList/WorkInfo";
import WorkSkeletonComponent from "../../WorkSkeletonComponent/WorkSkeletonComponent";

const WorkProductCardGrid = ({
	grid,
	notice,
	image,
	sort = "2",
	page = 1,
	perPage = 48
}: {
	grid: string;
	notice: publicOneNotice;
	image?: string;
	sort?: string;
	page?: number;
	perPage?: number;

}) => {
	const workType = notice.noticeType?.notice_properties?.find(prop => prop.specialViewOnFront === "work_search_propose")?.propertyValues;

	return (<>
		{image ? (
			<div className={s.workCardImage}>
				<img src={image} alt={notice.name} />
			</div>
		) : workType && (
			<WorkSkeletonComponent type={workType} category={notice.category_name} />
		)}
		<div className={s.topFlag}>
			{notice.top_at && testActualityService(notice.top_at) && (
				<PersonalCabinetSvgSelector id={"top100"} />
			)}
		</div>

		<div className={`${s.cardTitle} ${s.work}`}>
			<h4 title={notice.name}>{notice.name}</h4>

			<FavoriteHeartSelector
				id={notice?.id || 0}
				isFavorite={notice?.isFavorite || 0}
				userId={notice?.users_id || 0}
				sort={sort}
				page={page}
				perPage={perPage}
			/>
		</div>

		<div className={s.cardWorkContent}>
			<WorkInfoList notice={notice} card cardGrid />
			{notice.noticeType?.vacancyType &&
				<VacancyType type={notice.noticeType?.vacancyType}/>
			}
		</div>
	</>);
};

export default WorkProductCardGrid;
