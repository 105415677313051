import React, { useEffect, useState } from "react";
import s from "../../../pages/PersonalCabinet/PersonalCabinet.module.css";

import MyAds from "./MyAds";
import PersonalCabinetCheckBoxPanel from "../PersonalCabinetCheckBoxPanel";
import PersonalCabinetTopSection from "../PersonalCabinetTopSection";
import { useDispatch, useSelector } from "react-redux";
import {
	getNoticeList,
	setCategoriesIdsList,
	setNoticesAllList,
} from "../../../redux/notice_Reducer";
import { StateType } from "../../../redux/redux-store";
import { useTranslation } from "react-i18next";
import Paginator from "../../Paginator/Paginator";
import { scrollToTop } from "../../../common/Logic/supporting";
import useCatIdsHandler from "../../../CustomHooks/useCatIdsHandler";
import { getPriceList } from "../../../redux/price_Reducer";

function PersonalCabinetAds() {
	const { t, i18n } = useTranslation();
	const dispatch: any = useDispatch();
	const [status, setStatus] = useState(30);
	const [page, setPage] = useState(1);
	const getNotices = useSelector(() => getNoticeList);
	const setCatIds = useSelector(() => setCategoriesIdsList);
	const giveMePriceList = useSelector(() => getPriceList);
	const notices = useSelector(
		(state: StateType) => state.noticeData?.noticeList
	);
	const noticesMeta = useSelector(
		(state: StateType) => state.noticeData.noticeListMeta
	);
	const userStatic = useSelector(
		(state: StateType) => state.userData.userStatic
	);
	const staticInfo = userStatic?.userCountNotices[0] || {};
	const categories = useSelector(
		(state: StateType) => state.noticeData.userStatByCategory
	);
	const catIdsForHook = useSelector(
		(state: StateType) => state.noticeData.userStatByCategoryIds
	);
	const { catIds, handleCatIds } = useCatIdsHandler(catIdsForHook);
	const noticesAllList = useSelector(
		(state: StateType) => state.noticeData?.noticeListAllList
	);

	useEffect(() => {
		// if(notices && notices.length) {
		if (page == 0 || page == 1) {
			dispatch(setNoticesAllList(notices));
		} else {
			const newArr = [...noticesAllList, ...notices];
			dispatch(setNoticesAllList(newArr));
		}

		// }
	}, [notices]);
	useEffect(() => {
		dispatch(giveMePriceList());
	}, [i18n.language]);
	const elements = [
		{
			link: "active",
			name: t("Активні"),
			elementData: staticInfo.active || "0",
			status: 30,
		},
		{
			link: "under_review",
			name: t("На перевірці"),
			elementData: staticInfo.waitModer || "0",
			status: 10,
		},
		{
			link: "rejected",
			name: t("Відхилені"),
			elementData: staticInfo.rejected || "0",
			status: 20,
		},
		{
			link: "no_active",
			name: t("Неактивні"),
			elementData: staticInfo.noActive || "0",
			status: 40,
		},
	];

	useEffect(() => {
		dispatch(
			getNotices({ perPage: 48, status: status, page: page, catIds: catIds })
		);
		scrollToTop();
	}, [status, page, noticesMeta.pageCount, catIds, i18n.language]);

	return (
		<div className={s.personalCabinetADS}>
			<PersonalCabinetTopSection
				title={t("мої оголошення")}
				headers={elements}
				status={status}
				setPage={setPage}
				setStatus={setStatus}
				setCatIds={setCatIds}
			/>

			{/*{!!notices && !!notices.length &&*/}
			<PersonalCabinetCheckBoxPanel
				categories={categories}
				handleCatIds={handleCatIds}
				catIds={catIds}
			/>

			<div className={s.cabinetContent_CardTemplate}>
				{notices?.map((notice: any) => (
					<div key={notice.id}>
						<MyAds notice={notice} type={status} />
					</div>
				))}
				{noticesMeta.pageCount > 1 && (
					<div className={s.paginatorContainer}>
						{/* <ExpandModernButton onClickCallback={()=>{
                                setPage((prevState)=>prevState + 1)
                            }}/> */}
						{/* {noticesMeta.pageCount > noticesMeta.currentPage &&
                            <div className={s.paginatorContainer}>
                                <ExpandModernButton onClickCallback={() => {
                                    setPage((prevState) => prevState + 1)
                                }} />
                            </div>} */}
						<Paginator
							page={page}
							setPage={setPage}
							count={noticesMeta.pageCount}
						/>
					</div>
				)}
			</div>
		</div>
	);
}

export default PersonalCabinetAds;
