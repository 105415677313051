import React from "react";
import s from "./ChangeLayotZone.module.css";
import AnimationPlayer from "../../common/Animations/AnimationPlayer/AnimationPlayer";
import loadHamster from "../../common/Animations/Aniki Hamster.json";
import loadError from "../../common/Animations/Main Scene2.json";
import CreateBoardADSvg from "../../pages/CreateBoardAD/Components/CreateBoardADSvg";
import { Image } from "./ChangeLayoutZone";
import { useTranslation } from "react-i18next";

interface Props {
	image: Image;
	removeError: string | null;
	handleRotateImage: any;
	handleRemove: any;
}

const GridElement:React.FC<Props> = ({image,
																			 removeError,
																			 handleRotateImage,
																			 handleRemove,
																			 }) => {
	const {t} = useTranslation();
	return (
			<div className={s.gridElementContainer}>
				{image.name === "loading" ? (
					<AnimationPlayer animation={loadHamster} />
				) : image.name === "error" ? (
					<>
						<AnimationPlayer animation={loadError} />
						<div className={s.errorSize}>
							{t("Розмір файлу перевищує 5мб")}
						</div>
					</>
				) : (
					<img
						className={s.gridElementImage}
						style={{ transform: `rotate(${image.rotate}deg)` }}
						src={image.content ?? undefined}
						alt="Uploaded"
					/>
				)}
				<div className={s.removeError}>{removeError && removeError}</div>
				<div className={s.buttonContainer}>
					{image.name !== "error" && (
						<div className={`${s.button} ${s.rotate}`}>
							<button
								type={"button"}
								onClick={() => handleRotateImage(image.i)}
								onTouchStart={() => handleRotateImage(image.i)}
							>
								<CreateBoardADSvg id={"rotateIcon"} />
							</button>
						</div>
					)}
					<div className={`${s.button} ${s.remove}`}>
						<button
							type={"button"}
							onClick={(event) => {
								event.stopPropagation();
								handleRemove(image.i, image.name, image.tmpName);
							}}
							onTouchStart={(event) => {
								event.stopPropagation();
								handleRemove(image.i, image.name, image.tmpName);
							}}
						>
							<CreateBoardADSvg id={"trashIcon"} />
						</button>
					</div>
				</div>
			</div>
	);
};

export default GridElement;