import { useEffect, useState } from "react";
import { NoticeType } from "../types/noticeTypes";

const useAutoContinue = (notice:NoticeType) => {
	const [visibleAutoContinue, setVisibleAutoContinue] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			if (notice?.categoryBlocs?.length) {
				try {
					const result = notice?.categoryBlocs.filter(
						(item:any) => item.bloc_id == 24
					);
					setVisibleAutoContinue(result.length > 0);
				} catch (error) {
					console.error("Error fetching data", error);
				}
			}
		};

		fetchData();
	}, [notice]);

	return visibleAutoContinue;
};

export default useAutoContinue;