import React, { useEffect, useState } from 'react';
import s from './CommentSection.module.css';
import { useTranslation } from 'react-i18next';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../redux/redux-store';
import { getComments } from '../../../redux/notice_Reducer';
import CreateComment from './Components/CreateComment';
import Comment from './Components/Comment';
import ExpandButton from '../../../Components/ExpandButton/ExpandButton';
import Recaptcha from '../../../Components/Recaptcha/Recaptcha';

const CommentSection: React.FC = () => {
  const { t } = useTranslation();
  const [perPage, setPerPage] = useState<number>(20);
  const dispatch: Dispatch = useDispatch();
  const noticeView = useSelector((state: StateType) => state.publicNotice.noticeView);
  const comments = useSelector((state: StateType) => state.noticeData.commentsList);

  useEffect(() => {
    if  (noticeView?.id) {
      getComments({
        noticeId: noticeView.id,
        page: 1,
        perPage
      }, dispatch)
    }
  }, [noticeView, perPage])

  return (
    <Recaptcha>
      <div className={s.wrap}>
        <div className={s.title}>{t("Додати коментар")}</div>
        <CreateComment />

      {comments.length ? <>
        <div className={s.title}>{t("Коментарі до оголошення")}</div>
        <ul className={s.commentsList}>
          {comments.map(({ author_name, comment, created_at, id }) => <li key={id} >
            <Comment
              author={author_name}
              text={comment}
              date={created_at}
            />
          </li>)}
        </ul>
        {comments.length >= perPage ?
          <div className={s.expandButton}>
            <ExpandButton onClick={() => setPerPage(perPage + 20)} />
          </div>
        : null}
      </> : null}
      </div>
    </Recaptcha>
  );
};

export default CommentSection;