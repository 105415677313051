import React, { useEffect, useState } from "react";
import s from "./AutomaticSelectionOfCategories.module.css";
import AutomaticSelectionOfCategoriesTemplate from "./AutomaticSelectionOfCategoriesTemplate";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../redux/redux-store";
import { useParams } from "react-router-dom";
import AutomaticSelectionOfCategoriesSvgSelector from "./AutomaticSelectionOfCategoriesSvgSelector";
import { setClearThirdCategoryList } from "../../redux/category_Reducer";
import { useTranslation } from "react-i18next";
import HeaderSvg from "../Header/HeaderSvg";
import { setOneAutoSelectionChoosing } from "../../redux/autoSelection_Reducer";
import CategorySelectorMobile from "../NewRubricsBoardSelector/CategorySelectorMobile";
import { Desktop, Mobile } from "../../responsive";
import CategorySelectorDesktop from "../NewRubricsBoardSelector/CategorySelectorDesktop";
import { IMainAutomaticSelection } from "../../types/autoSelectionTypes";
import { OneRubricType, RubricTypeOnBoard } from "../../types/categoriesTypes";

interface Props {
	setIsOpen: (value: boolean) => void;
	setFieldValue: any;
	handleChoseCategory:(r:RubricTypeOnBoard | OneRubricType | null) => void;
}

const AutomaticSelectionOfCategoriesModal = ({
	setIsOpen,
	setFieldValue,
	handleChoseCategory
}: Props) => {
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const { noticeId } = useParams();

	const [searchValue, setSearchValue] = useState("");
	const autoSelectionList = useSelector(
		(state: StateType) => state.autoSelection.autoSelectionList
	);



	useEffect(() => {
		dispatch(setClearThirdCategoryList());
	}, []);

	const handleKeyDown = () => {
		setFieldValue("name", searchValue);
		setSearchValue(searchValue);
	};
	const handleSendResoult = () => {
		setFieldValue("name", searchValue);
		setSearchValue(searchValue);
	};

	const handleChangeInput = (e: any) => {
		const value = e.target.value;
		setFieldValue("name", value);
		setSearchValue(value);
	};

	/*const handleChoseRubric = (e: any) => {
		dispatch(setOneAutoSelectionStore(e));
	};*/

	const chooseCategoryInTemplate = (item: IMainAutomaticSelection) => {
		const category:RubricTypeOnBoard = {
			name:item.categoryName,
			alias:item.categoryAlias ?? "",
			id:item?.categoryId?.toString() ?? "",
			iconUrl:"",
			isForNotice:true,
			parent_id:""
		}
		handleChoseCategory(category);
		setOneAutoSelectionChoosing(item)
		setIsOpen(false)
	}
	return (
		<div className={`${s.modalWrap}`}>
			<div className={s.modalContainer}>
				<div className={s.modalContainerTopBlock}>
					<div className={s.mobileHeaderSearchBlock}>
						<div onClick={handleSendResoult} onKeyDown={handleKeyDown}>
							<HeaderSvg id="search" />
						</div>
						<input
							// onFocus={(event) => handleFocusInput(event.currentTarget.value)}
							onKeyDown={handleKeyDown}
							style={{ borderRadius: !open ? "8px 0 0 8px" : "" }}
							onChange={(event) => handleChangeInput(event)}
							type="text"
							placeholder={t("Введіть назву оголошення")}
							value={searchValue}
						/>
					</div>
					<div
						style={{ cursor: "pointer" }}
						onClick={() => setIsOpen && setIsOpen(false)}
					>
						<AutomaticSelectionOfCategoriesSvgSelector id="close" />
					</div>
				</div>
				<div className={s.modalContainerContent}>
					{!!autoSelectionList.length && !noticeId && (
						<div className={s.modalContainerContentTop}>
							<div className={s.modalContainerContentTop_title}>
								<p>{t(`Автопідбір рубрики`)}:</p>
							</div>
							<div className={s.modalContainerContentTop_blocks}>
								{autoSelectionList.map((item: IMainAutomaticSelection) => (
									<div
										key={item?.categoryId}
										/*onClick={() => handleChoseRubric(item)}*/
									>
										<AutomaticSelectionOfCategoriesTemplate
											handleChoseCategory={()=>chooseCategoryInTemplate(item)}
											change={false}
											chose={true}
											categoryName={item?.categoryName}
											names={item?.categoryInfo?.names.slice(0, 3)}
											categoryId={item?.categoryId ?? null}
											image={item?.categoryInfo?.urlIconLevel2}
										/>
									</div>
								))}
							</div>
						</div>
					)}
					<Desktop>
						<CategorySelectorDesktop
							handleChoseCategory={handleChoseCategory}
						/>
					</Desktop>
					<Mobile>
						<CategorySelectorMobile
							handleChoseCategory={handleChoseCategory}
						/>
					</Mobile>
				</div>
			</div>
		</div>
	);
};

export default AutomaticSelectionOfCategoriesModal;
