import { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import OutputADSvgSelector from "../../../Components/OutputBoardAdComponents/OutputADSvgSelector";
import { StateType } from "../../../redux/redux-store";
import { Desktop, Mobile } from "../../../responsive";
import s from "./WorkNotice.module.css";
import { stateUserType } from "../../../types/reduxStoreTypes";
import PublishedTime from "../../../Components/TimeAndDate/PublishedTime";
import SellerCardPopup from "../../../Components/SellerCard/SellerCardPopup";
import FavoriteHeartSelector from "../../../Components/Cards/FavoriteHeartSelector/FavoriteHeartSelector";
import { useNavigate } from "react-router";
import { favoriteHandler } from "../../../redux/publicNotice_Reducer";
import { VacancyType, WorkInfoList } from "./WorkInfoList/WorkInfo";
import CommentSection from "../CommentSection/CommentSection";

type Props = {
	handleOpenLogin?: () => void;
	handleOpenCompainModal?: () => void;

	card?: {
		sellerName: string | null;
		sellerId: string;
		price?: string;
		maxSalary?: string;
		location?: string | null;
		ownerBPData: {
			slug?: string;
			status?: number;
			statusName?: string;
		};
		ownerData?: {
			online: boolean;
			time_last_visit: number | null;
			photoUrl: string | null;
		};
		views: number | null;
		isFavorite: number;
		ownerPhone: string | null;
		time_registration: number | null;
		viber: number;
		whatsapp: number;
		telegram: number;
		reputation: number | null;
		seeNow: number | null;
	} | null | any;
};

const WorkNotice = forwardRef<HTMLDivElement, Props>(({
	handleOpenLogin,
	handleOpenCompainModal,
	card
}, ref) => {
  const { t } = useTranslation();
	
	const navigate = useNavigate();
	
	const dispatch: any = useDispatch();
	
	const [dialogOpen, setDialogOpen] = useState(false);
	const handleDialogOpen = () => {
		/*document.body.classList.add('dialog-open');*/
		setDialogOpen(true);
	};
	const handleDialogClose = () => {
		setDialogOpen(false);
	};
	
	const addDelInFavorite = useSelector(() => favoriteHandler);
	const isFavoriteHandler = (action: string) => {
		dispatch(addDelInFavorite(noticeView?.id || 0, action));
	};
	
	const auth = useSelector((state: stateUserType) => state.userData.isAuth);
	const noticeView = useSelector((state: StateType) => state.publicNotice.noticeView);
	const userView = useSelector((state: StateType) => state.userData.user);

	type PropertyType = {
		filterPosition: string,
		propertyName: string,
		propertyValues: string
	}
	const info = {
		title: noticeView?.name,
		description: noticeView?.description,
		vacancyType: noticeView?.noticeType?.vacancyType,
		workLocation: noticeView?.settlement_name,
		properties: noticeView?.notice_properties as PropertyType[],
		views: noticeView?.views,
		logo: noticeView?.noticeImages?.[0],
    users_id: noticeView?.users_id,
    id: noticeView?.id,
    isFavorite: noticeView?.isFavorite,
    seeNow: noticeView?.seeNow
	}

	const { title, description, vacancyType, properties, views, logo,
		users_id, id, isFavorite, seeNow } = info;

	const sellerInfo = {
		name: card?.sellerName || "",
		sellerId: card?.sellerId || "",
		ownerPhone: card?.ownerPhone || "",
		time_registration: card?.time_registration || 0,
		viber: card?.viber || 0,
		whatsapp: card?.whatsapp || 0,
		telegram: card?.telegram || 0,
		time_last_visit: card?.ownerData?.time_last_visit || 0,
		reputation: card?.reputation || null,
		ownerImage: card?.ownerData?.photoUrl || "",
		ownerBPData: card?.ownerBPData,
	};

  return (
	<>
		<Desktop>
			<div className={s.workContent}>
				<div className={s.workContentLeft}>
					<div className={s.workTop}>
						<div className={s.workTitle}>{title}</div>
						<div className={s.heart}>
							{card?.sellerId && +card?.sellerId !== userView.id && (
								<>
									{card?.isFavorite.toString() === "1" ? (
										<button
											onClick={() => {
												isFavoriteHandler("remove");
											}}
										>
											<OutputADSvgSelector id={"yellowHeart"} />
										</button>
									) : (
										<button
											onClick={() => {
												isFavoriteHandler("add");
											}}
										>
											<OutputADSvgSelector id={"heart"} />
										</button>
									)}
								</>
							)}
						</div>
					</div>

					<WorkInfoList notice={noticeView} />

					{properties?.length ?
						<ul className={s.properties}>
							{properties.map((p: PropertyType) => 
								<li key={p.propertyValues}>{p.propertyValues}</li>
							)}
						</ul>
					: null}

					{description && (
						<div className={s.description}>
							{description}
						</div>
					)}

					<div className={s.workBottom}>
						{vacancyType && (
							<VacancyType type={vacancyType} />
						)}
						<span>
							ID: {noticeView.id}
						</span>
						<span className={s.vies}>
							<OutputADSvgSelector id="eye" /> {views}
						</span>
						{auth ? (
							<div
								className={s.warning}
								onClick={handleOpenCompainModal}
							>
								<OutputADSvgSelector id={"warningComplain"} />
								{t("Поскаржитись")}
							</div>
						) : (
							<div className={s.warning} onClick={handleOpenLogin}>
								<OutputADSvgSelector id={"warningComplain"} />
								{t("Поскаржитись")}
							</div>
						)}
					</div>
				</div>

				<div className={s.workContentRight}>
					<div className={s.owner}>
						<h4
							className={card?.ownerData?.online ? s.onLineIndicator : ""}
							onClick={handleDialogOpen}
						>
							{card?.sellerName || ""}
						</h4>
						{!card?.ownerData?.online && (
							<div className={s.seeNow}>
								{t("Останній візит")}{" "}
								<PublishedTime
									time={card?.ownerData?.time_last_visit || 0}
								/>
							</div>
						)}
					</div>
					
					<div className={s.logoWrap}>
						{logo &&
							<img className={s.logo} src={logo.imageUrl} alt='logo'/>
						}
					</div>

					{card?.seeNow && (
						<div className={s.seeNow}>
								{t("Зараз дивляться")}: {card.seeNow}
						</div>
					)}

					<div className={s.buttonWrapper}>
						<button onClick={handleDialogOpen}>
							<OutputADSvgSelector id={"phone"} />
							<span>{t("Подзвонити")}</span>
						</button>
					</div>
				</div>

				
				{dialogOpen && (
					<SellerCardPopup
						sellerInfo={sellerInfo}
						dialogOpen={dialogOpen}
						dialogClose={handleDialogClose}
					/>
				)}
			</div>
			
			<div className={s.comments}>
				<CommentSection/>
			</div>
		</Desktop>
		<Mobile>
			<div ref={ref}>
				<div className={s.workTopMobile}>
					<button className={s.buttonGrey} onClick={() => navigate(-1)}>
						<OutputADSvgSelector id={"backMobileArrow"} />
					</button>
					{vacancyType && (
						<VacancyType type={vacancyType}/>
					)}
				</div>
				<div className={s.workHeadMobile}>
					<div className={s.workTitleWrap}>
						<h4 className={`${s.workTitle} ${s.mobile}`}>{title}</h4>
						{users_id && +users_id !== userView.id && (
							<FavoriteHeartSelector
								isFavorite={isFavorite || 0}
								id={id || 0}
								userId={users_id || 0}
								isOrange={true}
							/>
						)}
					</div>

					{logo &&
						<div className={s.logoWrap}>
							<img className={s.logo} src={logo.imageUrl} alt='logo'/>
						</div>
					}

					{seeNow && (
						<div className={s.seeNowMobile}>
								{t("Зараз дивляться")}: {seeNow}
						</div>
					)}
				</div>

				<WorkInfoList notice={noticeView} />

				<div className={s.workContentMobile}>
					{properties?.length ?
						<ul className={`${s.properties} ${s.mobile}`}>
							{properties.map((p: PropertyType) =>
								<li key={p.propertyValues}>{p.propertyValues}</li>
							)}
						</ul>
					: null}

					<div className={`${s.workTitle} ${s.mobile}`}>{t("Опис вакансії")}</div>
					<div className={s.descriptionMobile}>{description}</div>
				</div>
			</div>
		</Mobile>
	</>
  );
});

export default WorkNotice;