import React from "react";
import s from "./LanguageSelector.module.css";
import { changeLanguage } from "i18next";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Desktop, Mobile } from "../../responsive";

interface Props {
	open?: boolean;
	setOpenLanguage?: (d: boolean) => void;
}

const LanguageSelector: React.FC<Props> = ({ open, setOpenLanguage }) => {
	const location = useLocation();
	const { t, i18n } = useTranslation();
	const currentLanguage = i18n.language;
	const pathname = location.pathname;

	// Проверка, начинается ли путь с "/ua"
	const isUkLanguageInUrl = pathname.startsWith("/ua");


	const handleLanguageChange = (lang: string) => {
		if (currentLanguage === lang) return;

		changeLanguage(lang);
		setOpenLanguage?.(false);

		const updatedPathname = lang === "uk"
			? isUkLanguageInUrl
				? pathname
				: `/ua${pathname}`
			: isUkLanguageInUrl
				? pathname.replace(/^\/ua/, "")
				: pathname;

		window.history.replaceState({}, "", updatedPathname);
	};

	// Определяем отображаемый язык
	const displayedLanguage = currentLanguage === "uk" ? "ua" : currentLanguage;

	const languagesInProject = ["uk","ru" ]
	return (
		<>
			<Desktop>
				<div
					onClick={() => setOpenLanguage?.(!open)}
					className={s.container}
				>
					<h5>{displayedLanguage}</h5>
					<svg
						width="7"
						height="3"
						viewBox="0 0 7 3"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M3.5 3L6.53109 0H0.468911L3.5 3Z" fill="#939393" />
					</svg>
					{open && (
						<div className={s.languageSelectPopupTop}>
							<div className={s.popupWrapper} />
							{languagesInProject.map((lang) => (
								<span
									key={lang}
									className={currentLanguage === lang ? s.active : ""}
									onClick={() => handleLanguageChange(lang)}
								>
                   {lang === "uk" ? "ua" : lang}
                </span>
							))}
						</div>
					)}
				</div>
			</Desktop>
			<Mobile>
				<div className={s.switcherContainer}>
					<div className={s.switcherTitle}>{t("Мова")}</div>
					<div className={s.switcherButtonsContainer}>
						{languagesInProject.map((lang) => (
							<button
								key={lang}
								className={
									currentLanguage !== lang
										? s.switcherButtonActive
										: s.switcherButton
								}
								onClick={() => handleLanguageChange(lang)}
							>

								{lang === "uk" ? "ua".toUpperCase() : lang.toUpperCase()}
							</button>
						))}
					</div>
				</div>
			</Mobile>
		</>
	);
};

export default LanguageSelector;
