import React, { useState, useEffect } from "react";
import s from "./AdCard.module.css";
import NoRegisterSvgSelector from "./NoRegisterSvgSelector";
import { useTranslation } from "react-i18next";
import handleSortPhrases from "./sortPhrases";
import {
	colorArray,
	noRegisterPhrasesArray,
} from "../../data_arrays/BannersData";
import { setOpenRegAuthPopup } from "../../redux/user_Reducer";
import { useDispatch } from "react-redux";

interface InterfacePhrasesArray {
	text: string;
	author: string;
}

const NoRegisterAdCard = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [randomColor, setRandomColor] = useState("");
	const [randomPhrases, setRandomPhrases] =
		useState<InterfacePhrasesArray | null>(null);

	useEffect(() => {
		const sortResoult = handleSortPhrases(
			noRegisterPhrasesArray,
			"noAuthBagoPhrasesArray"
		);
		setRandomPhrases(sortResoult);

		const randomColorIndex = Math.floor(Math.random() * colorArray.length);
		setRandomColor(colorArray[randomColorIndex]);
	}, []);

	const handleOpenLogin = () => {
		dispatch(setOpenRegAuthPopup(true, "auth"));
	};

	const handleOpenRegistration = () => {
		dispatch(setOpenRegAuthPopup(true, "reg"));
	};

	return (
		<div className="__container">
			{randomColor && (
				<div className={`${s.container} `}>
					<div style={{ background: `#${randomColor}`, borderRadius: "5px" }}>
						<div className={s.cardContainer}>
							<div className={s.logo}>
								<NoRegisterSvgSelector id={"logoBago"} />
							</div>
							<div className={s.content}>
								<div className={s.contentDescriptionWrap_noRegister}>
									<p className={s.contentDescription}>
										{randomPhrases && t(`${randomPhrases?.text}`)}
									</p>
								</div>
								<div className={s.contentAuthorWrap}>
									<p className={s.contentDescriptionAuthor}>
										{randomPhrases && t(`${randomPhrases?.author}`)}
									</p>
								</div>
							</div>
							<div className={s.buttons}>
								<button className={s.buttonEnter} onClick={handleOpenLogin}>
									{t("вхід")}
								</button>
								<button
									className={s.registration}
									onClick={handleOpenRegistration}
								>
									{t("Реєстрація")}
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default NoRegisterAdCard;
