import React, { useEffect, useState } from "react";
import s from "./Registration&AuthForms.module.css";
import { useNavigate } from "react-router";
import FastEnter from "./FastEnter";
import FormRegAuth from "./FormReg&Auth";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ArrowsAndCross from "../../common/ArrowsAndCross/ArrowsAndCross";
import { setOpenRegAuthPopup } from "../../redux/user_Reducer";
import { StateType } from "../../redux/redux-store";
import Recaptcha from "../Recaptcha/Recaptcha";

const MobileRegistrationAuthForms = () => {
	const [isAuth, setIsAuth] = useState(true); //this state managed Reg Popup type (auth or reg)
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const navigate: any = useNavigate();
	const [errorApple, setErrorApple] = useState("");
	const openRegAuthPopupInRegType = useSelector(
		(state: StateType) => state.userData.openRegAuthPopupInRegType
	);
	const dialogClose = () => {
		dispatch(setOpenRegAuthPopup(false));
	};
	useEffect(() => {
		if (openRegAuthPopupInRegType === "auth") {
			setIsAuth(true);
		} else if (openRegAuthPopupInRegType === "reg") {
			setIsAuth(false);
		} else {
			setIsAuth(true);
		}
	}, [openRegAuthPopupInRegType, dispatch]);

	return (
		<Recaptcha>
			<div className={s.mobileContainer}>
				<div className={s.prewArrow}>
					<span onClick={() => navigate(-1)}>
						<ArrowsAndCross id={"backArrowAuth"} />
					</span>
				</div>
				<div className={s.titlePanelButton}>
					<h5
						onClick={() => setIsAuth(true)}
						className={isAuth ? s.active : ""}
					>
						{t("вхід")}
					</h5>
					<h5
						onClick={() => setIsAuth(false)}
						className={!isAuth ? s.active : ""}
					>
						{t("Реєстрація")}
					</h5>
				</div>
				<FastEnter
					errorApple={errorApple}
					setErrorApple={setErrorApple}
					closeModal={dialogClose}
				/>
				<div className={s.borderSimulation}>
					<span className={s.leftLine} />
					<span className={s.orElement}>{t("або")}</span>
					<span className={s.rightLine} />
				</div>
				<FormRegAuth
					isAuth={isAuth}
					setIsAuth={setIsAuth}
				/>
			</div>
			{errorApple !== "" && errorApple !== "popup_closed_by_user" && (
				<div className={s.socialNetworkErrorPopupWrapper}>
					<div className={s.socialNetworkErrorPopupContainer}>
						<div onClick={() => setErrorApple("")}>
							<ArrowsAndCross
								id={"closeCross"}
								color={"rgba(114, 114, 114, 1)"}
							/>
						</div>
						<div>{errorApple}</div>
					</div>
				</div>
			)}
		</Recaptcha>
	);
};

export default MobileRegistrationAuthForms;
