import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import {
	GuaranteeConditions,
	Payment,
	ReturnConditions,
	SetManageActivityBlock,
} from "../../../../../../api/business-page/types";
import {
	useSetPayAndGuaranteeGuaranteeConditions,
	useSetPayAndGuaranteeReturnConditions,
} from "../../../../../../CustomHooks/api/business-page/useBusinessPage";
import Checkbox from "../../Checkbox/Checkbox";
import { CheckboxesBlock } from "../../CheckboxesBlock/CheckboxesBlock";
import styles from "../SellerInfo.module.css";
import { FormSeller } from "../types";

type Props = {
	isEditPaymentSeller: boolean;
	setPayAndGuaranteeSetPayment: (values: any) => void;
	handleCheckBox: (name: string, checked: boolean, setFieldValue: any) => void;
	payment: Payment;
	guaranteeConditions: GuaranteeConditions;
	returnConditions: ReturnConditions;
};

export const PaymentAndGuaranteeSection = ({
	isEditPaymentSeller,
	setPayAndGuaranteeSetPayment,
	payment,
	guaranteeConditions,
	returnConditions,
}: Props) => {
	const { t } = useTranslation();
	const { values, setFieldValue } = useFormikContext<FormSeller>();

	const [returnCondition, setReturnCondition] = useState(
		values.returnConditionsVar4 || ""
	);
	const [returnConditionChecked, setReturnConditionChecked] = useState(
		!!values.returnConditionsVar4
	);

	const [guaranteeCondition, setGuaranteeCondition] = useState(
		values.guaranteeConditionsVar5 || ""
	);
	const [guaranteeConditionChecked, setGuaranteeConditionChecked] = useState(
		!!values.guaranteeConditionsVar5
	);

	const { mutate: setPayAndGuaranteeReturnConditions } =
		useSetPayAndGuaranteeReturnConditions("");
	const { mutate: setPayAndGuaranteeGuaranteeConditions } =
		useSetPayAndGuaranteeGuaranteeConditions("");

	return (
		<div>
			<CheckboxesBlock
				title="Оплата"
				isEdit={isEditPaymentSeller}
				values={{
					var1: Number(values.paymentVar1),
					var2: Number(values.paymentVar2),
					var3: Number(values.paymentVar3),
					var4: Number(values.paymentVar4),
					var5: Number(values.paymentVar5),
				}}
				mutate={setPayAndGuaranteeSetPayment}
				content={
					isEditPaymentSeller ? (
						<>
							<Checkbox
								checked={values.paymentVar1}
								onChange={(checked) => {
									setFieldValue("paymentVar1", checked);
								}}
								label={t("На карту Приватбанка")}
							/>
							<Checkbox
								checked={values.paymentVar2}
								onChange={(checked) => {
									setFieldValue("paymentVar2", checked);
								}}
								label={t("Накладений платіж")}
							/>
							<Checkbox
								label={t("Безпечна угода BAGO")}
								checked={values.paymentVar3}
								onChange={(checked) => {
									setFieldValue("paymentVar3", checked);
								}}
							/>
							<Checkbox
								label={t("На карту Монобанка")}
								checked={values.paymentVar4}
								onChange={(checked) => {
									setFieldValue("paymentVar4", checked);
								}}
							/>
							<Checkbox
								label={t("По реквізитам (рахунок фактура)")}
								checked={values.paymentVar5}
								onChange={(checked) => {
									setFieldValue("paymentVar5", checked);
								}}
							/>
						</>
					) : (
						<div>
							{Object.entries(payment)
								.filter(([key, option]) => option.value === "1")
								.map(([key, option]) => (
									<p key={key}>{t(`${option.title}`)}</p>
								))}
						</div>
					)
				}
				bgColor="#FAFAFA"
			/>

			<CheckboxesBlock
				title={t("Умови гарантії")}
				isEdit={isEditPaymentSeller}
				values={{
					var1: Number(values.guaranteeConditionsVar1),
					var2: Number(values.guaranteeConditionsVar2),
					var3: Number(values.guaranteeConditionsVar3),
					var4: Number(values.guaranteeConditionsVar4),
					var5: guaranteeConditionChecked ? guaranteeCondition.toString() : "",
				}}
				mutate={setPayAndGuaranteeGuaranteeConditions}
				content={
					isEditPaymentSeller ? (
						<>
							<Checkbox
								checked={values.guaranteeConditionsVar1}
								onChange={(checked) => {
									setFieldValue("guaranteeConditionsVar1", checked);
								}}
								label={t("14 днів")}
							/>
							<Checkbox
								checked={values.guaranteeConditionsVar2}
								onChange={(checked) => {
									setFieldValue("guaranteeConditionsVar2", checked);
								}}
								label={t("1 міс.")}
							/>
							<Checkbox
								label={t("2 роки")}
								checked={values.guaranteeConditionsVar3}
								onChange={(checked) => {
									setFieldValue("guaranteeConditionsVar3", checked);
								}}
							/>
							<Checkbox
								label={t("Гарантійний строк вказано в картці товару")}
								checked={values.guaranteeConditionsVar4}
								onChange={(checked) => {
									setFieldValue("guaranteeConditionsVar4", checked);
								}}
							/>
							<Checkbox
								label={"Власний варіант"}
								checked={guaranteeConditionChecked}
								value={guaranteeCondition.toString()}
								onChange={(checked) => {
									setGuaranteeConditionChecked(checked);
									if (!checked) {
										setGuaranteeCondition("");
										setFieldValue("guaranteeConditionsVar5", "");
									} else {
										setFieldValue(
											"guaranteeConditionsVar5",
											guaranteeCondition
										);
									}
								}}
								onChangeText={(value: string) => {
									setGuaranteeCondition(value);
									if (guaranteeConditionChecked) {
										setFieldValue("guaranteeConditionsVar5", value);
									}
								}}
								placeholder={t("Опишіть свої умови гарантії")}
							/>
						</>
					) : (
						<div>
							{guaranteeConditions.var5.value !== "0" &&
							guaranteeConditions.var5.value !== "1"
								? guaranteeConditions.var5.value
								: null}
							{Object.entries(guaranteeConditions)
								.filter(([key, option]) => option.value === "1")
								.map(([key, option]) => (
									<p key={key}>
										{/* {option.title} */}
										{t(`${option.title}`)}
									</p>
								))}
						</div>
					)
				}
			/>
			<CheckboxesBlock
				title={t("Умови повернення")}
				isEdit={isEditPaymentSeller}
				mutate={setPayAndGuaranteeReturnConditions}
				values={{
					var1: Number(values.returnConditionsVar1),
					var2: Number(values.returnConditionsVar2),
					var3: Number(values.returnConditionsVar3),
					var4: returnConditionChecked ? returnCondition.toString() : "",
				}}
				content={
					isEditPaymentSeller ? (
						<>
							<Checkbox
								checked={values.returnConditionsVar1}
								onChange={(checked) => {
									setFieldValue("returnConditionsVar1", checked);
								}}
								label={t("На протязі 14 днів")}
							/>
							<Checkbox
								checked={values.returnConditionsVar2}
								onChange={(checked) => {
									setFieldValue("returnConditionsVar2", checked);
								}}
								label={t("Зворотня доставка за рахунок покупця")}
							/>
							<Checkbox
								label={t(
									"Зберігайте оригінальну упаковку, якщо така упаковка передбачена"
								)}
								checked={values.returnConditionsVar3}
								onChange={(checked) => {
									setFieldValue("returnConditionsVar3", checked);
								}}
							/>
							<Checkbox
								label={"Власний варіант"}
								checked={returnConditionChecked}
								value={returnCondition.toString()}
								onChange={(checked) => {
									setReturnConditionChecked(checked);
									if (!checked) {
										setReturnCondition("");
										setFieldValue("returnConditionsVar4", "");
									} else {
										setFieldValue("returnConditionsVar4", returnCondition);
									}
								}}
								onChangeText={(value: string) => {
									setReturnCondition(value);
									if (returnConditionChecked) {
										setFieldValue("returnConditionsVar4", value);
									}
								}}
								placeholder={t("Опишіть свої умови повернення")}
							/>
						</>
					) : (
						<div>
							{returnConditions.var4.value !== "0" &&
							returnConditions.var4.value !== "1"
								? returnConditions.var4.value
								: null}
							{Object.entries(returnConditions)
								.filter(([key, option]) => option.value === "1")
								.map(([key, option]) => <p key={key}>{t(`${option.title}`)}</p>)
								.reverse()}
						</div>
					)
				}
				bgColor="#FAFAFA"
			/>
		</div>
	);
};
