import s from "./FavoriteSearchCard.module.css";
import SearchCardSvgSelector from "./SearchCardSvgSelector";
import FavoriteSearchCardPopup from "./FavoriteSearchCardPopup";
import {
	publicOneNotice,
	TreeLinksType,
} from "../../../types/publicNoticeTypes";
import PublishedTime from "../../TimeAndDate/PublishedTime";
import { useNavigate } from "react-router";
import FavoriteHeartSelector from "../FavoriteHeartSelector/FavoriteHeartSelector";
import { formattingPrice } from "../../../common/Logic/supporting";
import { useTranslation } from "react-i18next";
import WorkFavoriteSearchCardRow from "./WorkFavoriteSearchCardRow";

const FavoriteSearchCardRow = ({ notice }: { notice: publicOneNotice }) => {
	const noticeTree = notice?.tree?.map((s: TreeLinksType) => s.alias);
	const noticeUrl = noticeTree?.join("/");
	const navigate = useNavigate();
	const { i18n, t } = useTranslation();
	const currentLanguage = i18n.language === "ru" ? "" : "/ua";
	const isWorkNotice = notice.noticeType?.value === 1;

	return (
		<div className={s.cardRowContainer}>
			{!isWorkNotice ? (<>
				{notice?.noticeImages.length ? (
					<div
						className={s.image}
						onClick={() =>
							//navigate(`/o/${noticeUrl ? noticeUrl +"/" : ""}${notice?.slug}`)
							navigate(
								`${currentLanguage}/o/${noticeUrl ? noticeUrl + "/" : ""}${notice?.slug}`
							)
						}
					>
						<img
							className={`${s.image}`}
							loading={"lazy"}
							src={notice?.noticeImages[0]?.originalImageUrl}
							alt={notice?.slug || "image"}
						/>
					</div>
				) : null}
				<div className={s.info}>
					<div
						className={s.infoTitle}
						onClick={() =>
							//navigate(`/o/${noticeUrl ? noticeUrl +"/" : ""}${notice?.slug}`)
							navigate(
								`${currentLanguage}/o/${noticeUrl ? noticeUrl + "/" : ""}${notice?.slug}`
							)
						}
					>
						<h4>{notice?.name || ""}</h4>
					</div>
					<div className={s.infoDescription}>
						<p>{notice?.description || ""}</p>
					</div>
					<div className={s.infoLocation}>
						<span>
							<SearchCardSvgSelector id={"location"} />
						</span>
						<span>{notice?.short_settlement_name || ""}</span>
					</div>
				</div>
				<div className={s.panel}>
					<div className={s.panelTop}>
						<span>
							<FavoriteHeartSelector
								id={notice?.id || 0}
								isFavorite={notice?.isFavorite || 0}
								userId={notice?.users_id || 0}
							/>
						</span>
						<span>
							{notice?.price && notice.price !== "0.00"
								? formattingPrice(notice?.price)
								: t("Безкоштовно")}
							<span>
								{notice?.price && notice.price !== "0.00" ? " грн" : ""}
							</span>
						</span>
					</div>

					<div className={s.panelBottom}>
						<span className={s.dateInfo}>
							<PublishedTime time={notice?.published_at || 0} />
						</span>
						<span className={s.hiddenInfo}>
							<span className={s.menuButton}>
								<span className={s.dayInfo}>
									<PublishedTime time={notice?.published_at || 0} />
								</span>
								{/* <SearchCardSvgSelector id={"delCardButton"}/>*/}
								<FavoriteSearchCardPopup />
							</span>
						</span>
					</div>
				</div>
			</>) : (
				<WorkFavoriteSearchCardRow notice={notice} />
			)}
		</div>
	);
};

export default FavoriteSearchCardRow;
