import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { publicNotice } from "../../../../../api/api";
import ProductCardGrid from "../../../../../Components/Cards/FavoriteSearchCard/ProductCardGrid";
import MobileProductCard from "../../../../../Components/Cards/ProductCard/MobileProductCard";
import ProductCardHorizontalForSearchMobile from "../../../../../Components/Cards/ProductCard/ProductCardHorizontalForSearchMobile";
import Paginator from "../../../../../Components/Paginator/Paginator";
import PreLoaderComponent from "../../../../../common/Animations/PreLoaderComponent/PreLoaderComponent";
import { useGetNoticesByUserId } from "../../../../../CustomHooks/api/business-page/useBusinessPage";
import { StateType } from "../../../../../redux/redux-store";
import { Desktop, Mobile } from "../../../../../responsive";
import { publicOneNotice } from "../../../../../types/publicNoticeTypes";
import { NoticeCardRow } from "../NoticeCardRow/NoticeCardRow";
import s from "./SellerInfo.module.css";

type Props = {
	businessPageUserId: number;
	grid3: boolean;
	row: boolean;
	sort: string;
	categoryId: number;
	catIds: number[];
};

export const SellerAds = ({
	businessPageUserId,
	grid3,
	row,
	sort,
	categoryId,
	catIds,
}: Props) => {
	const { t } = useTranslation();
	const userView = useSelector((state: StateType) => state.userData.user);


	const [noticesList, setNoticesList] = useState<publicOneNotice[]>([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [hasMore, setHasMore] = useState(true);

	const queryClient = useQueryClient();

	const { data, isLoading, isError, error, isPlaceholderData } =
		useGetNoticesByUserId({
			userId: businessPageUserId ?? userView.id,
			perPage: 48,
			page: currentPage,
			sortType: sort,
			category_id: categoryId === 0 ? undefined : categoryId,
			catIds: catIds[0] === 0 ? undefined : catIds,
		});

	useEffect(() => {
		if (data) {
			setNoticesList(data.items || []);
			setHasMore(data._meta.currentPage < data._meta.pageCount);
		}
		// scrollToTop();
	}, [data]);

	useEffect(() => {
		if (!isPlaceholderData && hasMore) {
			queryClient.prefetchQuery({
				queryKey: ["get/noticesByUserId", currentPage + 1],
				queryFn: async () =>
					publicNotice.getAllNoticesByUserId(
						businessPageUserId ?? userView.id,
						3,
						currentPage + 1
					),
			});
		}
	}, [data, isPlaceholderData, currentPage, hasMore]);

	const renderNotices = useMemo(() => {
		if (row) {
			return noticesList.length > 0 ? (
				noticesList.map((notice) => (
					<div key={notice.id} id="all">
						<Desktop>
							<NoticeCardRow 
								notice={notice} 
								sort={sort}
								page={currentPage}
								perPage={48}
							/>
							<hr className={s.line} />
						</Desktop>
					</div>
				))
			) : (
				<Desktop>
					<p id="all" className={s.noAds}>
						{t("У цього продавця немає жодних оголошень")}
					</p>
				</Desktop>
			);
		} else if (grid3) {
			return (
				<div
					id="all"
					className={`${s.resultCardsContainer} ${s.grid4}`}
					style={{ flexDirection: "row", flexWrap: "wrap" }}
				>
					{noticesList.map((notice) => (
						<div key={notice.id} className={s.grid3Container}>
							<Desktop>
								<ProductCardGrid grid={"3"} notice={notice} 
								sort={sort}
								page={currentPage}
								perPage={48}
								/>
							</Desktop>
						</div>
					))}
				</div>
			);
		}
	}, [row, grid3, noticesList, sort, currentPage]);

	return (
		<>
			{isLoading ? (
				<PreLoaderComponent type={"category"} size={"medium"} />
			) : null}
			{isLoading && currentPage === 1 && (
				<PreLoaderComponent type={"category"} size={"medium"} />
			)}
			{isError && (
				<p>
					{t("Error")}: {error.message}
				</p>
			)}
			{renderNotices}

			{row ? (
				<div id="all" className={s.cardsContainerRow}>
					<Mobile>
						{data
							? data.items.map((notice: publicOneNotice) => (
									<ProductCardHorizontalForSearchMobile
										key={notice.id}
										notice={notice}
										isRow
									/>
								))
							: null}
					</Mobile>
				</div>
			) : (
				<div id="all" className={s.bestsellersContainerTopPanel}>
					<Mobile>
						{data
							? data.items.map((i: publicOneNotice) => (
									<MobileProductCard notice={i} key={i.id} />
								))
							: null}
					</Mobile>
				</div>
			)}

			{/* {grid3 && (
				<div id="all" className={s.bestsellersContainerTopPanel}>
					<Mobile>
						{notices
							? notices.map((i) => <MobileProductCard notice={i} key={i.id} />)
							: null}
					</Mobile>
				</div>
			)} */}

			{data?._meta?.totalCount > 48 && (
				<div className={s.paginatorContainer}>
					<Paginator
						page={currentPage}
						count={data._meta.pageCount}
						setPage={setCurrentPage}
					/>
				</div>
			)}

			{/* <Mobile>
				<div className={s.paginatorContainer}>
					{data?.length === 0 ? (
						<p className={s.noAds}>
							У цього продавця наразі немає жодних оголошень.
						</p>
					) : null}
					{data && data.length > 0 && hasNextPage ? (
						<ExpandModernButton
							onClickCallback={() => {
								loadMore();
							}}
							disabled={isFetchingNextPage}
						/>
					) : null}
					{isLoading && currentPage > 1 && <p>{t("Loading...")}</p>}
				</div>
			</Mobile> */}
		</>
	);
};