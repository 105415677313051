import React, {  useState } from "react";
import s from "../Category/CategoryBoardSecondLevel/CategoryBoardFirstLevel.module.css";
import RubricsSvgSelector from "../NewRubricsBoardSelector/RubricsSvgSelector";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../redux/redux-store";
import {
	setThorCategoryList,
	setClearThirdCategoryList,
} from "../../redux/category_Reducer";
import AutomaticSelectionOfCategoriesSvgSelector from "./AutomaticSelectionOfCategoriesSvgSelector";
import { OneRubricType } from "../../types/categoriesTypes";

type Props = {
	currentCategoryInfo: { name: string; alias: string; id: number | null };
	thirdStepElements: OneRubricType[];
	handleClick: (r: any) => void;
};

const AutomaticSelectionOfCategoriesLevelThreeAndFour = ({
	currentCategoryInfo,
	thirdStepElements,
	handleClick,
}: Props) => {
	const dispatch: any = useDispatch();
	const [activeID, setActiveID] = useState<number | null>(null);
	const thorStepElements = useSelector(
		(state: StateType) => state.categoryData.thorLevelCategories
	);

	return (
		<div className={s.subCotegoriesContainer}>
			<div className={s.categoriesTop}>
				<div className={s.categoriesTopTitle}>
					{currentCategoryInfo?.name || ""}
				</div>
				<div
					onClick={() => dispatch(setClearThirdCategoryList())}
					style={{ cursor: "pointer" }}
				>
					<AutomaticSelectionOfCategoriesSvgSelector id="close" />
				</div>
			</div>
			<div className={s.categoriesRow}>
				<ul className={s.categoriesColumn}>
					{thirdStepElements.map((r: OneRubricType) => {
						return (
							<li
								key={r.id}
								className={`${s.categoriesItem} ${r.id === activeID ? s.active : ""}`}
								onClick={() => handleClick(r)}
								onMouseEnter={() => {
									dispatch(setThorCategoryList(r.id));
									setActiveID(r.id);
								}}
							>
								<span className={s.name}>{r.name}</span>
								{r.isForNotice !== "0" ? (
									""
								) : (
									<RubricsSvgSelector id={"arrow"} />
								)}
							</li>
						);
					})}
				</ul>
				<ul className={`${s.categoriesColumn} ${s.inAutoSelection}`}>
					{thorStepElements.map((r: OneRubricType) => {
						return (
							<li
								key={r.id}
								onClick={() => handleClick(r)}
								className={s.categoriesItem}
							>
								<span className={s.name}>{r.name}</span>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};

export default AutomaticSelectionOfCategoriesLevelThreeAndFour;
