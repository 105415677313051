import {  useState } from "react";
import { useTranslation } from "react-i18next";
import {  useSelector } from "react-redux";
import CreateBoardADSvg from "../../pages/CreateBoardAD/Components/CreateBoardADSvg";
import { StateType } from "../../redux/redux-store";
import s from "./NewRubricsBoardSelectorMobile.module.css";
import { OneRubricType, RubricTypeOnBoard } from "../../types/categoriesTypes";

interface Props {
	handleChoseCategory:(r:RubricTypeOnBoard | OneRubricType) => void;
}
const CategorySelectorMobile = ({ handleChoseCategory }: Props) => {
	const { t } = useTranslation();
	const [firstStepElements, setFirstStepElements] = useState([]);
	const [secondStepElements, setSecondStepElements] = useState([]);
	const [thirdStepElements, setThirdStepElements] = useState([]);
	const [firstCategoryName, setFirstCategoryName] = useState("");
	const [secondCategoryName, setSecondCategoryName] = useState("");
	const [openList, setOpenList] = useState(0);
	const arrayRubrics = useSelector(
		(state: StateType) => state.categoryData.allRubrics
	);

	const handleFirstStep = (rubric: OneRubricType) => {
		const data = arrayRubrics.level2.filter(
			(r2: OneRubricType) => r2.parent_id === rubric?.id
		);
		setFirstCategoryName(rubric?.name);
		setFirstStepElements(data);
		setSecondStepElements([]);
		setThirdStepElements([]);
		handleChoseCategory(rubric)
		//checkedLastCategory(rubric?.id, rubric?.name, +rubric?.isForNotice);
	};

	const handleSecondStep = (rubric: OneRubricType) => {
		const data = arrayRubrics.level3.filter(
			(r2: OneRubricType) => r2.parent_id === rubric?.id
		);
		setSecondCategoryName(rubric?.name);
		setSecondStepElements(data);
		setThirdStepElements([]);
		handleChoseCategory(rubric)
		//checkedLastCategory(rubric?.id, rubric?.name, +rubric?.isForNotice);
	};
	const handleThirdStep = (rubric: OneRubricType) => {
		const data = arrayRubrics.level4.filter(
			(r2: OneRubricType) => r2.parent_id === rubric?.id
		);
		setThirdStepElements(data);
		handleChoseCategory(rubric)
		//checkedLastCategory(rubric?.id, rubric?.name, +rubric?.isForNotice);

		if (openList == rubric?.id) {
			setOpenList(0);
		} else {
			setOpenList(rubric?.id);
		}
	};

	if (secondStepElements.length) {
		return (
			<div className={s.choseRubricModalWrap}>
				<div
					className={s.choseRubricModalSecondHeader}
					onClick={() => setSecondStepElements([])}
				>
					<CreateBoardADSvg id="arrowBack" />
					<p>{secondCategoryName}</p>
				</div>
				<div className={s.thirdWindowWrap}>
					{secondStepElements.map((item: OneRubricType) => (
						<div key={item?.id}>
							<div
								className={s.compainListItemWrap}
								onClick={() => handleThirdStep(item)}
							>
								<p className={s.compainListItem_text}>{item.name}</p>
								<div
									className={`${s.arrowSvg} ${
										openList == item.id && s.arrowSvgActive
									}`}
								>
									{item?.isForNotice !== "1" && (
										<CreateBoardADSvg id="arrowDown" />
									)}
								</div>
							</div>
							<div>
								{openList == item.id &&
									thirdStepElements.map((list: OneRubricType) => (
										<div
											key={list.id}
											className={s.openListItemWrap}
											onClick={() =>handleChoseCategory(list)}>
											<p className={s.openListItem}>{list.name}</p>
										</div>
									))}
							</div>
						</div>
					))}
				</div>
			</div>
		);
	}

	if (firstStepElements.length) {
		return (
			<div className={s.choseRubricModalWrap}>
				<div
					className={s.choseRubricModalSecondHeader}
					onClick={() => setFirstStepElements([])}
				>
					<CreateBoardADSvg id="arrowBack" />
					<p>{firstCategoryName}</p>
				</div>
				<div className={s.choseRubricModalContainer}>
					<div className={s.choseRubricModalContent}>
						<div className={s.secondCategoryMobileWrap}>
							{firstStepElements.map((item: OneRubricType) => (
								<div
									className={s.secondCategoryMobileItemBlock}
									key={item.id}
									onClick={() => handleSecondStep(item)}
								>
									<div className={s.secondCategoryMobileImageContainer}>
										<img src={item?.iconUrl || ""} alt="icon" />
									</div>
									<p>{item?.name || ""}</p>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className={s.choseRubricModalWrap}>
			<div className={s.choseRubricModalContainer}>
				<div className={s.choseRubricModalContent}>
					<div className={s.choseRubricModalHeader}>
						{/*<CreateBoardADSvg id='closeIcon'/>*/}
					</div>
					<div>
						<p className={s.hoseRubricModalTitle}>{t(`Оберіть рубрику`)}</p>
					</div>
					<div className={`${s.mainCategoryMobileWrap}`}>
						{arrayRubrics?.level1.map((item: OneRubricType) => (
							<div
								key={item.id}
								className={s.mainCategoryMobileBlock}
								onClick={() => handleFirstStep(item)}
							>
								<div className={s.mainCategoryMobileItem}>
									<div className={s.mainCategoryMobileImageBlock}>
										<img
											src={item?.iconUrl || ""}
											alt="icon"
											className={s.mainCategoryMobileImage}
										/>
									</div>
									<p className={s.mainCategoryMobileText}>{item?.name || ""}</p>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CategorySelectorMobile;
