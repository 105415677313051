import { useTranslation } from "react-i18next";
import s from "./WorkSkeletonComponent.module.css";

type Props = {
	type: string;
	category: string;
};

const WorkSkeletonComponentMobile = ({ type, category }: Props) => {
	const { t } = useTranslation();

	let workType: { title: string; color: string; borderColor: string; } | null;
	switch (type) {
		case "пропоную":
		case "предлагаю":
			workType = {
				title: t("Пропоную роботу"),
				color: "#F9FEFF",
				borderColor: "#E1F0FE"
			};
			break;
		case "шукаю":
		case "ищу":
			workType = {
				title: t("Шукаю роботу"),
				color: "#f8fcf8",
				borderColor: "#CCFFBF"
			};
			break;
		default:
			workType = {
				title: t("Робота"),
				color: "#F9FEFF",
				borderColor: "#E1F0FE"
			};
	}

	return workType ? (
		<div className={s.containerMobile} style={{ backgroundColor: workType.color, borderColor: workType.borderColor }}>
			<div className={s.typeMobile}>{workType.title}</div>
			<div className={s.categoryMobile} title={category}>{category}</div>
		</div>
	) : null;
};

export default WorkSkeletonComponentMobile;
