import React from "react";
import s from "./PersonalCabinetSettings.module.css";
import PersonalCabinetAccordion from "../PersonalCabinetAccordion/PersonalCabinetAccordion";
import { useTranslation } from "react-i18next";
import { PersonalCabinetSettingBusinessPage } from "./PersonalCabinetSettingBusinessPage";

const PersonalCabinetSettings = () => {
	const { t } = useTranslation();
	return (
		<div className={s.cabinetSettingsContainer}>
			{/*<SettingsTopBlock />
            <SettingsPremiumSettings />*/}
			<h5>{t("Управління особистими даними")}</h5>
			<div className={s.settingsAccordionContainer}>
				{/* <PersonalCabinetSettingBusinessPage /> */}
				<PersonalCabinetAccordion />
			</div>
		</div>
	);
};

export default PersonalCabinetSettings;
