import React, { Fragment, useMemo, useRef, useState } from "react";
import ProductCardHorizontalForSearchMobile from "../../Components/Cards/ProductCard/ProductCardHorizontalForSearchMobile";
import FilterAndSortPanel from "../../Components/SearchResultComponents/FilterAndSortPanel";
import TopTitleBlock from "../../Components/TopTitleBlockMobileAllPages/TopTitleBlock";
import s from "./SearchResultMobile.module.css";
//import ExpandModernButton from "../../Components/ExpandButton/ExpandModernButton";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import ArrowsAndCross from "../../common/ArrowsAndCross/ArrowsAndCross";
import AddListingCardRowMobile from "../../Components/Cards/AddListingCard/AddListingCardRowMobile";
import AddListingMobileCard from "../../Components/Cards/AddListingCard/AddListingMobileCard";
import MobileFiltersTemplate from "../../Components/SearchFilters/MobileFiltersTemplate/MobileFiltersTemplate";
import Advertising from "../../Components/Сommercial/Advertising";
import { StateType } from "../../redux/redux-store";
import { FiltersValue, publicOneNotice } from "../../types/publicNoticeTypes";
import { OneCategoryType } from "../../types/reduxStoreTypes";
import { SearchCategory } from "../../types/searchTypes";

interface Props {
	category: boolean;
	filtersValues: FiltersValue[];
	setFiltersValues: (filtersValues: FiltersValue[] | []) => void;
	showSearchCategory: boolean;
	setShowSearchCategory: (b: boolean) => void;
	setSort: (b: string) => void;
	handleClickChoseCategory: (c: SearchCategory) => void;
	categoryRange: any;
}

const SearchResultMobile: React.FC<Props> = ({
	category,
	categoryRange,
	showSearchCategory,
	setShowSearchCategory,
	handleClickChoseCategory,
	setSort,
	filtersValues,
	setFiltersValues,
}) => {
	const sliderPrice = useSelector(
		(state: StateType) => state.publicNotice.priceRange
	);
	//const auth = useSelector((state: stateUserType) => state.userData.isAuth)
	const currentCategory: OneCategoryType = useSelector(
		(state: StateType) => state.categoryData.view
	);
	/*const searchingName = useSelector(
    (state: StateType) => state.searchData.searchingNoticeName,
  );*/
	//const decodedSearchingName = decodeURI(searchingName).split("&")[0];
	const [params] = useSearchParams();
	const decodedSearchingName = useMemo(() => params.get("search"), [params]);
	const categoriesSearch = useSelector(
		(state: StateType) => state.searchData.categories
	);
	const filteredNoticeList = useSelector(
		(state: StateType) => state.publicNotice.filteredNotices
	);
	const filteredNoticeListAllList = useSelector(
		(state: StateType) => state.publicNotice.filteredNoticesAllList
	);
	const [row, setRow] = useState(true);
	const menuRef: React.Ref<any> = useRef(null);
	const [openFilters, setOpenFilters] = useState<boolean>(false);
	const [sorting, setSeeSorting] = useState(false);

	/*const handleArrowButtonClick = (event: any) => {
    event.stopPropagation();
    setSeeSorting(false);

  };*/
	const handleClickFilterButton = () => {
		setOpenFilters(!openFilters);
	};

	const adIndexes = [11, 23, 47, 71]; // Индексы, после которых нужно добавить рекламу

	const insertAds = (list: publicOneNotice[]) => {
		return list.reduce<React.ReactNode[]>((acc, item, idx) => {
			acc.push(
				<Fragment key={item.id}>
					<ProductCardHorizontalForSearchMobile notice={item} isRow={row} />
				</Fragment>
			);
			// Проверяем, нужно ли добавить рекламу
			if (adIndexes.includes(idx)) {
				acc.push(
					<div key={`ad-${idx}`} style={{ margin: "10px 0px 20px 0px" }}>
						<Advertising idAD={10} isMobile={1} />
					</div>
				);
			}

			return acc;
		}, []);
	};

	return (
		<>
			{openFilters ? (
				<MobileFiltersTemplate
					setFiltersValues={setFiltersValues}
					filtersValues={filtersValues}
					sliderPrice={sliderPrice}
					categoryRange={categoryRange}
					handleClickFilterButton={handleClickFilterButton}
				/>
			) : (
				<div
					className={`${s.searchResultMobileContainer}`}
					/*onClick={handleArrowButtonClick}*/
				>
					<div className={s.topMenuContainer}>
						<TopTitleBlock
							background={"#FFFFFF"}
							hiddenBorder={true}
							title={`${
								category
									? `${currentCategory?.name}`
									: `${
											decodedSearchingName
												? `Знайдено “${decodedSearchingName}” в категоріях:`
												: ""
										}`
							}`}
						/>
						{!category && !!categoriesSearch.length && (
							<>
								<div
									className={`${s.topMenuArrowContainer} ${
										showSearchCategory && s.arrowSvgActive
									}`}
									onClick={() => setShowSearchCategory(!showSearchCategory)}
								>
									<ArrowsAndCross id={"arrowForSelect"} color={"#3D3D3D"} />
								</div>
								<div className={s.topMenu}>
									{showSearchCategory &&
										categoriesSearch.slice(0, 20).map((c) => {
											return (
												<li
													onClick={() => handleClickChoseCategory(c)}
													className={s.topMenuElement}
													key={c.categoryId}
												>
													<p>{c.categoryName}</p>
													<p>{c.countItems}</p>
												</li>
											);
										})}
								</div>
							</>
						)}
					</div>
					<div className={s.filterAndSortPanelWrapper}>
						<FilterAndSortPanel
							sorting={sorting}
							row={row}
							setRow={setRow}
							setSeeSorting={setSeeSorting}
							menuRef={menuRef}
							setSorting={setSort}
							handleClickFilterButton={handleClickFilterButton}
						/>
					</div>
					<Advertising idAD={9} isMobile={1} />
					<div className={s.searchResultMobileProductContainer}>
						{row && (
							<>
								{filteredNoticeList.length === 0 ? (
									<AddListingCardRowMobile />
								) : (
									<div className={s.cardsContainerRow}>
										{insertAds(filteredNoticeList)}
										<AddListingCardRowMobile />
									</div>
								)}
							</>
						)}
						{!row && (
							<>
								{filteredNoticeListAllList.length === 0 ? (
									<AddListingMobileCard />
								) : (
									<div className={s.cardsContainerColumn}>
										{insertAds(filteredNoticeListAllList)}
										<AddListingMobileCard />
									</div>
								)}
							</>
						)}
					</div>
					{/*<div className={s.searchResultMobileProductContainer}>
                        {!row && (
                            <>
                                {filteredNoticeList.length === 0 ? (
                                    <AddListingCardRowMobile/>
                                ) : (
                                    <div className={s.cardsContainerRow}>
                                        {filteredNoticeList.map((item, idx) => (
                                            <Fragment key={item.id}>
                                                <ProductCardHorizontalForSearchMobile
                                                    notice={item}
                                                    key={item.id}
                                                />
                                                {idx === 11 && !auth && (
                                                    <div style={{margin: '10px 0px 0px 0px'}}>
                                                        <div className={s.searchResultBannerContainer}>
                                                            <NoRegisterAdCardAdaptive/>
                                                        </div>
                                                    </div>
                                                )}
                                                {idx === 11 && auth && (
                                                    <div style={{margin: '0px 0px 20px 0px'}}>
                                                        <SaveSearchParamsCard/>
                                                    </div>
                                                )}
                                            </Fragment>
                                        ))}
                                        <AddListingCardRowMobile/>
                                    </div>
                                )}
                            </>
                        )}
                        {row && (
                            <>
                                {filteredNoticeListAllList.length === 0 ? (
                                    <AddListingMobileCard/>
                                ) : (
                                    <div className={s.cardsContainerColumn}>
                                        {filteredNoticeListAllList.map((item, idx) => (
                                            <Fragment key={item.id}>
                                                <MobileProductCard notice={item} key={item.id}/>
                                                {idx === 11 && !auth && (
                                                    <div
                                                        className={s.searchResultBannerContainer}
                                                        style={{margin: '20px 0px 0px 0px'}}
                                                    >
                                                        <NoRegisterAdCardAdaptive/>
                                                    </div>
                                                )}
                                                {idx === 11 && auth && (
                                                    <div
                                                        style={{
                                                            margin: '0px 0px 30px 0px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <SaveSearchParamsCard/>
                                                    </div>
                                                )}
                                            </Fragment>
                                        ))}
                                        <AddListingMobileCard/>
                                    </div>
                                )}
                            </>
                        )}
                    </div>*/}
					{/*{pagination.totalCount > 24 && pagination.pageCount > pagination.currentPage &&
                    <div className={s.expandButtonWrapper}>
                        <ExpandModernButton onClickCallback={() => {
                            setPageCount((state) => state + 1)
                            setCurrentPage((state) => state + 1)
                        }}
                        />
                    </div>}*/}
				</div>
			)}
		</>
	);
};

export default SearchResultMobile;
