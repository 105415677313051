


export const getRecaptchaCode = async (executeRecaptcha:((action?: (string | undefined)) => Promise<string>) | undefined) => {
	try {
		if (executeRecaptcha) {
			return await executeRecaptcha("yourAction");
		} else {
			console.error("executeRecaptcha is not available");
		}
	} catch (error) {
		console.error("Error refreshing reCAPTCHA:", error);
		return null;
	}
};
