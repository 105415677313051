import React from "react";
import s from "./ProductCardHorizontalMobile.module.css";
import productImage from "../../../images/cardItemHorizontal.jpg";
import { formattingPrice } from "../../../common/Logic/supporting";
import { useTranslation } from "react-i18next";
import FavoriteHeartSelector from "../FavoriteHeartSelector/FavoriteHeartSelector";
import { useNavigate } from "react-router";
import {
	publicOneNotice,
	TreeLinksType,
} from "../../../types/publicNoticeTypes";
import { useSelector } from "react-redux";
import { StateType } from "../../../redux/redux-store";
import WorkProductCardHorizontalForSearchMobile from "./WorkProductCardHorizontalForSearchMobile ";

const ProductCardHorizontalForSearchMobile = ({
	notice,
	isRow
}: {
	notice?: publicOneNotice;
	isRow?: boolean; 
}) => {
	const userView = useSelector((state: StateType) => state.userData.user);
	const noticeTree = notice?.tree?.map((s: TreeLinksType) => s.alias);
	const noticeUrl = noticeTree?.join("/");
	const navigate = useNavigate();
	const { i18n, t } = useTranslation();
	const currentLanguage = i18n.language === "ru" ? "" : "/ua";
	const navigateOnClick = () => {
		navigate(
			`${currentLanguage}/o/${noticeUrl ? noticeUrl + "/" : ""}${notice?.slug}`
		);
	};

	const favoriteHeart = (
		<FavoriteHeartSelector
			id={notice?.id || 0}
			isFavorite={notice?.isFavorite || 0}
		/>
	);

	const isWorkNotice = notice?.noticeType?.value === 1;

	return (
		<div className={`${s.horizontalCardBlock} ${isRow ? s.row : s.column} ${isWorkNotice ? s.work : ''}`} onClick={navigateOnClick}>
			{!isWorkNotice ? (<>
				<div className={s.horizontalCardImageBlock}>
					{notice?.imagePath ? (
						<img
							loading={"lazy"}
							src={notice?.imagePath}
							alt={notice?.slug || "image"}
						/>
					) : (
						<img
							loading={"lazy"}
							src={notice?.noticeImages[0]?.originalImageUrl || productImage}
							alt={notice?.slug || "image"}
						/>
					)}
				</div>
				<div className={s.horizontalCardContentBlock}>
					<div className={s.horizontalCardContenTitle}>
						<p>{notice?.name || ""}</p>
					</div>
					<div className={s.horizontalCardContenPrice}>
						<span>
							{notice?.price && notice.price !== "0.00"
								? formattingPrice(notice?.price)
								: t("Безкоштовно")}
							<span>
								{notice?.price && notice.price !== "0.00" ? " грн" : ""}
							</span>
						</span>
					</div>
					<div
						className={s.horizontalCardContenPlaceForSerach}
						onClick={(e) => e.stopPropagation()}
					>
						<p className={s.horizontalCardContenPlaceText}>
							{notice?.short_settlement_name || ""}
						</p>
						{notice?.users_id && +notice?.users_id !== userView.id 
							? favoriteHeart
							: null}
					</div>
				</div>
			</>) : (
				<WorkProductCardHorizontalForSearchMobile notice={notice} favoriteHeart={favoriteHeart} isRow={isRow} />
			)}
		</div>
	);
};

export default ProductCardHorizontalForSearchMobile;
