import React, { ReactNode, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Switch from "react-switch";
import { SetManageActivityBlock } from "../../../../../api/business-page/types";
import styles from "./Collapse.module.css";

interface CollapseProps {
	title: string;
	children: ReactNode;
	isEdit: boolean;
	onChangeEdit: (isEdit: boolean) => void;
	isUserPage: boolean;
	isVisible: boolean;
	onChangeVisible: (
		block: keyof SetManageActivityBlock,
		visible: 0 | 1
	) => void;
	block: keyof SetManageActivityBlock;
}

const Collapse: React.FC<CollapseProps> = ({
	title,
	children,
	isEdit,
	onChangeEdit,
	isUserPage,
	isVisible,
	onChangeVisible,
	block,
}) => {
	const { t } = useTranslation();
	const contentRef = useRef<HTMLDivElement>(null);
	const [localIsVisible, setLocalIsVisible] = useState(isVisible);

	const toggleCollapse = () => {
		if (!isUserPage) return;
		onChangeEdit(!isEdit);
	};

	const handleSwitchChange = (checked: boolean) => {
		setLocalIsVisible(checked);
		onChangeVisible(block, checked ? 1 : 0);
	};

	return (
		<div className={styles.collapseContainer}>
			<div className={styles.collapseHeader}>
				<div className={styles.collapseHeaderRight}>
					<span className={styles.title}>{title}</span>
					{isEdit ? (
						<div className={styles.footer}>
							<div className={styles.switchContainer}>
								<Switch
									checked={localIsVisible}
									onChange={handleSwitchChange}
									width={32}
									height={12}
									handleDiameter={22}
									uncheckedIcon={
										<svg
											className={styles.switchIconPause}
											width="17"
											height="17"
											viewBox="0 0 17 17"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="M6 5V9L6 13" stroke="white" strokeWidth="2" />
											<path d="M11 5V13" stroke="white" strokeWidth="2" />
										</svg>
									}
									checkedIcon={
										<svg
											className={styles.switchIconPlay}
											width="10"
											height="12"
											viewBox="0 0 10 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M9.17578 6.08824L0.0434279 11.3608L0.0434284 0.815669L9.17578 6.08824Z"
												fill="white"
											/>
										</svg>
									}
									offColor={"#E1E1E1"}
									onColor={"#E1E1E1"}
									offHandleColor={"#C4C4C4"}
									onHandleColor={"#00AB3A"}
									className={styles.switchContainer}
									activeBoxShadow={"0 0 2px 3px #00AB3A"}
								/>
								<h3>{t("Приховати")}</h3>
							</div>
						</div>
					) : null}
				</div>
				<button aria-expanded={isEdit}>
					{isUserPage ? (
						<span className={styles.collapseText}>
							{isEdit ? (
								<>
									<span onClick={toggleCollapse}>{t("Закрити")}</span>
								</>
							) : (
								<svg
									onClick={toggleCollapse}
									width="20"
									height="20"
									viewBox="0 0 20 20"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M16.4283 10C16.0338 10 15.714 10.3198 15.714 10.7143V17.8571C15.714 18.2516 15.3942 18.5714 14.9998 18.5714H2.14283C1.74833 18.5714 1.42854 18.2516 1.42854 17.8571V3.57143C1.42854 3.17693 1.74833 2.85713 2.14283 2.85713H10.7141C11.1086 2.85713 11.4284 2.53733 11.4284 2.14283C11.4284 1.74832 11.1086 1.42857 10.7141 1.42857H2.14283C0.959376 1.42857 0 2.38796 0 3.57143V17.8571C0 19.0406 0.959376 20 2.14283 20H14.9998C16.1832 20 17.1426 19.0406 17.1426 17.8571V10.7143C17.1426 10.3198 16.8228 10 16.4283 10Z"
										fill="#00A9CE"
									/>
									<path
										d="M19.1993 0.800988C18.6865 0.288042 17.9908 -7.24047e-05 17.2655 1.13007e-05C16.5397 -0.00208134 15.8434 0.286535 15.332 0.801449L5.9235 10.2093C5.84544 10.2879 5.78656 10.3835 5.75136 10.4886L4.32282 14.7743C4.19814 15.1486 4.4005 15.553 4.77478 15.6777C4.84739 15.7019 4.92344 15.7142 4.99995 15.7143C5.07662 15.7142 5.15283 15.7019 5.22565 15.6779L9.51131 14.2493C9.61657 14.2142 9.7122 14.155 9.79059 14.0765L19.199 4.66789C20.2669 3.60014 20.267 1.86886 19.1993 0.800988ZM18.1891 3.65857L8.90345 12.9443L6.1292 13.8707L7.05275 11.1L16.3419 1.81428C16.8526 1.3046 17.6798 1.30544 18.1895 1.81613C18.4329 2.06009 18.5701 2.39035 18.5712 2.735C18.572 3.0815 18.4345 3.41398 18.1891 3.65857Z"
										fill="#00A9CE"
									/>
								</svg>
							)}
						</span>
					) : null}
				</button>
			</div>

			<div ref={contentRef} className={styles.collapseContent}>
				<div
					className={styles.contentInner}
					style={{
						paddingLeft: block === "delivery" ? 0 : "20px",
					}}
				>
					{children}
				</div>
			</div>
		</div>
	);
};

export default Collapse;
