import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getRestorePass, getSmsCode } from "../../../redux/user_Reducer";
import classes from "../../../Auth&RegNewPopups/ForgotPassPopup/ForgotPassPopup.module.css";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { getRecaptchaCode } from "../../../common/Logic/recaptchaService";

type Props = {
	setTime: (b: number) => void;
	time: number;
	phone: string;
	approve: boolean;
};

function Timer({ time, setTime, phone, approve }: Props) {
	const [changeButton, setChangeButton] = useState(true);
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const getSms = useSelector(() => getSmsCode);
	const forgotPass = useSelector(() => getRestorePass);
	const { executeRecaptcha } = useGoogleReCaptcha();

	// Функция для форматирования времени в формат ММ:СС
	const formatTime = (seconds: number) => {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;
		return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
	};


	useEffect(() => {
		const timer = setInterval(() => {
			if (time > 0) {
				setTime(time - 1);
			} else {
				clearInterval(timer);
				setChangeButton(!changeButton);
				// Действия, которые нужно выполнить по истечении 4 минут
			}
		}, 1000);

		return () => {
			clearInterval(timer);
		};
	}, [time]);

	return (
		<div>
			{changeButton ? (
				formatTime(time) + ` ` + t("хв")
			) : (
				<button type={"button"}
					className={classes.timerButton}
					onClick={async (event	:any) => {
						event.preventDefault();
						if (approve) {
							dispatch(getSms(phone));
						} else {
							const newCaptcha = await getRecaptchaCode(executeRecaptcha);
							dispatch(forgotPass(phone, newCaptcha ?? ""));
						}

						setChangeButton(!changeButton);
						setTime(240);
					}}
				>
					{t("Відправити новий код")}
				</button>
			)}
		</div>
	);
}

export default Timer;
