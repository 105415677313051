import React from "react";
import Lottie from "lottie-react";

interface Props {
	animation:object,
	w?:string
	h?:string
}
const AnimationPlayer:React.FC<Props> = ({animation,w,h}) => {
	return (
		<div style={{ width: w ?? "100%", height: h ?? "100%" }}>
			<Lottie animationData={animation} />
		</div>
	);
};

export default AnimationPlayer;