import React from "react";
import { useTranslation } from "react-i18next";
import s from "./ErrorPages.module.css";
import { useNavigate } from "react-router";

type ButtonType = "reload" | "goHome";

type Props = {
	type: ButtonType;
};
const ErrorPagesButton = ({ type }: Props) => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const languagePath = i18n.language === "ru" ? "" : "ua/";
	const handleClick = () => {
		if (type === "reload") {
			window.location.reload();
		} else {
			navigate(`/${languagePath}`);
		}
	};
	return (
		<button className={s.errorPageButton} onClick={handleClick}>
			{type === "reload" && t("Перезавантажити")}
			{type === "goHome" && t("Перейти на головну")}
		</button>
	);
};

export default ErrorPagesButton;
