import { useGetBusinessUserPageData } from "../../CustomHooks/api/business-page/useBusinessPage";
import NewPage404 from "../../pages/Page404/NewPage404";
import PreLoaderComponent from "../../common/Animations/PreLoaderComponent/PreLoaderComponent";
import s from "./DeliveryBlock.module.css";

const DeliveryBlock = () => {
	const { data, isLoading, isError } = useGetBusinessUserPageData("");

	if (isLoading) {
		return <PreLoaderComponent type={"category"} size={"medium"} />;
	}
	if (isError || !data) {
		return <NewPage404 />;
	}

	const delivery = data.deliveryServices;

	const activeDeliveries = Object.entries(delivery).filter(
		([, service]) => service?.active === "1"
	);

	return (
		<ul className={s.deliveryTable}>
			{activeDeliveries.map(([key, service]) => (
				<li key={key} className={s.deliveryTableElement}>
					<button className={s.deliveryButton}>
						<div className={s.deliveryLogo}>
							<img src={service?.logoUrl} alt="Logo" />
						</div>
						<div className={s.deliveryInfo}>
							<span className={s.deliveryName}>{service?.title}</span>
						</div>
					</button>
				</li>
			))}
		</ul>
	);
};

export default DeliveryBlock;
