import React, { useEffect, useState } from "react";
import GridLayout from "react-grid-layout";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { responsiveForLayotZone } from "../../common/Logic/responsiveForLayotZone";
import { useMediaQuery } from "react-responsive";
import s from "./ChangeLayotZone.module.css";
import "./ChangeLayotZone.css";
import {  userNotice } from "../../api/api";
import { useSelector } from "react-redux";
import { StateType } from "../../redux/redux-store";
import { OriginalResponseImage } from "../../types/noticeTypes";
import { ToastContainer, toast } from "react-toastify";
import GridLoader from "./GridLoader";
import GridElement from "./GridElement";
import { Desktop, Mobile } from "../../responsive";

export type ImageForRequest = {
	name: string;
	tmpName: string;
	rotate: 0 | 90 | 180 | 270;
};

export type Image = ImageForRequest & {
	i: string;
	x: number;
	y: number;
	w: number;
	h: number;
	content: string | null;
};
type Props = {
	setFieldValue: any;
	isWorkCategory: boolean;
	noticeId?: string;
};

const ChangeLayoutZone: React.FC<Props> = ({ setFieldValue, noticeId ,isWorkCategory}) => {
	const { t } = useTranslation();
	const [images, setImages] = useState<any[]>([]);
	const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
	const [removeError, setRemoveError] = useState<string | null>(null);
	const editImages = useSelector(
		(state: StateType) => state.noticeData.originalImages
	);
	const quantityPhotosData = useSelector(
		(state: StateType) => state.categoryData.quantityPhotosData?.params
	);

	const isMobile = useMediaQuery({ maxWidth: 1024 });
	const cols = responsiveForLayotZone("cols")
	const width = responsiveForLayotZone("width")

	let approveCount: any = 12;

	if (quantityPhotosData) {
		try {
			const paramsObj: { photosNumber?: string } =
				JSON.parse(quantityPhotosData);
			approveCount = paramsObj?.photosNumber
				? Number(paramsObj.photosNumber)
				: 12;
		} catch (error) {
			console.error("Ошибка парсинга JSON:", error);
		}
	}
	const isApproveByQuantity = images.length <= approveCount;
	const maxRows=Math.ceil(Math.min(images.length, approveCount) / cols)
	const [quantityError, setQuantityError] = useState<string | null>(null);

	const QUNTITI_ERROR = t("Перевищено ліміт зображень");
	useEffect(() => {

// Сортируем изображения перед обновлением поля формы
		const sortedImages = [...images].sort((a, b) => {
			if (a.y === b.y) {
				return a.x - b.x;
			}
			return a.y - b.y;
		});

		const updatedImageData = sortedImages.map((img) => ({
			name: img.name,
			tmpName: img.tmpName,
			rotate: img.rotate,
		}));
		setFieldValue("noticeImagesString", updatedImageData);
	}, [images, setImages]);

	useEffect(() => {
		if (!isApproveByQuantity) {
			toast.error(QUNTITI_ERROR);
			const updatedImages = images.slice(0, approveCount ?? 0);
			// Устанавливаем обновленный массив
			setImages(updatedImages);
		}
	}, [isApproveByQuantity]);

	useEffect(() => {
		if (editImages) {
			const images: any = editImages.map(
				(i: OriginalResponseImage, index: number) => {
					const id = uuidv4();
					return {
						i: id,
						x: index % width,
						y: Math.floor(index / cols),
						w: 1,
						h: 1,
						content: i.originalImageUrl,
						name: i.name,
						tmpName: i.tmpName,
						rotate: null,
					};
				}
			);
			setImages(images);
		} else {
			setImages([]);
		}
	}, [editImages]);

	const handleRotateImage = (id: string) => {
		const updatedImages = images.map((image) => {
			if (image.i === id) {
				return { ...image, rotate: ((image.rotate || 0) + 90) % 360 };
			}
			return image;
		});

		// Сортируем изображения перед обновлением поля формы
		const sortedImages = [...updatedImages].sort((a, b) => {
			if (a.y === b.y) {
				return a.x - b.x;
			}
			return a.y - b.y;
		});

		// Обновляем поле формы с отсортированными изображениями
		const updatedImageData = sortedImages.map((image) => ({
			name: image.name,
			tmpName: image.tmpName,
			rotate: image.rotate,
		}));

		setFieldValue("noticeImagesString", updatedImageData);
		setImages(sortedImages); // Обновляем состояние с отсортированными изображениями
	};

	const handleRemove = async (id: string, name: string, fileName: string) => {
		const filtered = images.filter((image) => image.i !== id);
		const uploadFiltered = uploadedFiles.filter(
			(image: File) => image.name !== fileName && image.name !== name,
		);

		setUploadedFiles(uploadFiltered);

		if (noticeId || fileName === "error") {
			// Сортируем изображения перед обновлением поля формы
			const sortedImages = [...filtered].sort((a, b) => {
				if (a.y === b.y) {
					return a.x - b.x;
				}
				return a.y - b.y;
			});

			const updatedImageData = sortedImages.map((image) => ({
				name: image.name,
				tmpName: image.tmpName,
				rotate: image.rotate,
			}));

			// Обновляем поле формы с отсортированными изображениями
			setFieldValue("noticeImagesString", updatedImageData);
			setImages(sortedImages); // Обновляем состояние с отсортированными изображениями
			setQuantityError(null);
		} else {
			try {
				const response = await userNotice.removePhotoTmp(fileName);
				if (response.data.tmpFileDeleteResult) {
					// Успешное удаление, обновляем состояние
					const sortedImages = [...filtered].sort((a, b) => {
						if (a.y === b.y) {
							return a.x - b.x;
						}
						return a.y - b.y;
					});

					const updatedImageData = sortedImages.map((image) => ({
						name: image.name,
						tmpName: image.tmpName,
						rotate: image.rotate,
					}));

					setFieldValue("noticeImagesString", updatedImageData);
					setImages(sortedImages); // Обновляем состояние с отсортированными изображениями
					setQuantityError(null);
				} else {
					setRemoveError(t("Помилка при видаленні"));
				}
			} catch (error) {
				setRemoveError(t("Помилка при видаленні"));
			}
		}
	};
	const handleLayoutChange = (layout:Image[]) => {
		setImages((prevImages) => {
			const updatedImages = layout.map((item, index) => ({
				...prevImages[index],
				x: item.x,
				y: item.y,
				w: item.w,
				h: item.h,
			}));

			// Сортируем изображения по координатам
			const sortedImages = [...updatedImages].sort((a, b) => {
				if (a.y === b.y) {
					return a.x - b.x; // Если y одинаковые, сортируем по x
				}
				return a.y - b.y; // Сначала сортируем по y
			});

			// Создаем массив для отправки на сервер
			const noticeImages = sortedImages.map((image: Image) => ({
				name: image.name,
				tmpName: image.tmpName,
				rotate: image.rotate,
			}));

			// Обновляем noticeImagesString
			setFieldValue("noticeImagesString", noticeImages);

			return updatedImages; // Обновляем состояние images
		});
	};
	return (
		<>
			<div className={s.counterImageContainer}>
				{`${t("Доступна кількість зображень для завантаження")} - ${(approveCount - images.length)}`}
			</div>
			<div className={s.container}>
				{/* Загрузчик изображений */}
				<Desktop>
					<GridLoader
						setUploadedFiles={setUploadedFiles}
						uploadedFiles={uploadedFiles}
						images={images}
						approveCount={approveCount}
						setImages={setImages}
						QUNTITI_ERROR={QUNTITI_ERROR}
						isWorkCategory={isWorkCategory}
						setFieldValue={setFieldValue}
					/>
				</Desktop>

				{/* Сетка изображений */}
				{images?.length > 0 && (
					<div className={s.containerGrid}>
						<GridLayout
							cols={cols}
							rowHeight={isMobile ? 70 : 130}
							width={width}
							isResizable={false}
							isDraggable={true}
							margin={isMobile ? [10, 35] : [10, 55]}
							compactType={"horizontal"}
							maxRows={maxRows}
							onLayoutChange={(layout:any) => handleLayoutChange(layout)}
						>
							{images.map((image: Image) => (
								<div
									key={image.i}
									data-grid={{
										i: image.i,
										x: image.x,
										y: image.y,
										w: image.w,
										h: image.h,
									}}
								>
									<GridElement
										image={image}
										handleRotateImage={handleRotateImage}
										handleRemove={handleRemove}
										removeError={removeError}
									/>
								</div>
							))}
						</GridLayout>
					</div>
				)}
				{/* Загрузчик изображений */}
				<Mobile>
					<GridLoader
						setUploadedFiles={setUploadedFiles}
						uploadedFiles={uploadedFiles}
						images={images}
						approveCount={approveCount}
						setImages={setImages}
						QUNTITI_ERROR={QUNTITI_ERROR}
						isWorkCategory={isWorkCategory}
						setFieldValue={setFieldValue}
					/>
				</Mobile>
				<div className={s.errorQuantity}>
					{Boolean(quantityError) && quantityError}
				</div>
			</div>
			<ToastContainer stacked />
		</>
	);
};

export default ChangeLayoutZone;
